export const quarterList = [
  { label: "Quarter 1", value: 1 },
  { label: "Quarter 2", value: 2 },
  { label: "Quarter 3", value: 3 },
  { label: "Quarter 4", value: 4 }
]

export const yearList = [
  { label: "2021", value: 2021 },
  { label: "2022", value: 2022 },
  { label: "2023", value: 2023 },
  { label: "2024", value: 2024 },
  { label: "2025", value: 2025 },
  { label: "2026", value: 2026 }
]
