import { useEffect, useState } from "react"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid
} from "@material-ui/core"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router"
import { Formik, Form } from "formik"
import { FormikControl } from "src/components"
import * as Yup from "yup"
import { useAuth } from "../../../hooks"
import { fetchCompanyList } from "../api/api"

const RegisterPage = () => {
  const auth = useAuth()
  const { currentUser, updateUsersCompany } = auth

  const [companyList, setCompanyList] = useState()

  useEffect(() => {
    fetchCompanyList().then(data => setCompanyList(data))
  }, [])

  const navigate = useNavigate()

  const onSubmit = async values => {
    values.email = currentUser.email
    values.userId = currentUser.userId
    await updateUsersCompany(values)
    navigate("/")
  }

  const initialValues = {
    companyId: 0
  }

  const validationSchema = Yup.object().shape({
    companyId: Yup.number().required("Field is required"),
    phoneNumber: Yup.number().required("Field is required")
  })

  return (
    <>
      <Helmet>
        <title>Register</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center"
        }}
      >
        <Container maxWidth="sm">
          <Card>
            <CardHeader
              subheader="Please confirm registration information"
              title="Registration"
            />
            <Divider />
            <CardContent>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnMount
                enableReinitialize
              >
                {formik => {
                  return (
                    <Form>
                      <Grid item xs={12}>
                        <FormikControl
                          control="select"
                          data={companyList}
                          label="Please select Company"
                          name="companyId"
                          type="text"
                          value={formik.values.companyId}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikControl
                          control="input"
                          title="Phone Number"
                          type="text"
                          label="Phone Number"
                          name="phoneNumber"
                          maxLength={10}
                          minLength={10}
                        />
                      </Grid>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          p: 2
                        }}
                      >
                        <Button
                          color="primary"
                          fullWidth
                          size="large"
                          variant="contained"
                          type="submit"
                        >
                          Save details
                        </Button>
                      </Box>
                    </Form>
                  )
                }}
              </Formik>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  )
}

export default RegisterPage
