import React, { useState } from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Box, TableCell, TableRow, Typography } from "@material-ui/core"
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone"
import { ConfirmDialog, EnhancedTable, IconButton } from "src/components"
import { DeleteForeverTwoTone } from "@material-ui/icons"
import { ProtectedElement } from "src/components/auth"
import moment from "moment"
import PropTypes from "prop-types"
import AddEditItem from "./AddEditItem"

const LoadDataTable = ({
  lookupData,
  weekList,
  data,
  deleteNewLoad,
  editNewLoad
}) => {
  const [editItem, setEditItem] = useState(null)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState(null)

  const [selectedYear, setSelectedYear] = React.useState(
    new Date().getFullYear()
  )

  const headers = [
    {
      id: "loadNumber",
      numeric: false,
      disablePadding: false,
      label: "Load"
    },
    {
      id: "loadStartDate",
      numeric: false,
      disablePadding: false,
      label: "Start Date"
    },
    {
      id: "loadEndDate",
      numeric: false,
      disablePadding: false,
      label: "End Date"
    },
    {
      id: "originCity",
      numeric: false,
      disablePadding: false,
      label: "Origin"
    },
    {
      id: "destinationCity",
      numeric: false,
      disablePadding: false,
      label: "Destination"
    },
    {
      id: "tripLength",
      numeric: false,
      disablePadding: false,
      label: "Miles"
    },
    {
      id: "numberOfStops",
      numeric: false,
      disablePadding: false,
      label: "Stops"
    },
    {
      id: "pricePerMile",
      numeric: false,
      disablePadding: false,
      label: "PPM"
    },
    { id: "payout", numeric: false, disablePadding: false, label: "Payout" },
    {
      id: "loadStatusName",
      numeric: false,
      disablePadding: false,
      label: "Status"
    },
    {
      id: "loadDriverNames",
      numeric: false,
      disablePadding: false,
      label: "Driver(s)"
    },
    {
      id: "comments",
      numeric: false,
      disablePadding: false,
      label: "Comment"
    },
    {
      id: "action",
      disablePadding: false,
      label: "Action"
    }
  ]

  const handleOnCloseDialog = () => {
    setEditItem(null)
    setIsEditDialogOpen(false)
    setConfirmDialog(null)
  }

  // const saveLoadData = () => {}

  // const deleteItem = () => {}

  const handleEditItem = item => {
    setEditItem(item)
    setIsEditDialogOpen(true)
  }

  const handleOnConfirmDelete = async item => {
    deleteNewLoad(item)
    handleOnCloseDialog()
  }

  const handleDeleteItem = item => {
    const dialog = {
      text: `Are you sure you want to delete ${item.loadNumber}`,
      onConfirm: () => handleOnConfirmDelete(item),
      onCancel: () => handleOnCloseDialog(),
      isDialogOpen: true
    }
    setConfirmDialog(dialog)
  }

  const handleOnSave = item => {
    editNewLoad(item)
    handleOnCloseDialog()
  }

  return (
    <>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {data && (
            <EnhancedTable data={data} headers={headers} dense>
              {data.map(item => {
                return (
                  <TableRow hover key={item.createdOn}>
                    <TableCell>{item.loadNumber}</TableCell>
                    <TableCell>
                      {moment(item.loadStartDate).format("MM/DD/YYYY HH:mm")}
                    </TableCell>
                    <TableCell>
                      {moment(item.loadEndDate).format("MM/DD/YYYY HH:mm")}
                    </TableCell>
                    <TableCell>
                      {item.originCity} {item.originStateName}
                    </TableCell>
                    <TableCell>
                      {item.destinationCity} {item.destinationStateName}
                    </TableCell>
                    <TableCell>{item.tripLength}</TableCell>
                    <TableCell>{item.numberOfStops}</TableCell>
                    <TableCell>${item.pricePerMile}</TableCell>
                    <TableCell>${item.payout}</TableCell>
                    <TableCell>{item.loadStatusName}</TableCell>
                    <TableCell>
                      {item.loadDriverNames.map(driver => (
                        <Typography key={driver}>{driver}</Typography>
                      ))}
                    </TableCell>
                    <TableCell>{item.comments}</TableCell>
                    <TableCell align="center">
                      <ProtectedElement
                        name="LoadBoardPage"
                        accessLevel="DenyDelete"
                      >
                        <IconButton
                          aria-label="Edit"
                          onClick={() => handleEditItem(item)}
                        >
                          <EditTwoToneIcon />
                        </IconButton>
                      </ProtectedElement>
                      <ProtectedElement
                        name="LoadBoardPage"
                        accessLevel="Write"
                      >
                        <IconButton
                          aria-label="Edit"
                          onClick={() => handleDeleteItem(item)}
                        >
                          <DeleteForeverTwoTone />
                        </IconButton>
                      </ProtectedElement>
                    </TableCell>
                  </TableRow>
                )
              })}
            </EnhancedTable>
          )}
        </Box>
      </PerfectScrollbar>
      <AddEditItem
        data={lookupData}
        onSave={handleOnSave}
        onClose={handleOnCloseDialog}
        editItem={editItem}
        isDialogOpen={isEditDialogOpen}
        weekList={weekList}
        selectedYear={selectedYear}
        setYear={e => setSelectedYear(e)}
      />
      <ConfirmDialog props={confirmDialog} />
    </>
  )
}

LoadDataTable.propTypes = {
  lookupData: PropTypes.object,
  data: PropTypes.array,
  weekList: PropTypes.array,
  deleteNewLoad: PropTypes.func,
  editNewLoad: PropTypes.func
}

export default LoadDataTable
