import React from "react"
import { TextField, MenuItem } from "@material-ui/core"
import { Field } from "formik"
import PropTypes from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

const Select = props => {
  const { name, data, oject, ...rest } = props
  const variant = rest.variant ? rest.variant : "outlined"
  return (
    <>
      <Field name={name} id={name} {...rest}>
        {fieldProps => {
          const { field, meta } = fieldProps
          return (
            <>
              <PerfectScrollbar>
                <TextField
                  error={Boolean(meta.touched && meta.error)}
                  fullWidth
                  helperText={meta.error}
                  {...rest}
                  margin="normal"
                  variant={variant}
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value || ""}
                  // {...field} // replaces name, value, onChange and onBlur
                  select
                  defaultValue=""
                >
                  {data &&
                    data.map(item => (
                      <MenuItem
                        key={item.value}
                        value={oject ? item : item.value}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                </TextField>
              </PerfectScrollbar>
            </>
          )
        }}
      </Field>
    </>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.array,
  props: PropTypes.object,
  oject: PropTypes.bool
}

export default Select
