import * as React from "react"
import { makeStyles } from "@material-ui/styles"
import {
  Button,
  IconButton,
  Tooltip,
  InputLabel,
  Alert,
  AlertTitle,
  Box
} from "@material-ui/core"
import { PhotoCamera } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  input: {
    display: "none"
  },
  faceImage: {
    color: theme.palette.primary.light
  }
}))

const getBase64 = async file => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  return new Promise((reslove, reject) => {
    reader.onload = () => reslove(reader.result)
    reader.onerror = error => reject(error)
  })
}

// eslint-disable-next-line react/prop-types
const UploadDocument = ({ onUpload }) => {
  const classes = useStyles()
  const [selectedFiles, setSelectedFiles] = React.useState(null)

  const handleCapture = ({ target }) => {
    setSelectedFiles(target.files)
  }

  const handleSubmit = async () => {
    const formData = new FormData()
    const processedFiles = []

    for (let i = 0; i < selectedFiles.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      const file = await getBase64(selectedFiles[i])

      processedFiles.push(file)
    }

    const blobs = []

    for (let i = 0; i < processedFiles.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      blobs.push(await (await fetch(processedFiles[i])).blob())
    }

    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("File", blobs[i], selectedFiles[i].name)
    }

    await onUpload(formData)
    setSelectedFiles(null)
  }

  return (
    <>
      <Alert
        icon={
          <Tooltip title="Select Image">
            <InputLabel htmlFor="faceImage">
              <IconButton
                className={classes.faceImage}
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <PhotoCamera fontSize="large" />
              </IconButton>
            </InputLabel>
          </Tooltip>
        }
        severity="info"
      >
        <AlertTitle>
          <Box display="inline-flex">
            <input
              accept="*"
              className={classes.input}
              id="faceImage"
              type="file"
              multiple
              onChange={handleCapture}
            />
            <InputLabel aria-label="left" sx={{ mt: 2 }}>
              {selectedFiles
                ? `${selectedFiles.length} files selected`
                : "Select Files ..."}
            </InputLabel>
            <Button
              onClick={() => handleSubmit()}
              color="primary"
              size="small"
              variant="outlined"
              sx={{ ml: 2, mt: 1 }}
            >
              Save
            </Button>
          </Box>
        </AlertTitle>
        {/* <Grid container fullWidth>
          <Grid item xs={2} />
          <Grid item xs={3}>
            <InputLabel aria-label="left">
              {selectedFiles
                ? `${selectedFiles.lenghts} files selected`
                : "Select Files"}
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={() => handleSubmit()} color="primary">
              Save
            </Button>
          </Grid>
        </Grid> */}
      </Alert>
    </>
  )
}

export default UploadDocument
