/* eslint-disable no-unused-vars */
import axios from "axios"

export const fetchIftaMiles = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars, no-debugger
  const [_, param] = queryKey
  const result = await axios.post(`/api/Accounting/GetIftaMiles`, param)
  return result.data
}

export const importIftaMiles = async file => {
  const result = await axios.post(`/api/Accounting/ImportIftaMiles/`, file)
  return result.data
}
