/* eslint-disable */
import React from "react"
import { TextField, Box } from "@material-ui/core"
import { Field } from "formik"
import PropTypes from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import Autocomplete from "@material-ui/core/Autocomplete"

const AutoCompleteTest = props => {
  const { name, data, onInputChange, multiple, ...rest } = props
  const variant = rest.variant ? rest.variant : "outlined"

  const setValue = (values, form, fieldName) => {
    const filteredValues = multiple ? values.map(a => a.value) : values.value
    form.setFieldValue(fieldName, filteredValues)
  }

  const onInput = (event, value, reason) => {
    if (value) {
      onInputChange(value)
    }
  }

  const defaultValue = () => {
    return multiple ? data || [] : data[0] || {}
  }

  return (
    <>
      <Field name={name} id={name} {...rest}>
        {fieldProps => {
          // eslint-disable-next-line no-unused-vars
          const { field, form, meta } = fieldProps
          return (
            <PerfectScrollbar>
              <Autocomplete
                defaultValue={defaultValue}
                options={data ?? []}
                onInputChange={onInput}
                getOptionLabel={option => option.label || ""}
                multiple={multiple}
                id={name}
                onChange={(e, val) => {
                  setValue(val ?? "", form, name)
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    title={field.name}
                    variant={variant}
                    name={field.name}
                    {...rest}
                    fullWidth
                    margin="normal"
                  />
                )}
              />
            </PerfectScrollbar>
          )
        }}
      </Field>
    </>
  )
}

AutoCompleteTest.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.array,
  props: PropTypes.object,
  onInputChange: PropTypes.func,
  multiple: PropTypes.bool
}

export default AutoCompleteTest
