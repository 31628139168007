import React, { useState, useEffect } from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Box, Card, TableCell, TableRow } from "@material-ui/core"
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone"
import { useEnhancedMutate } from "src/hooks"
import { EnhancedTable, ConfirmDialog, IconButton } from "src/components"
import PropTypes from "prop-types"
import { DeleteForeverTwoTone } from "@material-ui/icons"
import { ProtectedElement } from "src/components/auth"
import AddEditItem from "./AddEditItem"
import { useLookupData, useBrokers } from "../api/hooks"
import { saveBroker, deleteBroker } from "../api/api"

const BrokersTable = ({ addNewItem, onAddItemClose, ...rest }) => {
  const [lookupData, setLookupData] = useState(null)
  const [editItem, setEditItem] = useState(null)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState(null)
  const [sortedData, setSortedData] = useState(null)

  const handleOnSort = inData => {
    setSortedData(inData)
  }

  const dataQuery = useBrokers()
  const lookupQuery = useLookupData()

  const headers = [
    {
      id: "brokerName",
      numeric: false,
      disablePadding: false,
      label: "Name"
    },
    {
      id: "fullAddress",
      numeric: false,
      disablePadding: false,
      label: "Address"
    },
    {
      id: "phoneNumber",
      numeric: false,
      disablePadding: false,
      label: "Phone"
    },
    { id: "email", numeric: false, disablePadding: false, label: "Email" },
    {
      id: "mcNumber",
      numeric: false,
      disablePadding: false,
      label: "MC / DOT"
    },
    {
      id: "comments",
      numeric: false,
      disablePadding: false,
      label: "Comments"
    },
    {
      id: "action",
      disablePadding: false,
      label: "Action"
    }
  ]

  const handleOnCloseDialog = () => {
    setEditItem(null)
    setIsEditDialogOpen(false)
    onAddItemClose()
  }

  const [saveBrokerInfo] = useEnhancedMutate(saveBroker, {
    invalidateQueries: [
      {
        queryKey: ["fetchBrokers"]
      }
    ],
    customSuccessMsg: "Broker updated successfully",
    onSuccess: () => {
      handleOnCloseDialog()
    }
  })

  const [deleteItem] = useEnhancedMutate(deleteBroker, {
    invalidateQueries: [
      {
        queryKey: ["fetchBrokers"]
      }
    ],
    customSuccessMsg: "Broker deleted successfully",
    onSuccess: () => {
      handleOnCloseDialog()
    }
  })

  // Used to check if useeffect trigerred by data update
  const [dataUpdatedOn, setDataUpdatedOn] = useState("")

  useEffect(() => {
    if (dataQuery.isFetched && dataUpdatedOn < dataQuery.dataUpdatedAt) {
      setDataUpdatedOn(dataQuery.dataUpdatedAt)
      setSortedData(dataQuery.data)
    }
    if (lookupQuery.isFetched) {
      setLookupData(lookupQuery.data)
    }
  }, [dataQuery, lookupQuery])

  const handleAddItem = () => {
    setEditItem({})
    setIsEditDialogOpen(true)
  }

  useEffect(() => {
    if (addNewItem) {
      handleAddItem()
    }
  }, [addNewItem])

  const handleEditItem = item => {
    setEditItem(item)
    setIsEditDialogOpen(true)
  }

  const handleOnConfirmDelete = async item => {
    await deleteItem(item)
  }

  const handleDeleteItem = item => {
    const dialog = {
      text: `Are you sure you want to delete ${item.brokerName}`,
      onConfirm: () => handleOnConfirmDelete(item),
      onCancel: () => handleOnCloseDialog(),
      isDialogOpen: true
    }
    setConfirmDialog(dialog)
  }

  const handleOnSave = item => {
    saveBrokerInfo(item)
    setIsEditDialogOpen(false)
  }

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            {sortedData && (
              <EnhancedTable
                data={sortedData}
                headers={headers}
                onSort={handleOnSort}
              >
                {sortedData.map(item => {
                  return (
                    <TableRow hover key={item.brokerId}>
                      <TableCell>{item.brokerName}</TableCell>
                      <TableCell align="left">{item.fullAddress}</TableCell>
                      <TableCell align="left">{item.phoneNumber}</TableCell>
                      <TableCell align="left">{item.email}</TableCell>
                      <TableCell align="left">
                        {item.mcNumber} / {item.dotNumber}
                      </TableCell>
                      <TableCell>{item.comments}</TableCell>
                      <TableCell align="left">
                        <ProtectedElement
                          name="BrokerAdminPage"
                          accessLevel="DenyDelete"
                        >
                          <IconButton
                            aria-label="Edit"
                            onClick={() => handleEditItem(item)}
                          >
                            <EditTwoToneIcon />
                          </IconButton>
                        </ProtectedElement>
                        <ProtectedElement
                          name="BrokerAdminPage"
                          accessLevel="Write"
                        >
                          <IconButton
                            aria-label="Edit"
                            onClick={() => handleDeleteItem(item)}
                          >
                            <DeleteForeverTwoTone />
                          </IconButton>
                        </ProtectedElement>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </EnhancedTable>
            )}
          </Box>
        </PerfectScrollbar>
      </Card>
      {lookupData && (
        <AddEditItem
          data={lookupData}
          onSave={handleOnSave}
          onClose={handleOnCloseDialog}
          editItem={editItem}
          isDialogOpen={isEditDialogOpen}
        />
      )}
      <ConfirmDialog props={confirmDialog} />
    </>
  )
}

BrokersTable.propTypes = {
  addNewItem: PropTypes.bool,
  onAddItemClose: PropTypes.func
}

export default BrokersTable
