/* eslint-disable no-unused-vars */
import React from "react"
import { Box, Button, Grid, MenuItem, TextField } from "@material-ui/core"
import { PageSection, FormikControl, IconButton } from "src/components"
import { PropTypes } from "prop-types"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import { ProtectedElement } from "src/components/auth"
import { useQueryClient } from "react-query"

// import RefreshIcon from "@material-ui/icons/Refresh"

const SearchFilters = ({
  driverList,
  weekList,
  setYear,
  selectedYear,
  onSearch
}) => {
  const initialValues = {
    weekId: 0,
    driverId: 0,
    startDate: "",
    endDate: ""
  }

  const [lastQuery, setLastQuery] = React.useState(initialValues)

  const validationSchema = Yup.object().shape({
    weekId: Yup.number(),
    driverId: Yup.number(),
    startDate: Yup.date(),
    endDate: Yup.date()
  })

  const onSubmit = async values => {
    setLastQuery(values)
    await onSearch(values)
  }

  const queryClient = useQueryClient()

  const onRefresh = () => {
    queryClient.invalidateQueries(["fetchDriversGrossIncome", lastQuery])
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount
        enableReinitialize
      >
        {formik => {
          return (
            <Form>
              <Grid container spacing={1}>
                <Grid item md={1} xs={12}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    fullWidth
                    margin="normal"
                    label="Work Year"
                    onChange={e => setYear(e.target.value)}
                    value={selectedYear}
                    variant="standard"
                  >
                    {[2021, 2022, 2023, 2024, 2025].map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {!formik.values.startDate && !formik.values.endDate && (
                  <Grid item md={2} xs={12}>
                    <FormikControl
                      control="select"
                      data={weekList}
                      label="Work Weeks"
                      name="weekId"
                      type="text"
                      variant="standard"
                      values={formik.values.weekId}
                    />
                  </Grid>
                )}
                {!formik.values.weekId && (
                  <Grid item md={2} xs={12}>
                    <FormikControl
                      control="datepicker"
                      title="Start Date"
                      type="date"
                      label="Start Date"
                      name="startDate"
                      variant="standard"
                    />
                  </Grid>
                )}
                {!formik.values.weekId && (
                  <Grid item md={2} xs={12}>
                    <FormikControl
                      control="datepicker"
                      title="Load Start Date"
                      type="date"
                      label="End Date"
                      name="endDate"
                      variant="standard"
                    />
                  </Grid>
                )}
                <ProtectedElement
                  name="DriverDashboardPage"
                  accessLevel="DenyDelete"
                >
                  <Grid item md={2} xs={12}>
                    <FormikControl
                      control="select"
                      data={driverList}
                      label="Drivers"
                      name="driverId"
                      type="text"
                      variant="standard"
                      values={formik.values.driverId}
                    />
                  </Grid>
                </ProtectedElement>
                <Grid item md={1} xs={12}>
                  <Box pt={3} justifyContent="flex-end">
                    <Button
                      fullWidth
                      type="submit"
                      color="primary"
                      variant="outlined"
                      disabled={!formik.isValid || formik.isSubmitting}
                    >
                      Search
                    </Button>
                  </Box>
                </Grid>
                <Grid item md={1} xs={12}>
                  <Box pt={3} justifyContent="flex-end">
                    <Button
                      fullWidth
                      type="button"
                      onClick={() => onRefresh()}
                      color="warning"
                      variant="outlined"
                      disabled={!formik.isValid || formik.isSubmitting}
                    >
                      Refresh
                    </Button>
                  </Box>
                </Grid>
                <Grid item md={1} xs={12}>
                  <Box pt={3} justifyContent="flex-end">
                    <Button
                      fullWidth
                      color="error"
                      onClick={() => formik.handleReset()}
                      variant="outlined"
                    >
                      Clear
                    </Button>
                  </Box>
                </Grid>
                <Grid item md={1} xs={12} />
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

SearchFilters.propTypes = {
  driverList: PropTypes.array,
  weekList: PropTypes.array,
  setYear: PropTypes.func,
  selectedYear: PropTypes.number,
  onSearch: PropTypes.func
}

export default SearchFilters
