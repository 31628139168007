import React from "react"
import { Box } from "@material-ui/core"
import { Page, PageSection, SearchBar } from "src/components"
import SuspenseWrapper from "src/components/layout/SuspenseWrapper"
import CookieDataTable from "./components/CookieDataTable"

const CookieAdminPage = () => {
  const [addNewItem, setAddNewItem] = React.useState(false)

  return (
    <>
      <Page title="Cookie Management">
        <SearchBar
          name="Cookie"
          addNewItem={() => setAddNewItem(true)}
          mb={3}
        />
        <PageSection>
          <Box sx={{ pt: 3 }}>
            <SuspenseWrapper>
              <CookieDataTable
                addNewItem={addNewItem}
                onAddItemClose={() => setAddNewItem(false)}
              />
            </SuspenseWrapper>
          </Box>
        </PageSection>
      </Page>
    </>
  )
}

CookieAdminPage.propTypes = {}

export default CookieAdminPage
