import React from "react"
import PropTypes from "prop-types"
import { green } from "@material-ui/core/colors"
import { CircularProgress, Fab } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
    cursor: "pointer",
    maxWidth: "10%"
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}))

const ProgressButton = ({ children, onClick }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.wrapper} onClick={onClick}>
        <Fab
          aria-label="save"
          color="primary"
          // className={buttonClassname}
          size="small"
          component="button"
        >
          <HighlightOffTwoToneIcon />
        </Fab>

        <CircularProgress size={52} className={classes.fabProgress} />
        {children}
      </div>
    </>
  )
}

ProgressButton.propTypes = {
  children: PropTypes.element,
  onClick: PropTypes.func
}

export default ProgressButton
