/* eslint-disable no-unused-vars */
import axios from "axios"

export const fetchWeeks = async ({ queryKey }) => {
  const [_, param] = queryKey
  const result = await axios.get(`/api/Lookup/GetWorkWeekList?year=${param}`)
  return result.data
}

export const fetchCompanyDrivers = async () => {
  const result = await axios.get("/api/Lookup/GetCompanyDrivers")
  return result.data
}

export const fetchDriversGrossIncome = async ({ queryKey }) => {
  const [_, param] = queryKey
  const result = await axios.get(
    `/api/DriverDashboard/GetDriversGrossIncome?year=${param.year}&week=${param.weekId}&startDate=${param.startDate}&endDate=${param.endDate}&driverId=${param.driverId}`
  )
  return result.data
}
