/* eslint-disable no-unused-vars */
import React from "react"
import {
  Box,
  Button,
  Grid,
  MenuItem,
  SvgIcon,
  TextField
} from "@material-ui/core"
import { PageSection, FormikControl, Page, Select } from "src/components"
import SuspenseWrapper from "src/components/layout/SuspenseWrapper"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import { DocumentUpload, useWeeks } from "src/modules/shared"
import { Search as SearchIcon } from "react-feather"
import { useEnhancedMutate } from "src/hooks"
import UploadDocument from "src/modules/shared/UploadDocument"
import { useQueryClient } from "react-query"
import { useCompareInvoice } from "./api/hooks"
import { importInvoice, saveImportedLoads } from "./api/api"
import LoadDataTable from "./components/LoadDataTable"
import { useLookupData } from "../../loadboard/api/hooks"

const InvoiceImportPage = () => {
  const [selectedInvoiceWeek, setSelectedInvoiceWeek] = React.useState(0)
  const [selectedWeek, setSelectedWeek] = React.useState(0)
  const [lookupData, setLookupData] = React.useState(null)
  const lookupQuery = useLookupData()
  const onSubmit = async values => {
    setSelectedWeek(values.weekId)
  }
  const [loadsMissingInInvoice, setLoadsMissingInInvoice] = React.useState()
  const [loadsMissingInBoard, setLoadsMissingInBoard] = React.useState()

  const [weekList, setWeekList] = React.useState([])
  const [selectedInvoiceYear, setSelectedInvoiceYear] = React.useState(
    new Date().getFullYear()
  )
  const [selectedYear, setSelectedYear] = React.useState(
    new Date().getFullYear()
  )
  const weeksDataResult = useWeeks(selectedInvoiceYear)
  const compareInvoiceResult = useCompareInvoice(selectedWeek)

  const queryClient = useQueryClient()
  const onRefresh = () => {
    queryClient.invalidateQueries(["fetchCompareInvoice", selectedWeek])
  }

  React.useEffect(() => {
    if (weeksDataResult.isFetched) {
      setWeekList(weeksDataResult.data)
    }
    if (compareInvoiceResult.isFetched) {
      setLoadsMissingInBoard(compareInvoiceResult.data.loadsMissingInBoard)
      setLoadsMissingInInvoice(compareInvoiceResult.data.loadsMissingInInvoice)
    }
  }, [weeksDataResult, compareInvoiceResult])

  React.useEffect(() => {
    if (lookupQuery.isFetched) {
      setLookupData(lookupQuery.data)
    }
  }, [lookupQuery])

  const initialValues = {
    weekId: ""
  }

  const [uploadDocument] = useEnhancedMutate(importInvoice, {
    customSuccessMsg: "Invoice Imported Successfully"
  })

  const handleOnUpload = async formData => {
    formData.append("WeekId", selectedInvoiceWeek || 0)
    await uploadDocument(formData)
  }

  const handleChange = e => {
    setSelectedInvoiceWeek(e.target.value)
  }

  const validationSchema = Yup.object().shape({
    // origin: Yup.string().required("Origin city is required")
  })

  return (
    <>
      <Page title="Invoice Import Page">
        <PageSection>
          <Grid container spacing={3}>
            <Grid item xs={12} md={1}>
              <TextField
                id="outlined-select-currency"
                select
                fullWidth
                margin="normal"
                label="Work Year"
                onChange={e => setSelectedInvoiceYear(e.target.value)}
                value={selectedInvoiceYear}
                variant="standard"
              >
                {[2021, 2022, 2023, 2024, 2025].map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                margin="normal"
                variant="standard"
                name="Week"
                label="Work Weeks"
                onChange={e => handleChange(e)}
                select
                defaultValue=""
              >
                {weekList &&
                  weekList.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <SuspenseWrapper>
                <UploadDocument onUpload={handleOnUpload} />
              </SuspenseWrapper>
            </Grid>
          </Grid>
        </PageSection>
        <PageSection>
          <Box sx={{ pt: 3 }}>
            <SuspenseWrapper>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnMount
                enableReinitialize
              >
                {formik => {
                  return (
                    <Form>
                      <Grid container spacing={1}>
                        <Grid item md={1} xs={12}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            fullWidth
                            margin="normal"
                            label="Work Year"
                            onChange={e => setSelectedYear(e.target.value)}
                            value={selectedYear}
                            variant="standard"
                          >
                            {[2021, 2022, 2023, 2024, 2025].map(option => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        {!formik.values.startDate && !formik.values.endDate && (
                          <Grid item md={2} xs={12}>
                            <FormikControl
                              control="select"
                              data={weekList}
                              label="Work Weeks"
                              name="weekId"
                              type="text"
                              variant="standard"
                              values={formik.values.weekId}
                            />
                          </Grid>
                        )}
                        <Grid item md={1} xs={12}>
                          <Box pt={3} justifyContent="flex-end">
                            <Button
                              fullWidth
                              type="submit"
                              color="primary"
                              variant="outlined"
                              startIcon={
                                <SvgIcon fontSize="small" color="action">
                                  <SearchIcon />
                                </SvgIcon>
                              }
                              disabled={!formik.isValid || formik.isSubmitting}
                            >
                              Search
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item md={1} xs={12}>
                          <Box pt={3} justifyContent="flex-end">
                            <Button
                              fullWidth
                              type="button"
                              onClick={() => onRefresh()}
                              color="warning"
                              variant="outlined"
                            >
                              Refresh
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Form>
                  )
                }}
              </Formik>
            </SuspenseWrapper>
          </Box>
        </PageSection>
        {loadsMissingInInvoice && (
          <PageSection name="Loads Missing in Invoice">
            <LoadDataTable data={loadsMissingInInvoice} />
          </PageSection>
        )}
        {loadsMissingInBoard && (
          <PageSection name="Invoiced loads missing in Board">
            <LoadDataTable data={loadsMissingInBoard} />
          </PageSection>
        )}
      </Page>
    </>
  )
}

InvoiceImportPage.propTypes = {}

export default InvoiceImportPage
