/* eslint-disable no-unused-vars */
import axios from "axios"

export const fetchIftaReport = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars, no-debugger
  const [_, param] = queryKey
  const result = await axios.post(`/api/Accounting/GetIftaReport`, param)
  return result.data
}

export const fetchIftaReportSummary = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars, no-debugger
  const [_, param] = queryKey
  const result = await axios.post(`/api/Accounting/GetIftaReportSummary`, param)
  return result.data
}

export const importInvoice = async file => {
  const result = await axios.post(`/api/Accounting/ImportInvoice/`, file)
  return result.data
}

export const fetchLookupData = async () => {
  const fetchStates = await axios.get("/api/Lookup/GetStates")
  const fetchIftaDrivers = await axios.get(`/api/Lookup/GetIftaDrivers`)

  const response = await Promise.all([fetchStates, fetchIftaDrivers])

  return {
    stateList: response[0].data,
    iftaDrivers: response[1].data
  }
}
