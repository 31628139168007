/* eslint-disable no-unused-vars */
import React from "react"
import { NavLink as RouterLink, matchPath, useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  List,
  ListItem,
  Typography
} from "@material-ui/core"
import { navItems } from "src/routes"
import { makeStyles } from "@material-ui/styles"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ProtectedNav from "../auth/components/ProtectedNav"

const AppLayoutNavBar = ({ isOpen }) => {
  const location = useLocation()
  const [expanded, setExpanded] = React.useState(false)

  const useStyles = makeStyles(theme => ({
    root: {
      width: isOpen ? "100%" : "10%"
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary
    }
  }))
  const classes = useStyles()

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const isActive = href => {
    const active = href
      ? !!matchPath(
          {
            path: href,
            end: false
          },
          location.pathname
        )
      : false

    return active
  }

  const singleNav = item => {
    return (
      <ProtectedNav name={item.object} key={item.title}>
        <ListItem
          disableGutters
          sx={{
            display: "flex",
            py: 0
          }}
        >
          <Button
            component={RouterLink}
            sx={{
              color: "text.secondary",
              fontWeight: "medium",
              justifyContent: "flex-start",
              letterSpacing: 0,
              py: 1.25,
              textTransform: "none",
              width: "100%",
              ...(isActive(item.href) && {
                color: "primary.main"
              }),
              "& svg": {
                mr: 1
              }
            }}
            to={item.href}
          >
            {item.icon}
            {isOpen && <span>{item.title}</span>}
          </Button>
        </ListItem>
      </ProtectedNav>
    )
  }

  return (
    <>
      <List>
        {navItems.map(item => (
          <Box key={item.title}>
            {item.role === "single" && singleNav(item)}
            {item.role === "parent" && (
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                sx={{ marginY: 0 }}
                key={item.title}
              >
                <AccordionSummary
                  expandIcon={isOpen && <ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{ paddingLeft: 1 }}
                >
                  {item.icon}
                  {isOpen && (
                    <Typography
                      sx={{
                        color: "text.secondary",
                        fontWeight: "medium",
                        justifyContent: "flex-start",
                        letterSpacing: 0,
                        textTransform: "none",
                        width: "100%",
                        pl: 1,
                        ...(isActive(item.href) && {
                          color: "primary.main"
                        }),
                        "& svg": {
                          mr: 1
                        }
                      }}
                    >
                      {item.title}
                    </Typography>
                  )}
                </AccordionSummary>
                {navItems
                  .filter(x => x.parent === item.object)
                  .map(child => (
                    <AccordionDetails sx={{ paddingY: 0 }} key={child.title}>
                      {singleNav(child)}
                    </AccordionDetails>
                  ))}
              </Accordion>
            )}
          </Box>
        ))}
      </List>
    </>
  )
}

AppLayoutNavBar.propTypes = {
  isOpen: PropTypes.bool
}

export default AppLayoutNavBar
