import axios from "axios"

export const fetchBrokers = async () => {
  const result = await axios.get("/api/Broker")
  return result.data
}

export const saveBroker = async data => {
  const result = await axios.post("/api/Broker/AddUpdateBroker", data)
  return result.data
}

export const deleteBroker = async data => {
  const result = await axios.delete(
    `/api/Broker/DeleteBroker?brokerId=${data.brokerID}`
  )
  return result.data
}

export const fetchLookupData = async () => {
  const fetchStates = await axios.get("/api/Lookup/GetStates")
  const response = await Promise.all([fetchStates])

  return {
    states: response[0].data
  }
}
