import React from "react"
import { Box, Card, CardContent, CardHeader } from "@material-ui/core"
import PropTypes from "prop-types"

const PageSection = ({ name, children, hidden, actionElement }) => {
  return (
    <>
      {!hidden && (
        <Box sx={{ mt: 3 }}>
          <Card>
            {name && (
              <CardHeader
                title={name}
                action={actionElement}
                sx={{ pb: 0, mb: 0 }}
              />
            )}
            <CardContent>{children}</CardContent>
          </Card>
        </Box>
      )}
    </>
  )
}

PageSection.propTypes = {
  name: PropTypes.string,
  // eslint-disable-next-line max-len
  children: PropTypes.node.isRequired, // Takes care of single element and array of elements passed as a child
  hidden: PropTypes.bool,
  actionElement: PropTypes.element
}

export default PageSection
