import React from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Box, TableCell, TableRow } from "@material-ui/core"
import { EnhancedTable } from "src/components"
import PropTypes from "prop-types"

const IftaMilesDataTable = ({ data }) => {
  const headers = [
    {
      id: "quarter",
      numeric: false,
      disablePadding: false,
      label: "Quarter"
    },
    {
      id: "unitNumber",
      numeric: false,
      disablePadding: false,
      label: "Unit Number"
    },
    {
      id: "stateName",
      numeric: false,
      disablePadding: false,
      label: "State"
    },
    {
      id: "miles",
      numeric: false,
      disablePadding: false,
      label: "Miles"
    },
    {
      id: "createdOn",
      numeric: false,
      disablePadding: false,
      label: "Uploaded on"
    }
  ]

  return (
    <>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {data && (
            <EnhancedTable data={data} headers={headers} dense>
              {data.map(item => {
                return (
                  <TableRow hover key={item.createdOn}>
                    <TableCell>
                      {item.year} Quarter {item.quarter}
                    </TableCell>
                    <TableCell>{item.unitNumber}</TableCell>
                    <TableCell>{item.stateName}</TableCell>
                    <TableCell>{item.miles}</TableCell>
                    <TableCell>{item.createdOn}</TableCell>
                  </TableRow>
                )
              })}
            </EnhancedTable>
          )}
        </Box>
      </PerfectScrollbar>
    </>
  )
}

IftaMilesDataTable.propTypes = {
  data: PropTypes.array
}

export default IftaMilesDataTable
