/* eslint-disable no-unused-vars */
import React from "react"
import {
  Box,
  SvgIcon,
  Grid,
  Button,
  TextField,
  MenuItem
} from "@material-ui/core"
import { Search as SearchIcon } from "react-feather"
import PropTypes from "prop-types"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { FormikControl } from "src/components"
import { ProtectedElement } from "src/components/auth"
import { useWeeks } from "../api/hooks"

const SearchFilters = ({ onSearch, lookupData, children }) => {
  const initialValues = {
    loadNumber: "",
    weekId: 0,
    driverId: 0,
    startDate: "",
    endDate: ""
  }

  const [selectedYear, setSelectedYear] = React.useState(
    new Date().getFullYear()
  )
  const [weekList, setWeekList] = React.useState([])

  const weeksData = useWeeks(selectedYear)

  React.useEffect(() => {
    if (weeksData.isFetched) {
      setWeekList(weeksData.data)
    }
  }, [weeksData])

  const { driverList } = lookupData

  const validationSchema = Yup.object().shape({
    weekId: Yup.number(),
    driverId: Yup.number(),
    startDate: Yup.date(),
    endDate: Yup.date()
  })

  const onSubmit = async values => {
    await onSearch(values)
  }

  return (
    <Box sx={{ mt: 3 }}>
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
          enableReinitialize
        >
          {formik => {
            return (
              <Form>
                <Grid container spacing={1}>
                  <Grid item md={1} xs={12}>
                    <FormikControl
                      control="input"
                      type="input"
                      label="Load ID"
                      name="loadNumber"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item md={1} xs={12}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      fullWidth
                      margin="normal"
                      label="Work Year"
                      onChange={e => setSelectedYear(e.target.value)}
                      value={selectedYear}
                      variant="standard"
                    >
                      {[2021, 2022, 2023, 2024, 2025].map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {!formik.values.startDate && !formik.values.endDate && (
                    <Grid item md={2} xs={12}>
                      <FormikControl
                        control="select"
                        data={weekList}
                        label="Work Weeks"
                        name="weekId"
                        type="text"
                        variant="standard"
                        values={formik.values.weekId}
                      />
                    </Grid>
                  )}
                  {!formik.values.weekId && (
                    <Grid item md={2} xs={12}>
                      <FormikControl
                        control="datepicker"
                        title="Start Date"
                        type="date"
                        label="Start Date"
                        name="startDate"
                        variant="standard"
                      />
                    </Grid>
                  )}
                  {!formik.values.weekId && (
                    <Grid item md={2} xs={12}>
                      <FormikControl
                        control="datepicker"
                        title="Load Start Date"
                        type="date"
                        label="End Date"
                        name="endDate"
                        variant="standard"
                      />
                    </Grid>
                  )}
                  <ProtectedElement
                    name="DriverDashboardPage"
                    accessLevel="DenyDelete"
                  >
                    <Grid item md={3} xs={12}>
                      <FormikControl
                        control="select"
                        data={driverList}
                        label="Drivers"
                        name="driverId"
                        type="text"
                        variant="standard"
                        values={formik.values.driverId}
                      />
                    </Grid>
                  </ProtectedElement>
                  <Grid item md={1} xs={12}>
                    <Box pt={3} justifyContent="flex-end">
                      <Button
                        fullWidth
                        type="submit"
                        color="primary"
                        variant="outlined"
                        startIcon={
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        }
                        disabled={!formik.isValid || formik.isSubmitting}
                      >
                        Search
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item md={1} xs={12}>
                    <Box pt={3} justifyContent="flex-end">
                      <Button
                        fullWidth
                        color="error"
                        onClick={() => formik.handleReset()}
                        variant="outlined"
                      >
                        Clear
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </>
    </Box>
  )
}

SearchFilters.propTypes = {
  lookupData: PropTypes.object,
  onSearch: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.any])
}

export default SearchFilters
