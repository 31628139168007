/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-indent */
/* eslint-disable operator-linebreak */
import React from "react"
import PropTypes from "prop-types"
import { Formik, Form } from "formik"
import {
  Box,
  Button,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core"
import { FormikControl, IconButton } from "src/components"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import * as Yup from "yup"
import { Search } from "react-feather"
import { Add, Remove } from "@material-ui/icons"
import { fetchCities } from "../api/api"

const CitySelector = ({ form, multiple, property, label, variant }) => {
  const [originCities, setOriginCities] = React.useState([])
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const [check, setCheck] = React.useState(false)
  const [selectedCities, setSelectedCities] = React.useState([])

  const formikLabel = () => {
    let value = ""
    if (multiple) {
      value = form.values[property].map(item => item.label)
      if (Array.isArray(value)) {
        value = value.join(", ")
      }
    }

    if (!multiple) {
      value =
        form.values.origin === ""
          ? form.values.origin
          : form.values.origin.label
    }

    return value
  }

  const onItemSelect = val => {
    selectedCities.push(val)
    setCheck(!check)
    setSelectedCities(selectedCities)
  }

  const onItemRemove = val => {
    setSelectedCities(selectedCities.filter(item => item !== val))
  }

  const onClose = () => {
    setIsDialogOpen(false)
    setSelectedCities([])
  }

  const onFormSave = () => {
    if (multiple) {
      form.setFieldValue(
        property,
        selectedCities.map(item => item.value)
      )
      // form.values[property] = selectedCities.map(item => item.value)
    } else {
      form.setFieldValue(
        property,
        selectedCities.length > 0 ? selectedCities[0].value : ""
      )
      // eslint-disable-next-line prefer-destructuring
      // form.values[property] =
      //   selectedCities.length > 0 ? selectedCities[0].value : ""
    }
    formikLabel()
    onClose()
  }

  const onSubmit = async e => {
    const result = await fetchCities(e.searchValue)
    setOriginCities(result)
    // if (result.substr(0, 5) === "ERROR") {
    //   onClose()
    // } else {
    //   // eslint-disable-next-line no-debugger
    //   debugger
    //   setOriginCities(result)
    // }
  }

  const initialValues = {
    searchValue: ""
  }

  const validationSchema = Yup.object().shape({
    searchValue: Yup.string().required("Brokers name is required")
  })

  return (
    <>
      <TextField
        value={formikLabel()}
        onClick={() => setIsDialogOpen(true)}
        disabled
        variant={variant}
        label={label}
        sx={{ pt: 2 }}
        fullWidth
      />
      <Dialog
        open={isDialogOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose()
          }
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Update Location</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
            enableReinitialize
          >
            {formik => {
              return (
                <>
                  <Form>
                    <Grid
                      container
                      spacing={5}
                      alignItems="center"
                      justify="center"
                      // style={{ minHeight: "100vh" }}
                    >
                      <Grid item md={8} xs={8}>
                        <FormikControl
                          control="input"
                          title="Search City"
                          type="text"
                          label="Search City"
                          name="searchValue"
                          variant="standard"
                        />
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <Button
                          sx={{ mt: 2 }}
                          type="submit"
                          color="primary"
                          variant="outlined"
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          <Search />
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <PerfectScrollbar>
                          <Box sx={{ minWidth: 300 }}>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    colSpan={2}
                                    sx={{ color: "green" }}
                                    align="center"
                                  >
                                    Searching
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ width: "70%" }}>
                                    Name
                                  </TableCell>
                                  <TableCell style={{ width: "30%" }}>
                                    Action
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {originCities &&
                                  originCities.map(item => (
                                    <TableRow hover key={item.label}>
                                      <TableCell>{item.label}</TableCell>
                                      <TableCell>
                                        {multiple && (
                                          <IconButton
                                            size="small"
                                            aria-label="Edit"
                                            onClick={() => onItemSelect(item)}
                                          >
                                            <Add />
                                          </IconButton>
                                        )}
                                        {!multiple &&
                                          selectedCities.length === 0 && (
                                            <IconButton
                                              size="small"
                                              aria-label="Edit"
                                              onClick={() => onItemSelect(item)}
                                            >
                                              <Add />
                                            </IconButton>
                                          )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </PerfectScrollbar>
                      </Grid>
                      <Grid item xs={6}>
                        <PerfectScrollbar>
                          <Box sx={{ minWidth: 300 }}>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    colSpan={2}
                                    align="center"
                                    sx={{ color: "red" }}
                                  >
                                    Selected
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ width: "70%" }}>
                                    Name
                                  </TableCell>
                                  <TableCell style={{ width: "30%" }}>
                                    Action
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selectedCities &&
                                  selectedCities.map(item => (
                                    <TableRow hover key={item.label}>
                                      <TableCell sx={{ color: "blue" }}>
                                        {item.label}
                                      </TableCell>
                                      <TableCell>
                                        <IconButton
                                          size="small"
                                          aria-label="Edit"
                                          onClick={() => onItemRemove(item)}
                                        >
                                          <Remove />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </PerfectScrollbar>
                      </Grid>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Divider />
                      <Box display="flex" justifyContent="flex-end">
                        <Button onClick={onClose} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={onFormSave}>Save</Button>
                      </Box>
                    </Grid>
                  </Form>
                </>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}

CitySelector.propTypes = {
  form: PropTypes.object,
  multiple: PropTypes.bool,
  property: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string
}

export default CitySelector
