/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography
} from "@material-ui/core"
import React, { useState, useEffect } from "react"
import PropTypes, { func } from "prop-types"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import { ProtectedElement } from "src/components/auth"

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: 5
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: "bold",
    font: theme.palette.text.primary
  },
  CardContent: {
    paddingBottom: 0
  },
  CardHeader: {
    backgroundColor: theme.palette.text.secondary,
    paddingTop: 4,
    paddingBottom: 2,
    marginBottom: 0
  }
}))

const LoadDataTableMobile = ({ data, editItem, deleteItem }) => {
  const classes = useStyles()
  const bull = <span className={classes.bullet}>•</span>
  return (
    <>
      {data.map(item => {
        return (
          <Card
            className={classes.root}
            variant="outlined"
            key={item.loadId + item.payout}
          >
            <CardHeader
              className={classes.CardHeader}
              title={
                <Grid container>
                  <Grid item xs={3}>
                    <Typography className={classes.title} gutterBottom>
                      Load:
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className={classes.title} gutterBottom>
                      {item.loadNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      className={classes.title}
                      gutterBottom
                      sx={{ textAlign: "end" }}
                    >
                      ${item.pricePerMile} / ${item.payout.toLocaleString()}
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
            <CardContent className={classes.CardContent}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Location:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {item.originCity} {item.originStateName} {bull}{" "}
                    {item.destinationCity} {item.destinationStateName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Date:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {moment(item.loadStartDate).format("MM/DD/YYYY HH:mm")}{" "}
                    {bull} {moment(item.loadEndDate).format("MM/DD/YYYY HH:mm")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Load Status:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {item.loadStatusName}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {item.numberOfStops} Stops / {item.tripLength}mi
                  </Typography>
                </Grid>
              </Grid>
              {item.comments && (
                <Grid container>
                  <Grid item xs={3}>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Comments:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {item.comments}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </CardContent>
            <CardActions>
              <Box display="flex" justifyContent="flex-end">
                <ProtectedElement name="LoadBoardPage" accessLevel="Write">
                  <Button size="small" onClick={() => editItem(item)}>
                    Edit
                  </Button>
                </ProtectedElement>
                <ProtectedElement name="LoadBoardPage" accessLevel="DenyDelete">
                  <Button size="small" onClick={() => deleteItem(item)}>
                    Delete
                  </Button>
                </ProtectedElement>
              </Box>
            </CardActions>
          </Card>
        )
      })}
    </>
  )
}

LoadDataTableMobile.propTypes = {
  data: PropTypes.array,
  editItem: PropTypes.func,
  deleteItem: PropTypes.func
}

export default LoadDataTableMobile
