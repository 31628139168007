import React from "react"
import PropTypes from "prop-types"
import { useAuth } from "src/hooks"

// eslint-disable-next-line no-unused-vars
const ProtectedElement = ({ children, name, accessLevel, ...rest }) => {
  const auth = useAuth()
  const { currentUser } = auth

  let minAccessLevel = "Read"

  switch (accessLevel) {
    case "Write":
      minAccessLevel = ["Write"]
      break
    case "DenyDelete":
      minAccessLevel = ["DenyDelete", "Write"]
      break
    default:
      minAccessLevel = ["Read", "DenyDelete", "Write"]
  }

  const [permissiobleObject] = currentUser.userPermissibleObjects.filter(
    x => x.objectName === name
  )

  if (
    currentUser &&
    permissiobleObject &&
    minAccessLevel.some(x => x === permissiobleObject.accessType)
  ) {
    return <>{children}</>
  }

  return <></>
}

ProtectedElement.propTypes = {
  children: PropTypes.object,
  name: PropTypes.string,
  accessLevel: PropTypes.string
}

export default ProtectedElement
