import { useQuery } from "react-query"
import { fetchWeeks, fetchCompanyDrivers, fetchDriversGrossIncome } from "./api"

export const useWeeks = params => {
  return useQuery(["fetchWeeks", params], fetchWeeks)
}

export const useCompanyDrivers = () => {
  return useQuery("fetchCompanyDrivers", fetchCompanyDrivers)
}

export const useDriversGross = params => {
  return useQuery(
    ["fetchDriversGrossIncome", params],
    fetchDriversGrossIncome,
    {
      enabled: params === false ? params : true,

      refetchOnWindowFocus: false
    }
  )
}
