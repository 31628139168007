import React from "react"
import PropTypes from "prop-types"
import { Formik, Form } from "formik"
import {
  Box,
  Button,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  MenuItem
} from "@material-ui/core"
import { FormikControl } from "src/components"
import * as Yup from "yup"

const AddEditItem = ({
  onSave,
  onClose,
  setYear,
  data,
  isDialogOpen,
  editItem,
  weekList,
  selectedYear
}) => {
  const { stateList, loadStatusList, brokerList, driverList } = data

  const onSubmit = async values => {
    onSave(values)
  }

  const onDuplicate = async () => {
    const duplicate = editItem
    duplicate.loadId = 0
    onSave(duplicate)
  }

  const initialValues = {
    loadId: "",
    loadNumber: "",
    brokerId: "",
    loadStartDate: "",
    loadEndDate: "",
    weekId: "",
    originStateId: "",
    originCity: "",
    destinationStateId: "",
    destinationCity: "",
    tripLength: "",
    numberOfStops: "",
    pricePerMile: "",
    payout: "",
    loadStatusId: "",
    loadStatusName: "",
    loadDrivers: undefined,
    isDispute: false,
    comments: "",
    createdBy: "",
    createdOn: "",
    updatedBy: "",
    updatedOn: ""
  }

  const validationSchema = Yup.object().shape({
    loadNumber: Yup.string().required("Field is required"),
    brokerId: Yup.number().required("Field is required"),
    loadStartDate: Yup.date().required("Field is required"),
    loadEndDate: Yup.date().required("Field is required"),
    weekId: Yup.number().required("Field is required"),
    originStateId: Yup.number().required("Field is required"),
    originCity: Yup.string().required("Field is required"),
    destinationStateId: Yup.number().required("Field is required"),
    destinationCity: Yup.string().required("Field is required"),
    tripLength: Yup.number().required("Field is required"),
    numberOfStops: Yup.number().required("Field is required"),
    pricePerMile: Yup.number().min(0).required("Field is required"),
    loadStatusId: Yup.number().required("Field is required"),
    comments: Yup.string().nullable()
  })

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose()
          }
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {editItem ? "Update load" : "Add load"}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={editItem || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
            enableReinitialize
          >
            {formik => {
              return (
                <Form>
                  <Grid container spacing={1}>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="input"
                        title="Load Number"
                        type="text"
                        label="Load Number"
                        name="loadNumber"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="select"
                        data={brokerList}
                        label="Broker"
                        name="brokerId"
                        type="text"
                        variant="standard"
                        value={formik.values.brokerId}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="datepicker"
                        title="Load Start Date"
                        type="datetime-local"
                        label="Load Start Date"
                        name="loadStartDate"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="datepicker"
                        title="Load End Date"
                        type="datetime-local"
                        label="Load End Date"
                        name="loadEndDate"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        fullWidth
                        margin="normal"
                        label="Work Year"
                        onChange={e => setYear(e.target.value)}
                        value={selectedYear}
                        variant="standard"
                      >
                        {[2021, 2022, 2023, 2024, 2025].map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={9} xs={12}>
                      <FormikControl
                        control="select"
                        data={weekList}
                        label="Week"
                        name="weekId"
                        type="text"
                        variant="standard"
                        value={formik.values.weekId}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormikControl
                        control="input"
                        title="Origin City"
                        type="text"
                        label="Origin City"
                        name="originCity"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormikControl
                        control="select"
                        data={stateList}
                        label="Origin State"
                        name="originStateId"
                        type="text"
                        variant="standard"
                        value={formik.values.originStateId}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormikControl
                        control="input"
                        title="Destination City"
                        type="text"
                        label="Destination City"
                        name="destinationCity"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormikControl
                        control="select"
                        data={stateList}
                        label="Destination State"
                        name="destinationStateId"
                        type="text"
                        variant="standard"
                        value={formik.values.destinationStateId}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormikControl
                        control="input"
                        title="Price Per Mile"
                        type="number"
                        label="Price Per Mile"
                        name="pricePerMile"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormikControl
                        control="input"
                        title="Payout"
                        type="number"
                        label="Payout"
                        name="payout"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormikControl
                        control="input"
                        title="Trip Length"
                        type="number"
                        label="Trip Length"
                        name="tripLength"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormikControl
                        control="input"
                        title="Number Of Stops"
                        type="number"
                        label="Number Of Stops"
                        name="numberOfStops"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="select"
                        data={loadStatusList}
                        label="Load Status"
                        name="loadStatusId"
                        type="text"
                        variant="standard"
                        value={formik.values.loadStatusId}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="multiselect"
                        data={driverList}
                        label="Drivers"
                        name="loadDrivers"
                        type="text"
                        variant="standard"
                        form={formik}
                        values={formik.values.loadDrivers}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FormikControl
                        control="input"
                        title="Comments"
                        type="text"
                        label="Comments"
                        name="comments"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Divider />
                      <Box display="inline-flex">
                        <Box display="flex" justifyItems="flex-start">
                          <Button onClick={() => onDuplicate()} color="primary">
                            Duplicate
                          </Button>
                        </Box>
                        <Box display="flex" justifyItems="flex-end">
                          <Button onClick={onClose} color="primary">
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={!formik.isValid || formik.isSubmitting}
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}

AddEditItem.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  data: PropTypes.object,
  isDialogOpen: PropTypes.bool,
  editItem: PropTypes.object,
  setYear: PropTypes.func,
  weekList: PropTypes.array,
  selectedYear: PropTypes.number
}

export default AddEditItem
