/* eslint-disable no-unused-vars */
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  Layers,
  DollarSign,
  Command,
  Search,
  Archive,
  Aperture,
  Activity,
  Anchor,
  Download,
  Film
} from "react-feather"

const navItems = [
  {
    role: "single",
    object: "DriverDashboardPage",
    title: "Driver Dashboard",
    href: "/app/dashboard-driver",
    icon: <Layers size="20" />
  },
  {
    role: "single",
    object: "LoadHistoryPage",
    title: "Load History",
    href: "/app/load-history",
    icon: <Activity size="20" />
  },
  {
    role: "single",
    object: "UserAdminPage",
    title: "User Management",
    href: "/app/user-admin",
    icon: <UsersIcon size="20" />
  },
  {
    role: "single",
    object: "BrokerAdminPage",
    title: "Broker Management",
    href: "/app/broker-admin",
    icon: <Anchor strokeWidth="3" size="20" />
  },
  {
    role: "single",
    object: "ExpenseAdminPage",
    title: "Expenses",
    href: "/app/accounting/expenses",
    icon: <DollarSign strokeWidth="3" size="20" />
  },
  {
    role: "single",
    object: "IftaReportPage",
    title: "Ifta Report",
    href: "/app/accounting/ifta-report",
    icon: <Film strokeWidth="3" size="20" />
  },
  {
    role: "single",
    object: "LoadBoardPage",
    title: "Load Board",
    href: "/app/loadboard",
    icon: <Command strokeWidth="3" size="20" />
  },
  {
    role: "single",
    object: "LoadSearchPage",
    title: "Load Search",
    href: "/app/relay/loadsearch",
    icon: <Search strokeWidth="3" size="20" />
  },
  {
    role: "single",
    object: "SearchDashboard",
    title: "Search Dashboard",
    href: "/app/relay/search-dashboard",
    icon: <Archive strokeWidth="3" size="20" />
  },
  {
    role: "parent",
    object: "SystemAdministration",
    title: "System",
    href: "/app/system-admin",
    icon: <SettingsIcon size="20" />
  },
  {
    role: "child",
    parent: "SystemAdministration",
    object: "SystemAdminPage",
    title: "System Admin",
    href: "/app/system-admin",
    icon: <SettingsIcon size="20" />
  },
  {
    role: "child",
    parent: "SystemAdministration",
    object: "LoadImportPage",
    title: "Load Import",
    href: "/app/accounting/load-import",
    icon: <Download strokeWidth="3" size="20" />
  },
  {
    role: "child",
    parent: "SystemAdministration",
    object: "InvoiceImportPage",
    title: "Invoice Import",
    href: "/app/accounting/payment-import",
    icon: <Download strokeWidth="3" size="20" />
  },
  {
    role: "child",
    parent: "SystemAdministration",
    object: "FuelImportPage",
    title: "Fuel Import",
    href: "/app/accounting/fuel-import",
    icon: <Download strokeWidth="3" size="20" />
  },
  {
    role: "child",
    parent: "SystemAdministration",
    object: "IftaMilesImportPage",
    title: "IFTA Miles Import",
    href: "/app/accounting/iftamiles-import",
    icon: <Download strokeWidth="3" size="20" />
  },
  {
    role: "child",
    parent: "SystemAdministration",
    object: "CookieAdminPage",
    title: "Cookie Administration",
    href: "/app/relay/cookie-admin",
    icon: <Aperture strokeWidth="3" size="20" />
  },
  {
    role: "single",
    object: "Customers",
    title: "Customers",
    href: "/appm/customers",
    icon: <UserPlusIcon size="20" />
  }
]

export default navItems
