/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"

export const InProgressContext = React.createContext(undefined)

const InProgressBoundary = ({ children }) => {
  const [inProgress, setInProgress] = React.useState(false)

  const setInProgressStart = () => {
    setInProgress(true)
  }

  const setInProgressComplete = () => {
    setInProgress(false)
  }

  const context = {
    setInProgressStart,
    setInProgressComplete,
    inProgress
  }
  return (
    <InProgressContext.Provider value={context}>
      {children}
    </InProgressContext.Provider>
  )
}

InProgressBoundary.propTypes = {
  children: PropTypes.element
}

export default InProgressBoundary
