import React, { useState, useEffect } from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Box, Card, TableCell, TableRow } from "@material-ui/core"
import { useEnhancedMutate } from "src/hooks"
import { EnhancedTable, ConfirmDialog, IconButton } from "src/components"
import PropTypes from "prop-types"
import { DeleteForeverTwoTone } from "@material-ui/icons"
import { ProtectedElement } from "src/components/auth"
import moment from "moment"
import AddEditItem from "./AddEditItem"
import { useCompanyCookies } from "../api/hooks"
import { saveCookie, deleteCookie } from "../api/api"

const CookieDataTable = ({ addNewItem, onAddItemClose, ...rest }) => {
  const [editItem, setEditItem] = useState(null)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState(null)
  const [sortedData, setSortedData] = useState(null)

  const handleOnSort = inData => {
    setSortedData(inData)
  }

  const dataQuery = useCompanyCookies()

  const headers = [
    {
      id: "companyCookieId",
      numeric: false,
      disablePadding: false,
      label: "Id"
    },
    {
      id: "cookie",
      numeric: false,
      disablePadding: false,
      label: "Cookie"
    },
    {
      id: "csrfToken",
      numeric: false,
      disablePadding: false,
      label: "Csrf Token"
    },
    {
      id: "cookieSource",
      numeric: false,
      disablePadding: false,
      label: "Cookie Source"
    },
    {
      id: "createdOn",
      numeric: false,
      disablePadding: false,
      label: "CreatedOn"
    },
    {
      id: "isInUse",
      numeric: false,
      disablePadding: false,
      label: "In Use",
      align: "center"
    },
    {
      id: "action",
      disablePadding: false,
      label: "Action"
    }
  ]

  const handleOnCloseDialog = () => {
    setEditItem(null)
    setIsEditDialogOpen(false)
    onAddItemClose()
    setConfirmDialog({ isDialogOpen: false })
  }

  const [saveBrokerInfo] = useEnhancedMutate(saveCookie, {
    invalidateQueries: [
      {
        queryKey: ["fetchCompanyCookies"]
      }
    ],
    customSuccessMsg: "Company Cookie updated successfully",
    onSuccess: () => {
      handleOnCloseDialog()
    }
  })

  const [deleteItem] = useEnhancedMutate(deleteCookie, {
    invalidateQueries: [
      {
        queryKey: ["fetchCompanyCookies"]
      }
    ],
    customSuccessMsg: "Company Cookie deleted successfully",
    onSuccess: () => {
      handleOnCloseDialog()
    }
  })

  // Used to check if useeffect trigerred by data update
  const [dataUpdatedOn, setDataUpdatedOn] = useState("")

  useEffect(() => {
    if (dataQuery.isFetched && dataUpdatedOn < dataQuery.dataUpdatedAt) {
      setDataUpdatedOn(dataQuery.dataUpdatedAt)
      setSortedData(dataQuery.data)
    }
  }, [dataQuery])

  const handleAddItem = () => {
    setEditItem({})
    setIsEditDialogOpen(true)
  }

  useEffect(() => {
    if (addNewItem) {
      handleAddItem()
    }
  }, [addNewItem])

  const handleOnConfirmDelete = async item => {
    await deleteItem(item)
  }

  const handleDeleteItem = item => {
    const dialog = {
      text: `Are you sure you want to delete cookie ID ${item.companyCookieId}`,
      onConfirm: () => handleOnConfirmDelete(item),
      onCancel: () => handleOnCloseDialog(),
      isDialogOpen: true
    }
    setConfirmDialog(dialog)
  }

  const handleOnSave = item => {
    saveBrokerInfo(item)
    setIsEditDialogOpen(false)
  }

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            {sortedData && (
              <EnhancedTable
                data={sortedData}
                headers={headers}
                onSort={handleOnSort}
                dense
                orderBy="isInUse"
                order="asc"
              >
                {sortedData.map(item => {
                  return (
                    <TableRow hover key={item.companyCookieId}>
                      <TableCell>{item.companyCookieId}</TableCell>
                      <TableCell>{item.cookie.substr(0, 100)}</TableCell>
                      <TableCell align="left">
                        {item.csrfToken.substr(0, 50)}
                      </TableCell>
                      <TableCell align="left">{item.cookieSource}</TableCell>
                      <TableCell align="left">
                        {moment(item.createdOn).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {item.isInUse ? "Yes" : "No"}
                      </TableCell>
                      <TableCell align="left">
                        <ProtectedElement
                          name="BrokerAdminPage"
                          accessLevel="Write"
                        >
                          <IconButton
                            aria-label="Edit"
                            onClick={() => handleDeleteItem(item)}
                          >
                            <DeleteForeverTwoTone />
                          </IconButton>
                        </ProtectedElement>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </EnhancedTable>
            )}
          </Box>
        </PerfectScrollbar>
      </Card>
      <AddEditItem
        onSave={handleOnSave}
        onClose={handleOnCloseDialog}
        editItem={editItem}
        isDialogOpen={isEditDialogOpen}
      />
      <ConfirmDialog props={confirmDialog} />
    </>
  )
}

CookieDataTable.propTypes = {
  addNewItem: PropTypes.bool,
  onAddItemClose: PropTypes.func
}

export default CookieDataTable
