import { Navigate } from "react-router-dom"
import HomePage from "src/modules/fms/pages/home/HomePage"
import { ProtectedNav } from "src/components/auth"
import WeeklyLoadsPrintView from "src/modules/shared/WeeklyLoadsPrintView"
import ProtectedRoute from "./ProtectedRoute"
// import DashboardLayout from "../components/layout/DashboardLayout"
import MainLayout from "../components/layout/MainLayout"
import AppLayout from "../components/layout/AppLayout"
import Account from "../pages/Account"
import CustomerList from "../pages/CustomerList"
import Dashboard from "../pages/Dashboard"
import LoginPage from "../components/auth/pages/LoginPage"
import NotFound from "../pages/NotFound"
import ProductList from "../pages/ProductList"
import Register from "../pages/Register"
import Settings from "../pages/Settings"
import RegisterPage from "../components/auth/pages/RegisterPage"
import InReviewPage from "../components/auth/pages/InReviewPage"
import LoginRoute from "./LoginRoute"
import InReviewRoute from "./InReviewRoute"
import RegisterRoute from "./RegisterRoute"
import UserAdminPage from "../modules/fms/pages/admin/user/UserAdminPage"
import BrokerAdminPage from "../modules/fms/pages/admin/broker/BrokerAdminPage"
import LoadBoardPage from "../modules/fms/pages/loadboard/LoadBoardPage"
import ExpensesPage from "../modules/fms/pages/accounting/expenses/ExpensesPage"
import LoadSearchPage from "../modules/relay/pages/filters/LoadSearchPage"
import SystemAdminPage from "../modules/fms/pages/admin/system/SystemAdminPage"
import DriverDashboardPage from "../modules/fms/pages/dashboard/driver/DriverDashboardPage"
import LoadHistoryPage from "../modules/fms/pages/loadHistory/LoadHistoryPage"
import SearchDashboard from "../modules/relay/pages/dashboard/SearchDashboard"
import CookieAdminPage from "../modules/relay/pages/admin/cookie-admin/CookieAdminPage"
import LoadImportPage from "../modules/fms/pages/accounting/load-import/LoadImportPage"
import InvoiceImportPage from "../modules/fms/pages/accounting/invoice-import/InvoiceImportPage"
import FuelImportPage from "../modules/fms/pages/accounting/fuel-import/FuelImportPage"
import IftaMilesImportPage from "../modules/fms/pages/accounting/iftamiles-import/IftaMilesImportPage"
import IftaReportPage from "../modules/fms/pages/accounting/ifta-report/IftaReportPage"

const routes = [
  {
    path: "app",
    element: (
      <ProtectedRoute name="AppLayout">
        <AppLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "system-admin",
        element: (
          <ProtectedRoute name="SystemAdminPage">
            <SystemAdminPage />
          </ProtectedRoute>
        )
      },
      {
        path: "relay/cookie-admin",
        element: (
          <ProtectedRoute name="CookieAdminPage">
            <CookieAdminPage />
          </ProtectedRoute>
        )
      },
      {
        path: "dashboard-driver",
        element: (
          <ProtectedRoute name="DriverDashboardPage">
            <DriverDashboardPage />
          </ProtectedRoute>
        )
      },
      {
        path: "load-history",
        element: (
          <ProtectedRoute name="LoadHistoryPage">
            <LoadHistoryPage />
          </ProtectedRoute>
        )
      },
      {
        path: "user-admin",
        element: (
          <ProtectedRoute name="UserAdmin">
            <UserAdminPage />
          </ProtectedRoute>
        )
      },
      {
        path: "broker-admin",
        element: (
          <ProtectedRoute name="BrokerAdmin">
            <BrokerAdminPage />
          </ProtectedRoute>
        )
      },
      {
        path: "loadboard",
        element: (
          <ProtectedRoute name="LoadBoardPage">
            <ProtectedNav name="LoadBoardPage">
              <LoadBoardPage />
            </ProtectedNav>
          </ProtectedRoute>
        )
      },
      {
        path: "loadboard/:loadNumber",
        element: (
          <ProtectedRoute name="LoadBoardPage">
            <ProtectedNav name="LoadBoardPage">
              <LoadBoardPage />
            </ProtectedNav>
          </ProtectedRoute>
        )
      },
      {
        path: "accounting/expenses",
        element: (
          <ProtectedRoute name="ExpenseAdminPage">
            <ExpensesPage />
          </ProtectedRoute>
        )
      },
      {
        path: "accounting/load-import",
        element: (
          <ProtectedRoute name="LoadImportPage">
            <LoadImportPage />
          </ProtectedRoute>
        )
      },
      {
        path: "accounting/payment-import",
        element: (
          <ProtectedRoute name="InvoiceImportPage">
            <InvoiceImportPage />
          </ProtectedRoute>
        )
      },
      {
        path: "accounting/fuel-import",
        element: (
          <ProtectedRoute name="ExpenseAdminPage">
            <FuelImportPage />
          </ProtectedRoute>
        )
      },
      {
        path: "accounting/iftamiles-import",
        element: (
          <ProtectedRoute name="IftaMilesImportPage">
            <IftaMilesImportPage />
          </ProtectedRoute>
        )
      },
      {
        path: "accounting/ifta-report",
        element: (
          <ProtectedRoute name="IftaReportPage">
            <IftaReportPage />
          </ProtectedRoute>
        )
      },
      {
        path: "relay/loadsearch",
        element: (
          <ProtectedRoute name="LoadSearchPage">
            <LoadSearchPage />
          </ProtectedRoute>
        )
      },
      {
        path: "relay/search-dashboard",
        element: (
          <ProtectedRoute name="LoadSearchPage">
            <SearchDashboard />
          </ProtectedRoute>
        )
      },
      {
        path: "accounting/weekly-printview",
        element: <WeeklyLoadsPrintView />
      },
      { path: "home", element: <HomePage /> },
      { path: "account", element: <Account /> },
      { path: "customers", element: <CustomerList /> },
      { path: "dashboard", element: <Dashboard /> },
      { path: "products", element: <ProductList /> },
      { path: "settings", element: <Settings /> },
      { path: "*", element: <Navigate to="/404" /> }
    ]
  },
  // {
  //   path: "appm",
  //   element: <DashboardLayout />,
  //   children: [
  //     { path: "account", element: <Account /> },
  //     { path: "customers", element: <CustomerList /> },
  //     { path: "dashboard", element: <Dashboard /> },
  //     { path: "products", element: <ProductList /> },
  //     { path: "settings", element: <Settings /> },
  //     { path: "*", element: <Navigate to="/404" /> }
  //   ]
  // },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "login",
        element: (
          <LoginRoute name="LoginPage">
            <LoginPage />
          </LoginRoute>
        )
      },
      {
        path: "register",
        element: (
          <RegisterRoute name="RegisterPage">
            <RegisterPage />
          </RegisterRoute>
        )
      },
      {
        path: "application-in-review",
        element: (
          <InReviewRoute name="InReviewPage">
            <InReviewPage />
          </InReviewRoute>
        )
      },
      { path: "register", element: <Register /> },
      { path: "404", element: <NotFound /> },
      { path: "/", element: <Navigate to="/app/home" /> },
      { path: "*", element: <Navigate to="/404" /> }
    ]
  }
]

export default routes
