import React from "react"
import { Outlet } from "react-router"
import { makeStyles, styled } from "@material-ui/styles"
import { ThemeProvider } from "@material-ui/core"
// import CssBaseline from "@material-ui/core/CssBaseline"
import { useAppTheme, useAuth } from "../../hooks"
import { Themes } from "../../theme"
import AppLayoutAppBar from "./AppLayoutAppBar"
import { InProgressBoundary } from ".."
import { ToastBoundary } from "../feedback/ToastBoundary"
import SuspenseWrapper from "./SuspenseWrapper"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex"
  }
}))
const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%"
}))

const DashboardLayoutWrapper = styled("div")(() => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64
}))

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden"
})

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto"
})

export default function AppLayout() {
  const classes = useStyles()
  const { mode } = useAppTheme()

  const { refreshLocalSession } = useAuth()

  React.useEffect(() => {
    refreshLocalSession()
  }, [])

  return (
    <div className={classes.root}>
      <ThemeProvider theme={Themes(mode)}>
        <DashboardLayoutRoot>
          <AppLayoutAppBar />
          <DashboardLayoutWrapper>
            <DashboardLayoutContainer>
              <DashboardLayoutContent>
                <InProgressBoundary>
                  <ToastBoundary>
                    <SuspenseWrapper>
                      <Outlet />
                    </SuspenseWrapper>
                  </ToastBoundary>
                </InProgressBoundary>
              </DashboardLayoutContent>
            </DashboardLayoutContainer>
          </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
      </ThemeProvider>
    </div>
  )
}
