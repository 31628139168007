import React from "react"
import { Alert, AlertTitle } from "@material-ui/core"
import PropTypes from "prop-types"
import { styled } from "@material-ui/styles"

const AlertComponent = styled(Alert)`
  margin: 1em;
`

/** Displays an error message. */
const AlertErrorMessage = ({ hidden = true, title, content }) => {
  if (hidden) {
    return null
  }

  return (
    <AlertComponent severity="error">
      {title && <AlertTitle>{title}</AlertTitle>}
      {content}
    </AlertComponent>
  )
}

AlertErrorMessage.propTypes = {
  hidden: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

export default AlertErrorMessage
