/* eslint-disable */
import React from "react"
import { TextField, Box } from "@material-ui/core"
import { Field } from "formik"
import PropTypes from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import Autocomplete from "@material-ui/core/Autocomplete"

const AutoComplete = props => {
  const { name, data, onInputChange, multiple, ...rest } = props
  const variant = rest.variant ? rest.variant : "outlined"

  const setValue = (values, form, fieldName) => {
    const filteredValues = multiple ? values.map(a => a.value) : values.value
    form.setFieldValue(fieldName, filteredValues)
  }

  const getSelectedValues = selectedValues => {
    const selOptionsList =
      typeof selectedValues === "string" ? [selectedValues] : selectedValues
    if (multiple) {
      if (selectedValues !== null) {
        if (Array.isArray(selectedValues) && selectedValues.length > 0) {
          const getValues1 = selectedValues.map(option => option)
          return (
            getValues1 && data.filter(item => getValues1.includes(item.value))
          )
        }
      }
      return []
    }
    return data.find(item => selOptionsList.includes(item.value)) || ""
  }

  const getOptions = () => {
    return rest?.disabled ? [] : data || []
  }

  return (
    <>
      <Field name={name} id={name} {...rest}>
        {fieldProps => {
          // eslint-disable-next-line no-unused-vars
          const { field, form, meta } = fieldProps
          return (
            <>
              <PerfectScrollbar>
                <Autocomplete
                  value={getSelectedValues(field.value)}
                  freeSolo
                  multiple={multiple}
                  id={name}
                  getOptionLabel={option => option.label || ""}
                  options={getOptions()}
                  renderOption={(inputProps, option) => {
                    if (!option || option === {}) {
                      const opt1 = option
                    }
                    return (
                      <Box component="span" {...inputProps} key={option.value}>
                        {option.label}
                      </Box>
                    )
                  }}
                  onInputCapture={e => {
                    onInputChange(e.target.value)
                  }}
                  onChange={(e, val) => {
                    // if (!multiple) {
                    //   setValue(val ? [val] : [], form, name)
                    //   return
                    // }
                    // setValue(val ?? "", form, name)
                    setValue(val ?? "", form, name)
                  }}
                  disabled={rest.disabled}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      margin="normal"
                      {...rest}
                      title={field.name}
                      variant={variant}
                      name={field.name}
                      values={field.value || ""}
                      InputProps={{
                        ...params.InputProps,
                        type: "search"
                      }}
                    />
                  )}
                />
              </PerfectScrollbar>
            </>
          )
        }}
      </Field>
    </>
  )
}

AutoComplete.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.array,
  props: PropTypes.object,
  onInputChange: PropTypes.func,
  multiple: PropTypes.bool
}

export default AutoComplete
