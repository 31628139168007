import React from "react"
import { Navigate } from "react-router"
import PropTypes from "prop-types"
import { useAuth } from "../hooks"

// eslint-disable-next-line no-unused-vars
const LoginRoute = ({ children, name, ...rest }) => {
  const auth = useAuth()
  const { currentUser } = auth
  if (!currentUser) {
    return <>{children}</>
  }

  return <Navigate to="/" />
}

LoginRoute.propTypes = {
  children: PropTypes.object,
  name: PropTypes.string
}

export default LoginRoute
