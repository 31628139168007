import { Helmet } from "react-helmet"
import { Box, Container, Grid, Typography } from "@material-ui/core"

import PageLogo from "src/components/layout/PageLogo"
import { Page } from "src/components"

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>FMS Home</title>
      </Helmet>
      <Page title="Home">
        <Box
          sx={{
            backgroundColor: "background.default",
            display: "flex",
            flexDirection: "column",
            height: "80vh", // set box height to use 80% of screen
            justifyContent: "center"
          }}
        >
          <Container maxWidth="sm">
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="center"
            >
              <Typography color="textSecondary" textAlign="center" variant="h1">
                Welcome to Fleet Management System
              </Typography>
            </Grid>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="center"
            >
              <PageLogo />
            </Grid>
          </Container>
        </Box>
      </Page>
    </>
  )
}

export default HomePage
