import { useQuery } from "react-query"
import {
  fetchUsers,
  fetchCompanies,
  fetchRoles,
  fetchUserStatuses,
  fetchLookupData
} from "./api"

export const useUsers = () => {
  return useQuery("fetchUsers", fetchUsers)
}
export const useCompanies = () => {
  return useQuery("fetchCompanies", fetchCompanies)
}
export const useRoles = () => {
  return useQuery("fetchRoles", fetchRoles)
}
export const useUserStatuses = () => {
  return useQuery("fetchUserStatuses", fetchUserStatuses)
}

export const useLookupData = () => {
  return useQuery("fetchLookupData", fetchLookupData)
}
