import { useQuery } from "react-query"
import {
  fetchLoads,
  fetchLookupData,
  fetchWeeks,
  fetchFilteredLoads
} from "./api"

export const useLoads = () => {
  return useQuery("fetchLoads", fetchLoads)
}

export const useFilteredLoads = params => {
  return useQuery(["fetchFilteredLoads", params], fetchFilteredLoads)
}

export const useLookupData = () => {
  return useQuery("fetchLookupData", fetchLookupData)
}

export const useWeeks = params => {
  return useQuery(["fetchWeeks", params], fetchWeeks)
}
