import React from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { Refresh as RefreshIcon } from "@material-ui/icons"
import PropTypes from "prop-types"

const ErrorDialog = ({ messageDetails }) => {
  return (
    <Dialog open aria-labelledby="error-dialog">
      <DialogTitle id="confirm-dialog">Rented Equipment Error</DialogTitle>
      <DialogContent style={{ paddingBottom: "16px" }}>
        <Alert severity="error">
          <AlertTitle>
            Uh oh. It looks like the app has encountered an error.
          </AlertTitle>
          {messageDetails ? (
            <Box m={2}>
              <Typography className="psaap-font-bold" variant="subtitle2">
                {messageDetails}
              </Typography>
            </Box>
          ) : (
            <Box>
              Please try reloading the page. Thank you for your patience.
            </Box>
          )}
          <Box mt={messageDetails ? 1 : 0} mb={1}>
            If the error continues please contact the Help Desk.
          </Box>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => window.location.reload()}
          color="default"
          startIcon={<RefreshIcon />}
        >
          Reload
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ErrorDialog.propTypes = {
  messageDetails: PropTypes.string
}

export default ErrorDialog
