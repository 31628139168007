import React from "react"
import {
  Brightness7 as SunIcon,
  Brightness4 as MoonIcon
} from "@material-ui/icons"
import { TooltipButton } from "../components"
import { useAppTheme } from "../hooks"

/** Render icon to toggle light/dark mode */
const ToggleTheme = () => {
  const { mode, setThemeMode } = useAppTheme()

  return (
    <TooltipButton
      title={mode === "light" ? "Use Dark Mode" : "Use Light Mode"}
      ariaLabel="toggle-theme"
      onClick={() => setThemeMode()}
      icon={mode === "light" ? <SunIcon /> : <MoonIcon />}
    />
  )
}

export default ToggleTheme
