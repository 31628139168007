/* eslint-disable no-unused-vars */
import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Formik, Form } from "formik"
import {
  Box,
  Button,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle
} from "@material-ui/core"
import { FormikControl } from "src/components"
import * as Yup from "yup"
import {
  radius,
  numberOfStops,
  orderType,
  timeZone,
  workType,
  usStateList,
  loadingType
} from "../api/options"
import CitySelector from "./CitySelector"

const AddEditItem = ({
  onSave,
  onClose,
  isDialogOpen,
  editItem,
  driverList
}) => {
  const formRef = React.useRef()

  const onDialogClose = () => {
    onClose()
  }

  const onSubmit = async values => {
    onDialogClose()
    onSave(values)
  }

  const initialValues = {
    workType: "Any",
    origin: "",
    originRadius: 25,
    destination: [],
    destinationRadius: 25,
    excludedDestinations: [],
    numberOfStops: 3,
    minPickupTime: 10,
    maxDistance: 0,
    minDistance: 0,
    pickupTimeWithin: 2,
    loadStartTime: "",
    loadEndTime: "",
    loadingType: "Any",
    ppm: 2,
    payout: 0,
    driver1: "",
    driver2: "",
    timeZone: "EST",
    orderActionType: 1,
    excludedStates: ["NY"],
    refreshRate: 0.5
  }

  const validationSchema = Yup.object().shape({
    driver1: Yup.string().required("First name is required")
  })

  // const driverList = [
  //   { label: "Jon Yus", value: "Jon Yus" },
  //   { label: "Mukhammadjon Sadikov", value: "Mukhammadjon Sadikov" },
  //   { label: "Odiljon Israilov", value: "Odiljon Israilov" },
  //   { label: "Sardorbek Shakirov", value: "Sardorbek Shakirov" }
  // ]

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          {editItem ? "Update Load Order" : "Add New Load Order"}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={editItem || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
            enableReinitialize
            innerRef={formRef}
          >
            {formik => {
              return (
                <Form>
                  <Grid container spacing={1}>
                    <Grid item md={1} xs={12}>
                      <FormikControl
                        control="select"
                        data={workType}
                        label="Work Type"
                        name="workType"
                        type="text"
                        variant="outlined"
                        value={formik.values.workType}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <CitySelector
                        form={formik}
                        property="origin"
                        label="Origin"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={1} xs={12}>
                      <FormikControl
                        control="select"
                        data={radius}
                        label="Radius"
                        name="originRadius"
                        type="text"
                        variant="outlined"
                        value={formik.values.originRadius}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <CitySelector
                        form={formik}
                        property="destination"
                        multiple
                        label="Destination Cities"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={1} xs={12}>
                      <FormikControl
                        control="select"
                        data={radius}
                        label="Radius"
                        name="destinationRadius"
                        type="text"
                        variant="outlined"
                        value={formik.values.destinationRadius}
                      />
                    </Grid>
                    <Grid item md={1} xs={12}>
                      <FormikControl
                        control="select"
                        data={numberOfStops}
                        label="Stops"
                        name="numberOfStops"
                        type="text"
                        variant="outlined"
                        value={formik.values.numberOfStops}
                      />
                    </Grid>
                    <Grid item md={1} xs={12}>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Refresh Rate"
                        name="refreshRate"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <CitySelector
                        form={formik}
                        property="excludedDestinations"
                        multiple
                        label="Excluded Cities"
                        variant="outlined"
                      />
                      {/* <FormikControl
                        control="autocompletetest"
                        data={excludedCities}
                        onInputChange={getExcludedCities}
                        label="Excluded Cities"
                        name="excludedDestinations"
                        variant="outlined"
                        multiple
                      /> */}
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <FormikControl
                        control="datepicker"
                        title="Load Start Date"
                        type="datetime-local"
                        label="Load Start Date"
                        name="loadStartDate"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <FormikControl
                        control="datepicker"
                        title="Load End Date"
                        type="datetime-local"
                        label="Load End Date"
                        name="loadEndDate"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={1} xs={12}>
                      <FormikControl
                        control="select"
                        data={loadingType}
                        label="Loading Type"
                        name="loadingType"
                        type="text"
                        variant="outlined"
                        value={formik.values.loadingType}
                      />
                    </Grid>
                    <Grid item md={1} xs={12}>
                      <FormikControl
                        control="input"
                        type="number"
                        label="Price P/M"
                        name="ppm"
                        variant="outlined"
                        inputAdornment="$"
                        adornmentPosition="start"
                      />
                    </Grid>
                    <Grid item md={1} xs={12}>
                      <FormikControl
                        control="input"
                        type="number"
                        label="Payout"
                        name="payout"
                        variant="outlined"
                        inputAdornment="$"
                        adornmentPosition="start"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item md={1} xs={12}>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Min Distance"
                        name="minDistance"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={1} xs={12}>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Max Distance"
                        name="maxDistance"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={1} xs={12}>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Min Pickup Time (Minutes)"
                        name="minPickupTime"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={1} xs={12}>
                      <FormikControl
                        control="input"
                        type="text"
                        label="PU Time Within (HRs)"
                        name="pickupTimeWithin"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={1} xs={12}>
                      <FormikControl
                        control="select"
                        data={timeZone}
                        label="Time Zone"
                        name="timeZone"
                        type="text"
                        variant="outlined"
                        value={formik.values.timeZone}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormikControl
                        control="select"
                        variant="outlined"
                        value={formik.values.driver1}
                        data={driverList}
                        label="Driver One"
                        name="driver1"
                        type="text"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormikControl
                        control="select"
                        variant="outlined"
                        value={formik.values.driver2}
                        data={driverList}
                        label="Driver Two"
                        name="driver2"
                        type="text"
                      />
                    </Grid>
                    <Grid item md={1} xs={12}>
                      <FormikControl
                        control="select"
                        data={orderType}
                        label="Order Type"
                        name="orderActionType"
                        type="text"
                        variant="outlined"
                        value={formik.values.orderType}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FormikControl
                        control="autocomplete"
                        data={usStateList}
                        label="Excluded States"
                        name="excludedStates"
                        variant="outlined"
                        onInputChange={() => {}}
                        form={formik}
                        values={formik.values.excludedStates}
                        multiple
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button onClick={onDialogClose} color="primary">
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          Save
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}

AddEditItem.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  isDialogOpen: PropTypes.bool,
  editItem: PropTypes.object,
  driverList: PropTypes.array
}

export default AddEditItem
