import axios from "axios"

export const fetchExpenses = async () => {
  const result = await axios.get("/api/Expense")
  return result.data
}

export const saveExpense = async data => {
  const result = await axios.post("/api/Expense/AddUpdateExpense", data)
  return result.data
}

export const deleteExpense = async data => {
  const result = await axios.delete(`/api/Expense?id=${data.companyExpenseId}`)
  return result.data
}

export const fetchLookupData = async () => {
  const fetchExpenseTypeList = await axios.get("/api/Lookup/GetExpenseTypeList")
  const fetchFrequencyTypeList = await axios.get(
    "/api/Lookup/GetFrequencyTypeList"
  )
  const fetchCompanyDrivers = await axios.get("/api/Lookup/GetCompanyDrivers")
  const response = await Promise.all([
    fetchExpenseTypeList,
    fetchFrequencyTypeList,
    fetchCompanyDrivers
  ])

  return {
    expenseTypeList: response[0].data,
    frequencyTypeList: response[1].data,
    driverList: response[2].data
  }
}
