import React from "react"
import PropTypes from "prop-types"
import {
  AlertErrorMessage,
  SkeletonPage,
  SkeletonDataTable,
  ErrorBoundary
} from ".."

/**
 * Wrapper component for children using Suspense -> lazy loading or react-query. Use this to show
 * transitions inline, rather than a global loading indicator.
 * Error boundary will automatically reset on a route change.
 */
const SuspenseWrapper = ({
  type,
  errorFallback = (
    <AlertErrorMessage
      hidden={false}
      title="It looks like this part of our site isn't working right now."
      content="Please try again later. Thanks for your patience."
    />
  ),
  suspenseFallback = type === "page" ? (
    <SkeletonPage />
  ) : (
    <SkeletonDataTable cells={5} />
  ),
  resetError,
  children
}) => {
  return (
    <ErrorBoundary
      resetError={resetError}
      fallback={
        typeof errorFallback === "string" ? (
          <AlertErrorMessage hidden={false} content={errorFallback} />
        ) : (
          <>{errorFallback}</>
        )
      }
    >
      <React.Suspense fallback={<>{suspenseFallback}</>}>
        {children}
      </React.Suspense>
    </ErrorBoundary>
  )
}

SuspenseWrapper.propTypes = {
  type: PropTypes.string,
  errorFallback: PropTypes.element,
  suspenseFallback: PropTypes.element,
  resetError: PropTypes.bool,
  children: PropTypes.element
}

export default SuspenseWrapper
