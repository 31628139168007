import axios from "axios"

export const fetchSearchSuggestions = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, param] = queryKey
  // eslint-disable-next-line no-unused-vars
  const result = await axios.get(
    `https://completion.amazon.com/api/2017/suggestions?session-id=147-9627297-7920701&customer-id=A2P8D2161H6T04&request-id=E0W5SZK4JE3FZ8G8B655&page-type=Gateway&lop=en_US&site-variant=desktop&client-info=amazon-search-ui&mid=ATVPDKIKX0DER&alias=aps&ks=84&prefix=${param}&event=onKeyPress&limit=11&b2b=0&fresh=0&fb=1&suggestion-type=KEYWORD&suggestion-type=WIDGET&_=1639782663200`
  )
  return result.data
}

export const fetchSearch = async param => {
  const result = await axios.get(
    `https://completion.amazon.com/api/2017/suggestions?session-id=147-9627297-7920701&customer-id=A2P8D2161H6T04&request-id=E0W5SZK4JE3FZ8G8B655&page-type=Gateway&lop=en_US&site-variant=desktop&client-info=amazon-search-ui&mid=ATVPDKIKX0DER&alias=aps&ks=84&prefix=${param}&event=onKeyPress&limit=11&b2b=0&fresh=0&fb=1&suggestion-type=KEYWORD&suggestion-type=WIDGET&_=1639782663200`
  )
  return result.data
}

export const fetchSearchResults = async data => {
  const result = await axios.post("/api/Expense/AddUpdateExpense", data)
  return result.data
}

export const fetchCities = async data => {
  const result = await axios.get(`/api/LoadOrder/GetCities?value=${data}`)
  return result.data
}

export const fetchDrivers = async () => {
  const result = await axios.get(`/api/LoadOrder/GetDrivers`)
  return result.data
}

export const startLoadSearch = async () => {
  const result = await axios.get(`/api/LoadOrder/GetLoads`)
  return result.data
}

export const assignDriver = async data => {
  const result = await axios.post(`/api/LoadOrder/AssignDriver`, data)
  return result.data
}

export const fetchLoadHistory = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, param] = queryKey
  const result = await axios.get(`/api/Accounting?id=${param}`)
  return result.data
}

export const fetchTest = async data => {
  const result = await axios.get(
    `https://relay.amazon.com/api/loadboard/filters/cities/search/${data}`
  )
  return result.data
}
