/* eslint-disable no-unused-vars */
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography
} from "@material-ui/core"
import React from "react"
import PropTypes, { func } from "prop-types"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: 5,
    paddingBottom: 0
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: "bold",
    font: theme.palette.text.primary
  },
  CardContent: {
    paddingBottom: 0
  },
  CardHeader: {
    backgroundColor: theme.palette.text.secondary,
    paddingTop: 4,
    paddingBottom: 2,
    marginBottom: 0
  }
}))

const LoadDataTableMobile = ({ data }) => {
  const classes = useStyles()
  const bull = <span className={classes.bullet}>•</span>

  const getDate = date => moment(date).format("MM/DD/YYYY HH:mm")

  return (
    <>
      {data.map(item => {
        return (
          <Card
            className={classes.root}
            variant="outlined"
            key={item.loadId + item.payout}
          >
            <CardHeader
              className={classes.CardHeader}
              title={
                <Grid container>
                  <Grid item xs={3}>
                    <Typography className={classes.title} gutterBottom>
                      Load:
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className={classes.title} gutterBottom>
                      {item.loadNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      className={classes.title}
                      gutterBottom
                      sx={{ textAlign: "end" }}
                    >
                      ${item.rate} / ${item.payout.toLocaleString()}
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
            <CardContent className={classes.CardContent}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Location:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {item.origin} {bull} {item.destination}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Date:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {getDate(item.startDate)} {bull} {getDate(item.endDate)}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Company:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {item.companyName}
                  </Typography>
                </Grid>
              </Grid>
              {item.comments && (
                <Grid container>
                  <Grid item xs={3}>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Comments:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {item.comments}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        )
      })}
    </>
  )
}

LoadDataTableMobile.propTypes = {
  data: PropTypes.array
}

export default LoadDataTableMobile
