/* eslint-disable no-unused-vars */
import React from "react"
import {
  Box,
  Button,
  Grid,
  MenuItem,
  SvgIcon,
  TextField
} from "@material-ui/core"
import { PageSection, FormikControl, Page } from "src/components"
import SuspenseWrapper from "src/components/layout/SuspenseWrapper"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import { useWeeks } from "src/modules/shared"
import { Search as SearchIcon } from "react-feather"
import { useEnhancedMutate } from "src/hooks"
import UploadDocument from "src/modules/shared/UploadDocument"
import { useQueryClient } from "react-query"
import { quarterList, yearList } from "src/helpers/GlobalConstants"
import { importFuel } from "./api/api"
import { useFetchFuels } from "./api/hooks"
import FuelDataTable from "./components/FuelDataTable"

const FuelImportPage = () => {
  const queryClient = useQueryClient()

  const [weekList, setWeekList] = React.useState([])
  const [fuelList, setFuelList] = React.useState([])

  const [selectedYear, setSelectedYear] = React.useState(
    new Date().getFullYear()
  )

  const initialFilterValues = {
    weekId: "",
    quarter: "",
    year: selectedYear
  }

  const [filterValues, setFilterValues] = React.useState(initialFilterValues)
  const fuelResult = useFetchFuels(filterValues)
  const weeksDataResult = useWeeks(selectedYear)

  const onSubmit = async values => {
    setFilterValues(values)
  }

  const onRefresh = () => {
    queryClient.invalidateQueries(["fetchFuel", filterValues])
  }

  React.useEffect(() => {
    if (weeksDataResult.isFetched) {
      setWeekList(weeksDataResult.data)
    }
    if (fuelResult.isFetched) {
      setFuelList(fuelResult.data)
    }
  }, [weeksDataResult, fuelResult])

  const [uploadDocument] = useEnhancedMutate(importFuel, {})

  const handleOnUpload = async form => {
    await uploadDocument(form)
  }

  const validationSchema = Yup.object().shape({
    // origin: Yup.string().required("Origin city is required")
  })

  return (
    <>
      <Page title="Fuel Import Page">
        <PageSection>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <SuspenseWrapper>
                <UploadDocument onUpload={handleOnUpload} />
              </SuspenseWrapper>
            </Grid>
          </Grid>
        </PageSection>
        <PageSection>
          <Box sx={{ pt: 3 }}>
            <SuspenseWrapper>
              <Formik
                initialValues={initialFilterValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnMount
                enableReinitialize
              >
                {formik => {
                  return (
                    <Form>
                      <Grid container spacing={1}>
                        <Grid item md={1} xs={12}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            fullWidth
                            margin="normal"
                            label="Work Year"
                            onChange={e => setSelectedYear(e.target.value)}
                            value={selectedYear}
                            variant="standard"
                          >
                            {yearList.map(option => (
                              <MenuItem key={option} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <FormikControl
                            control="select"
                            data={weekList}
                            label="Work Weeks"
                            name="weekId"
                            type="text"
                            variant="standard"
                            values={formik.values.weekId}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <FormikControl
                            control="select"
                            data={quarterList}
                            label="Quarter"
                            name="quarter"
                            type="text"
                            variant="standard"
                            values={formik.values.quarter}
                          />
                        </Grid>
                        <Grid item md={1} xs={12}>
                          <Box pt={3} justifyContent="flex-end">
                            <Button
                              fullWidth
                              type="submit"
                              color="primary"
                              variant="outlined"
                              startIcon={
                                <SvgIcon fontSize="small" color="action">
                                  <SearchIcon />
                                </SvgIcon>
                              }
                              disabled={!formik.isValid || formik.isSubmitting}
                            >
                              Search
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item md={1} xs={12}>
                          <Box pt={3} justifyContent="flex-end">
                            <Button
                              fullWidth
                              type="button"
                              onClick={() => onRefresh()}
                              color="warning"
                              variant="outlined"
                            >
                              Refresh
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Form>
                  )
                }}
              </Formik>
            </SuspenseWrapper>
          </Box>
        </PageSection>
        {fuelList && (
          <PageSection name="Fuel">
            <FuelDataTable data={fuelList} />
          </PageSection>
        )}
      </Page>
    </>
  )
}

FuelImportPage.propTypes = {}

export default FuelImportPage
