import React from "react"
import PropTypes from "prop-types"
import {
  TextBox,
  TextArea,
  PhoneInput,
  Select,
  MultiSelect,
  DatePicker,
  RadioButton,
  AutoComplete
} from ".."
import AutoCompleteLoadOrder from "./AutoCompleteLoadOrder"
import AutoCompleteTest from "./AutoCompleteTest"

const FormikControl = props => {
  const { control, ...rest } = props

  switch (control) {
    case "input":
      return <TextBox {...rest} />
    case "phone":
      return <PhoneInput {...rest} />
    case "textarea":
      return <TextArea {...rest} />
    case "select":
      return <Select {...rest} />
    case "multiselect":
      return <MultiSelect {...rest} />
    case "radio":
      return <RadioButton {...rest} />
    case "checkbox":
      return null
    case "datepicker":
      return <DatePicker {...rest} />
    case "autocomplete":
      return <AutoComplete {...rest} />
    case "autocompleteloadorder":
      return <AutoCompleteLoadOrder {...rest} />
    case "autocompletetest":
      return <AutoCompleteTest {...rest} />

    default:
      return null
  }
}

FormikControl.propTypes = {
  control: PropTypes.string
}

export default FormikControl
