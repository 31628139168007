import { useQuery } from "react-query"
import { fetchWeeks, fetchServiceUrl } from "./api"

// eslint-disable-next-line import/prefer-default-export
export const useWeeks = params => {
  return useQuery(["fetchWeeks", params], fetchWeeks)
}

export const useServiceUrl = () => {
  return useQuery(["fetchServiceUrl"], fetchServiceUrl)
}
