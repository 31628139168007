import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { lighten } from "@material-ui/core/styles"
import { makeStyles } from "@material-ui/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
// import FormControlLabel from "@material-ui/core/FormControlLabel"
// import Switch from "@material-ui/core/Switch"
import FilterListIcon from "@material-ui/icons/FilterList"

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headers } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }
  return (
    <TableHead>
      <TableRow>
        {headers.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  headers: PropTypes.array
}

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 100%"
  }
}))

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles()
  const { numSelected } = props

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <Tooltip title="Filter list">
        <IconButton aria-label="filter list">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}))

const EnhancedTable = ({
  headers,
  data,
  onSort,
  children,
  dense,
  sortBy,
  sortOrder
}) => {
  const classes = useStyles()
  const [order, setOrder] = React.useState(sortOrder || "asc")
  const [orderBy, setOrderBy] = React.useState(sortBy || "id")
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  // const [isDense, setIsDense] = React.useState(dense)
  const [rowsPerPage, setRowsPerPage] = React.useState(100)

  function descendingComparator(a, b, pOrderBy) {
    if (b[pOrderBy] < a[pOrderBy]) {
      return -1
    }
    if (b[pOrderBy] > a[pOrderBy]) {
      return 1
    }
    return 0
  }

  function getComparator(pOrder, pOrderBy) {
    return pOrder === "desc"
      ? (a, b) => descendingComparator(a, b, pOrderBy)
      : (a, b) => -descendingComparator(a, b, pOrderBy)
  }

  function stableSort() {
    const stabilizedThis = data.map((el, index) => [el, index])
    const comparator = getComparator(order, orderBy)
    stabilizedThis.sort((a, b) => {
      const pOrder = comparator(a[0], b[0])
      if (pOrder !== 0) return pOrder
      return a[1] - b[1]
    })

    onSort(stabilizedThis.map(el => el[0]))
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
    stableSort()
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = data.map(n => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // const handleChangeDense = event => {
  //   setIsDense(event.target.checked)
  // }

  return (
    <div className={classes.root}>
      {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headers={headers}
          />
          <TableBody>
            {children &&
              children.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 1000]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* {!dense && (
        <FormControlLabel
          control={<Switch checked={isDense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      )} */}
    </div>
  )
}

EnhancedTable.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array,
  onSort: PropTypes.func,
  children: PropTypes.array,
  dense: PropTypes.oneOfType([PropTypes.bool, PropTypes.any]),
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string
}

export default EnhancedTable
