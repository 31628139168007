import React, { useState, useEffect } from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Box, Card, TableCell, TableRow, Typography } from "@material-ui/core"
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone"
import { useEnhancedMutate } from "src/hooks"
import { ConfirmDialog, EnhancedTable, IconButton } from "src/components"
import PropTypes from "prop-types"
import { DeleteForeverTwoTone } from "@material-ui/icons"
import { ProtectedElement } from "src/components/auth"
import moment from "moment"
import AddEditItem from "./AddEditItem"
import { useLookupData, useExpenses } from "../api/hooks"
import { saveExpense, deleteExpense } from "../api/api"

const ExpenseDataTable = ({ addNewItem, onAddItemClose, ...rest }) => {
  const [lookupData, setLookupData] = useState(null)
  const [editItem, setEditItem] = useState(null)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState(null)

  const dataQuery = useExpenses()
  const lookupQuery = useLookupData()

  // Used to check if useeffect trigerred by data update
  const [dataUpdatedOn, setDataUpdatedOn] = useState("")
  const [sortedData, setSortedData] = useState(null)

  const handleOnSort = inData => {
    setSortedData(inData)
  }
  useEffect(() => {
    if (dataQuery.isFetched && dataUpdatedOn < dataQuery.dataUpdatedAt) {
      setDataUpdatedOn(dataQuery.dataUpdatedAt)
      setSortedData(dataQuery.data)
    }
    if (lookupQuery.isFetched) {
      setLookupData(lookupQuery.data)
    }
  }, [dataQuery, lookupQuery])

  const headers = [
    {
      id: "expenseTypeName",
      numeric: false,
      disablePadding: false,
      label: "Expense Type"
    },
    {
      id: "expenseName",
      numeric: false,
      disablePadding: false,
      label: "Expense Details"
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: false,
      label: "Amount"
    },
    {
      id: "frequencyTypeName",
      numeric: false,
      disablePadding: false,
      label: "Frequency"
    },
    {
      id: "expenseDate",
      numeric: false,
      disablePadding: false,
      label: "Date"
    },
    {
      id: "expenseStartDate",
      numeric: false,
      disablePadding: false,
      label: "Start Date"
    },
    {
      id: "expenseEndDate",
      numeric: false,
      disablePadding: false,
      label: "End Date"
    },
    {
      id: "expenseUserName",
      numeric: false,
      disablePadding: false,
      label: "Receiver"
    },
    {
      id: "comments",
      numeric: false,
      disablePadding: false,
      label: "Comments"
    },
    {
      id: "action",
      disablePadding: false,
      label: "Action"
    }
  ]

  const handleOnCloseDialog = () => {
    setEditItem(null)
    setIsEditDialogOpen(false)
    setConfirmDialog(null)
    onAddItemClose()
  }

  const [saveItem] = useEnhancedMutate(saveExpense, {
    invalidateQueries: [
      {
        queryKey: ["fetchExpenses"]
      }
    ],
    customSuccessMsg: "Expense updated successfully",
    onSuccess: () => {
      handleOnCloseDialog()
    }
  })

  const [deleteItem] = useEnhancedMutate(deleteExpense, {
    invalidateQueries: [
      {
        queryKey: ["fetchExpenses"]
      }
    ],
    customSuccessMsg: "Expense deleted successfully",
    onSuccess: () => {
      handleOnCloseDialog()
    }
  })

  const handleAddItem = () => {
    setEditItem({})
    setIsEditDialogOpen(true)
  }

  useEffect(() => {
    if (addNewItem) {
      handleAddItem()
    }
  }, [addNewItem])

  const handleEditItem = item => {
    setEditItem(item)
    setIsEditDialogOpen(true)
  }

  const handleOnConfirmDelete = async item => {
    await deleteItem(item)
  }

  const handleDeleteItem = item => {
    const dialog = {
      text: `Are you sure you want to delete ${item.expenseName}`,
      onConfirm: () => handleOnConfirmDelete(item),
      onCancel: () => handleOnCloseDialog(),
      isDialogOpen: true
    }
    setConfirmDialog(dialog)
  }

  const handleOnSave = item => {
    saveItem(item)
    setIsEditDialogOpen(false)
  }

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            {sortedData && (
              <EnhancedTable
                data={sortedData}
                headers={headers}
                onSort={handleOnSort}
              >
                {sortedData.map(item => {
                  return (
                    <TableRow hover key={item.companyExpenseId}>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex"
                          }}
                        >
                          <Typography color="textPrimary" variant="body1">
                            {item.expenseTypeName}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{item.expenseName}</TableCell>
                      <TableCell>{item.amount}</TableCell>
                      <TableCell>{item.frequencyTypeName}</TableCell>
                      <TableCell>
                        {item.expenseDate &&
                          moment(item.expenseDate).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell>
                        {item.expenseStartDate &&
                          moment(item.expenseStartDate).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell>
                        {item.expenseEndDate &&
                          moment(item.expenseEndDate).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell>{item.expenseUserName}</TableCell>
                      <TableCell>{item.comments}</TableCell>
                      <TableCell align="center">
                        <ProtectedElement
                          name="ExpenseAdminPage"
                          accessLevel="DenyDelete"
                        >
                          <IconButton
                            aria-label="Edit"
                            onClick={() => handleEditItem(item)}
                          >
                            <EditTwoToneIcon />
                          </IconButton>
                        </ProtectedElement>
                        <ProtectedElement
                          name="ExpenseAdminPage"
                          accessLevel="Write"
                        >
                          <IconButton
                            aria-label="Edit"
                            onClick={() => handleDeleteItem(item)}
                          >
                            <DeleteForeverTwoTone />
                          </IconButton>
                        </ProtectedElement>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </EnhancedTable>
            )}
          </Box>
        </PerfectScrollbar>
      </Card>
      {lookupData && (
        <AddEditItem
          data={lookupData}
          onSave={handleOnSave}
          onClose={handleOnCloseDialog}
          editItem={editItem}
          isDialogOpen={isEditDialogOpen}
        />
      )}
      <ConfirmDialog props={confirmDialog} />
    </>
  )
}

ExpenseDataTable.propTypes = {
  addNewItem: PropTypes.bool,
  onAddItemClose: PropTypes.func
}

export default ExpenseDataTable
