import { useQuery } from "react-query"
import { fetchBrokers, fetchLookupData } from "./api"

export const useBrokers = () => {
  return useQuery("fetchBrokers", fetchBrokers)
}

export const useLookupData = () => {
  return useQuery("fetchLookupData", fetchLookupData)
}
