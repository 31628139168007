import axios from "axios"

export const fetchCompanyCookies = async () => {
  const result = await axios.get("/api/CookieAdmin")
  return result.data
}

export const saveCookie = async data => {
  const result = await axios.post("/api/CookieAdmin", data)
  return result.data
}

export const deleteCookie = async data => {
  const result = await axios.delete(
    `/api/CookieAdmin?Id=${data.companyCookieId}`
  )
  return result.data
}
