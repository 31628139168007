import React, { useState, useEffect } from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Box, Button, TableCell, TableRow, Typography } from "@material-ui/core"
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone"
import { DeleteForeverTwoTone, PrintTwoTone } from "@material-ui/icons"
import { useEnhancedMutate, useScreenSize } from "src/hooks"
import { makeStyles } from "@material-ui/styles"
import {
  ConfirmDialog,
  EnhancedTable,
  IconButton,
  PageSection,
  AddButton
} from "src/components"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import { ProtectedElement } from "src/components/auth"
import moment from "moment"
import PropTypes from "prop-types"
import LoadDataTableMobile from "src/modules/shared/LoadDataTableMobile"
import { useNavigate } from "react-router"
import AddEditItem from "./AddEditItem"
import { useFilteredLoads, useWeeks } from "../api/hooks"
import { saveLoad, deleteLoad } from "../api/api"

const LoadDataTable = ({ lookupData, searchFilters }) => {
  const useStyles = makeStyles(theme => ({
    emptyRowStyle: {
      "& td": {
        color: "red"
      }
    },
    rowStyle: {
      color: theme.palette.primary
    }
  }))
  const classes = useStyles()

  const [isMobile] = useScreenSize()
  const [editItem, setEditItem] = useState(null)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState(null)

  // const dataQuery = useLoads()
  const dataQuery = useFilteredLoads(searchFilters)

  // Used to check if useeffect trigerred by data update
  const [dataUpdatedOn, setDataUpdatedOn] = useState("")
  const [sortedData, setSortedData] = useState(null)

  const [weekList, setWeekList] = React.useState([])
  const [selectedYear, setSelectedYear] = React.useState(
    new Date().getFullYear()
  )
  const weeksDataResult = useWeeks(selectedYear)

  const handleOnSort = inData => {
    setSortedData(inData)
  }
  useEffect(() => {
    if (dataQuery.isFetched && dataUpdatedOn < dataQuery.dataUpdatedAt) {
      setDataUpdatedOn(dataQuery.dataUpdatedAt)
      setSortedData(dataQuery.data)
    }
    if (weeksDataResult.isFetched) {
      setWeekList(weeksDataResult.data)
    }
  }, [dataQuery, searchFilters, weeksDataResult])

  const headers = [
    {
      id: "loadNumber",
      numeric: false,
      disablePadding: false,
      label: "Load"
    },
    {
      id: "brokerName",
      numeric: false,
      disablePadding: false,
      label: "Broker"
    },
    {
      id: "loadStartDate",
      numeric: false,
      disablePadding: false,
      label: "Start Date"
    },
    {
      id: "loadEndDate",
      numeric: false,
      disablePadding: false,
      label: "End Date"
    },
    {
      id: "originCity",
      numeric: false,
      disablePadding: false,
      label: "Origin"
    },
    {
      id: "destinationCity",
      numeric: false,
      disablePadding: false,
      label: "Destination"
    },
    {
      id: "tripLength",
      numeric: false,
      disablePadding: false,
      label: "Miles"
    },
    {
      id: "numberOfStops",
      numeric: false,
      disablePadding: false,
      label: "Stops"
    },
    {
      id: "pricePerMile",
      numeric: false,
      disablePadding: false,
      label: "PPM"
    },
    { id: "payout", numeric: false, disablePadding: false, label: "Payout" },
    {
      id: "loadStatusName",
      numeric: false,
      disablePadding: false,
      label: "Status"
    },
    {
      id: "loadDriverNames",
      numeric: false,
      disablePadding: false,
      label: "Driver(s)"
    },
    {
      id: "comments",
      numeric: false,
      disablePadding: false,
      label: "Comment"
    },
    {
      id: "action",
      disablePadding: false,
      label: "Action"
    }
  ]

  const handleOnCloseDialog = () => {
    setEditItem(null)
    setIsEditDialogOpen(false)
    setConfirmDialog(null)
  }

  const [saveLoadData] = useEnhancedMutate(saveLoad, {
    invalidateQueries: [
      {
        queryKey: ["fetchFilteredLoads", searchFilters]
      }
    ],
    customSuccessMsg: "Load updated successfully",
    onSuccess: () => {
      handleOnCloseDialog()
    }
  })

  const [deleteItem] = useEnhancedMutate(deleteLoad, {
    invalidateQueries: [
      {
        queryKey: ["fetchFilteredLoads", searchFilters]
      }
    ],
    customSuccessMsg: "Load deleted successfully",
    onSuccess: () => {
      handleOnCloseDialog()
    }
  })

  const handleAddItem = () => {
    setEditItem({})
    setIsEditDialogOpen(true)
  }

  const handleEditItem = item => {
    setEditItem(item)
    setIsEditDialogOpen(true)
  }

  const handleOnConfirmDelete = async item => {
    await deleteItem(item)
  }

  const handleDeleteItem = item => {
    const dialog = {
      text: `Are you sure you want to delete ${item.loadNumber}`,
      onConfirm: () => handleOnConfirmDelete(item),
      onCancel: () => handleOnCloseDialog(),
      isDialogOpen: true
    }
    setConfirmDialog(dialog)
  }

  const handleOnSave = item => {
    saveLoadData(item)
    setIsEditDialogOpen(false)
  }

  const downloadTxt = (fileName, data) => {
    const element = document.createElement("a")
    element.setAttribute(
      "href",
      `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`
    )
    element.setAttribute("download", fileName)

    element.style.display = "none"
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }

  const weeklyTotal = () => {
    let output = 0
    if (sortedData) {
      sortedData.map(x => {
        output += x.payout
        return output
      })
    }
    return output
  }

  const navigate = useNavigate()
  const handleOnPrintBtnClick = () => {
    navigate("/app/accounting/weekly-printview", {
      state: { data: sortedData }
    })
  }

  const handleOnDownloadBtnClick = () => {
    const data = sortedData.reverse()
    let output = "Load|Date|Driver|101|102|103|104|105|106|1001|Other \n"
    for (let i = 0; i < data.length; i++) {
      output += `\n${data[i].loadNumber}|${data[i].loadStartDate}|${data[
        i
      ].loadDriverNames.join()}|`
      if (data[i].loadDriverNames.join().includes("Mukhammadjon Sadikov")) {
        output += data[i].payout
      } else if (
        data[i].loadDriverNames.join().includes("Sardorbek Shakirov")
      ) {
        output += `|${data[i].payout}`
      } else if (data[i].loadDriverNames.join().includes("Odiljon Israilov")) {
        output += `||${data[i].payout}`
      } else if (
        data[i].loadDriverNames.join().includes("Yuldoshali Nurmatov")
      ) {
        output += `|||${data[i].payout}`
      } else if (
        data[i].loadDriverNames.join().includes("Mukhammadsodik Ergashev")
      ) {
        output += `||||${data[i].payout}`
      } else if (data[i].loadDriverNames.join().includes("Azzam Rahmanov")) {
        output += `|||||${data[i].payout}`
      } else if (data[i].loadDriverNames.join().includes("Asrorbek Turgunov")) {
        output += `||||||${data[i].payout}`
      } else output += `|||||||${data[i].payout}`
      output += `||||||||${data[i].comments ?? ""}`
    }

    const fileName = `${data[0].companyName}_${
      weekList.filter(x => x.value === searchFilters.weekId)[0].label
    }`
    downloadTxt(fileName, output)
  }

  return (
    <>
      {!isMobile && (
        <>
          <PageSection>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex">
                <AddButton
                  color="primary"
                  variant="contained"
                  onClick={() => handleAddItem()}
                >
                  Add Load
                </AddButton>
              </Box>
              <Box display="flex">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleOnPrintBtnClick()}
                  startIcon={<PrintTwoTone />}
                  sx={{ mr: 1 }}
                >
                  Print
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleOnDownloadBtnClick()}
                  startIcon={<CloudUploadIcon />}
                >
                  Export
                </Button>
              </Box>
            </Box>
          </PageSection>
          <PageSection>
            <Box
              display="flex"
              justifyContent="flex-end"
              sx={{ typography: "subtitle2" }}
            >
              Total: ${weeklyTotal().toFixed(2)}
            </Box>
          </PageSection>
          <PageSection>
            <PerfectScrollbar>
              <Box sx={{ minWidth: 1050 }}>
                {sortedData && (
                  <EnhancedTable
                    data={sortedData}
                    headers={headers}
                    onSort={handleOnSort}
                    dense
                  >
                    {sortedData.map(item => {
                      return (
                        <TableRow
                          hover
                          key={item.loadId}
                          className={
                            item.payout === 0 || item.loadDrivers.length === 0
                              ? classes.emptyRowStyle
                              : classes.rowStyle
                          }
                        >
                          <TableCell>{item.loadNumber}</TableCell>
                          <TableCell>{item.brokerName}</TableCell>
                          <TableCell>
                            {moment(item.loadStartDate).format(
                              "MM/DD/YYYY HH:mm"
                            )}
                          </TableCell>
                          <TableCell>
                            {moment(item.loadEndDate).format(
                              "MM/DD/YYYY HH:mm"
                            )}
                          </TableCell>
                          <TableCell>
                            {item.originCity} {item.originStateName}
                          </TableCell>
                          <TableCell>
                            {item.destinationCity} {item.destinationStateName}
                          </TableCell>
                          <TableCell>{item.tripLength}</TableCell>
                          <TableCell>{item.numberOfStops}</TableCell>
                          <TableCell>${item.pricePerMile}</TableCell>
                          <TableCell>${item.payout.toLocaleString()}</TableCell>
                          <TableCell>{item.loadStatusName}</TableCell>
                          <TableCell>
                            {item.loadDriverNames.map(driver => (
                              <Typography key={driver}>{driver}</Typography>
                            ))}
                          </TableCell>
                          <TableCell>{item.comments}</TableCell>
                          <TableCell align="center">
                            <ProtectedElement
                              name="LoadBoardPage"
                              accessLevel="DenyDelete"
                            >
                              <IconButton
                                aria-label="Edit"
                                onClick={() => handleEditItem(item)}
                              >
                                <EditTwoToneIcon />
                              </IconButton>
                            </ProtectedElement>
                            <ProtectedElement
                              name="LoadBoardPage"
                              accessLevel="Write"
                            >
                              <IconButton
                                aria-label="Edit"
                                onClick={() => handleDeleteItem(item)}
                              >
                                <DeleteForeverTwoTone />
                              </IconButton>
                            </ProtectedElement>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </EnhancedTable>
                )}
              </Box>
            </PerfectScrollbar>
          </PageSection>
        </>
      )}
      {isMobile && sortedData && (
        <LoadDataTableMobile
          data={sortedData}
          editItem={handleEditItem}
          deleteItem={handleDeleteItem}
        />
      )}
      <PageSection>
        <Box display="flex" justifyContent="space-between">
          <AddButton
            color="primary"
            variant="contained"
            onClick={() => handleAddItem()}
          >
            Add Load
          </AddButton>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleOnDownloadBtnClick()}
            startIcon={<CloudUploadIcon />}
          >
            Export
          </Button>
        </Box>
      </PageSection>
      {lookupData && (
        <AddEditItem
          data={lookupData}
          onSave={handleOnSave}
          onClose={handleOnCloseDialog}
          editItem={editItem}
          isDialogOpen={isEditDialogOpen}
          weekList={weekList}
          selectedYear={selectedYear}
          setYear={e => setSelectedYear(e)}
        />
      )}
      <ConfirmDialog props={confirmDialog} />
    </>
  )
}

LoadDataTable.propTypes = {
  lookupData: PropTypes.object,
  searchFilters: PropTypes.object
}

export default LoadDataTable
