/* eslint-disable no-unused-vars */
import axios from "axios"

export const fetchCompareInvoice = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars, no-debugger
  const [_, param] = queryKey
  const result = await axios.get(
    `/api/Accounting/CompareInvoice?weekId=${param}`
  )
  return result.data
}

export const saveImportedLoads = async data => {
  const result = await axios.post(`/api/Accounting/SaveImportedLoads`, data)
  return result.data
}

export const importInvoice = async file => {
  const result = await axios.post(`/api/Accounting/ImportInvoice/`, file)
  return result.data
}
