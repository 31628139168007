import React from "react"
import { Box, Grid } from "@material-ui/core"
import { Page, PageSection, PageSectionAccordion } from "src/components"
import SuspenseWrapper from "src/components/layout/SuspenseWrapper"
import { useParams } from "react-router"
import SearchFilters from "./components/SearchFilters"
import LoadDataTable from "./components/LoadDataTable"
import { useLookupData } from "./api/hooks"

const LoadBoardPage = () => {
  const { loadNumber } = useParams()
  const [lookupData, setLookupData] = React.useState(null)
  const lookupQuery = useLookupData()

  const defaultFilter = {
    loadNumber: "",
    weekId: 0,
    driverId: 0,
    startDate: "",
    endDate: ""
  }

  if (loadNumber) {
    defaultFilter.loadNumber = loadNumber
  }

  const [searchFilters, setSearchFilters] = React.useState(defaultFilter)

  React.useEffect(() => {
    if (lookupQuery.isFetched) {
      setLookupData(lookupQuery.data)
    }
  }, [lookupQuery])

  return (
    <>
      <Page title="Load Board">
        <PageSectionAccordion name="Filters" defaultExpanded>
          {lookupData && (
            <SearchFilters
              onSearch={filter => setSearchFilters(filter)}
              mb={3}
              lookupData={lookupData}
            />
          )}
        </PageSectionAccordion>
        <PageSection>
          <Grid>
            <Grid item xs={12}>
              <Box sx={{ pt: 3 }}>
                <SuspenseWrapper>
                  {lookupData && (
                    <LoadDataTable searchFilters={searchFilters} />
                  )}
                </SuspenseWrapper>
              </Box>
            </Grid>
          </Grid>
        </PageSection>
      </Page>
    </>
  )
}

LoadBoardPage.propTypes = {}

export default LoadBoardPage
