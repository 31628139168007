/* eslint-disable import/prefer-default-export */
import axios from "axios"

export const fetchUserStatuses = async () => {
  const result = await axios.get("/api/Lookup/GetUserStatuses")
  return result.data
}

export const fetchUserDetails = async param => {
  const result = await axios.post("/api/Auth/GetUserDetails", param)
  return result.data
}

export const updateUserCompany = async param => {
  const result = await axios.post("/api/Auth/UpdateUserCompany", param)
  return result.data
}

export const changeUsersCompany = async param => {
  const result = await axios.post("/api/Auth/ChangeUserCompany", param)
  return result.data
}

export const fetchCompanyList = async () => {
  const result = await axios.get("/api/Lookup/GetCompanyList")
  return result.data
}

export const signOutUser = async () => {
  const result = await axios.post("/api/Auth/LogOut")
  return result.data
}
