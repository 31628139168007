import { useQuery } from "react-query"
import {
  fetchSearchSuggestions,
  fetchSearchResults,
  fetchDrivers,
  fetchLoadHistory
} from "./api"

export const useSearchSuggestions = params => {
  return useQuery(["fetchSearchSuggestions", params], fetchSearchSuggestions)
}

export const useLoadHistory = params => {
  return useQuery(["fetchLoadHistory", params], fetchLoadHistory)
}

export const useSearchResults = () => {
  return useQuery("fetchSearchResults", fetchSearchResults)
}

export const useDrivers = () => {
  return useQuery("fetchDrivers", fetchDrivers)
}
