import React from "react"
import PropTypes from "prop-types"
import { Formik, Form } from "formik"
import {
  Box,
  Button,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  Divider
} from "@material-ui/core"
import { FormikControl } from "src/components"
import * as Yup from "yup"

const AddEditItem = ({ onSave, onClose, isDialogOpen, editItem }) => {
  const onSubmit = async values => {
    onSave(values)
  }

  const initialValues = {
    cookie: "",
    csrfToken: "",
    cookieSource: ""
  }

  const validationSchema = Yup.object().shape({
    cookie: Yup.string().required("Cookie is required"),
    csrfToken: Yup.string().required("CsrfToken is required"),
    cookieSource: Yup.string().required("Cookie Source is required")
  })

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose()
          }
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {editItem ? "Update cookie" : "Add cookie"}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={editItem || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
            enableReinitialize
          >
            {formik => {
              return (
                <Form>
                  <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                      <FormikControl
                        control="textarea"
                        title="Cookie"
                        type="text"
                        label="Cookie"
                        name="cookie"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FormikControl
                        control="textarea"
                        title="Csrf Token"
                        type="text"
                        label="Csrf Token"
                        name="csrfToken"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Cookie Source"
                        name="cookieSource"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Divider />
                      <Box display="flex" justifyContent="flex-end">
                        <Button onClick={onClose} color="primary">
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          Save
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}

AddEditItem.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  isDialogOpen: PropTypes.bool,
  editItem: PropTypes.object
}

export default AddEditItem
