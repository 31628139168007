import { useRef, useEffect } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useToast } from "."

/** function that extends react-query's useMutate
 * with built in calls to setting progress and error feedback */
const useEnhancedMutate = (
  mutationFn,
  { onSuccess, onError, invalidateQueries, customSuccessMsg }
) => {
  // hooks
  const toast = useToast()
  const queryClient = useQueryClient()

  // track if mounted
  const isMounted = useRef(true)
  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  // call useMutate
  const { mutate: mutationName, ...status } = useMutation(mutationFn, {
    // status provides query status also has variables object with passed parameters to mutationFn
    onMutate: () => {
      // setShowProgress(true)
    },
    onSuccess: data => {
      if (invalidateQueries) {
        // eslint-disable-next-line array-callback-return
        invalidateQueries.map(query => {
          queryClient.invalidateQueries(query.queryKey)
        })
      }
      if (customSuccessMsg) {
        // TODO: Use toast
        toast.onSuccess(customSuccessMsg)
      } else if (data.substr(0, 5) === "ERROR") {
        toast.onError(data)
      } else if (data.substr(0, 4) === "INFO") {
        toast.onInfo(data)
      } else toast.onSuccess(data)

      if (onSuccess) {
        onSuccess()
      }
    },
    onError: error => {
      toast.onError(error)
      if (onError) {
        onError()
      }
      // (error, rollback) => rollback()
    },
    onSettled: () => {
      // setShowProgress(false)
    }
  })

  return [mutationName, status]
}

export default useEnhancedMutate

// Example full mutate funciton

// eslint-disable-next-line max-len
// const { mutate: updateRfqLineItemQuotes, isIdle, isLoading, isError, isSuccess, data, error } = useMutation(
//   postRfqLineItemQuote,
//   {
//     // eslint-disable-next-line
//     onSuccess: (data, variables, context) => {
//       queryClient.invalidateQueries(["fetchRfqLineItemQuotes", { rfqId: variables.rfqId }])
//       handleOnCloseLineItemDialog()
//     },
//     // eslint-disable-next-line
//     onError: (error, rollback) => rollback()
//   }
// )
