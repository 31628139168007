import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from "@material-ui/core"
import { Search as SearchIcon } from "react-feather"
import PropTypes from "prop-types"

const SearchBar = ({ name, disabled, addNewItem, children, ...rest }) => {
  const placeHolder = `Search ${name}`

  return (
    <Box {...rest}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        {/* <Button disabled={disabled}>Import</Button>
      <Button sx={{ mx: 1 }} disabled={disabled}>
        Export
      </Button> */}
        {!disabled && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => addNewItem()}
          >
            Add {name}
          </Button>
        )}
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            {children}
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder={placeHolder}
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}

SearchBar.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  addNewItem: PropTypes.func,
  children: PropTypes.element
}

export default SearchBar
