/* eslint-disable no-unused-vars */
import axios from "axios"

export const fetchWeeks = async ({ queryKey }) => {
  const [_, param] = queryKey
  const result = await axios.get(`/api/Lookup/GetWorkWeekList?year=${param}`)
  return result.data
}

export const fetchFilteredLoads = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars, no-debugger
  const [_, param] = queryKey
  const result = await axios.post("/api/Load/GetLoadHistory", param)
  return result.data
}

export const fetchLookupData = async () => {
  const fetchStates = await axios.get("/api/Lookup/GetStates")
  const fetchLoadStatus = await axios.get("/api/Lookup/GetLoadStatusList")
  const fetchBrokers = await axios.get("/api/Lookup/GetBrokerList")
  const fetchCompanyDrivers = await axios.get("/api/Lookup/GetCompanyDrivers")

  const response = await Promise.all([
    fetchStates,
    fetchLoadStatus,
    fetchBrokers,
    fetchCompanyDrivers
  ])

  return {
    stateList: response[0].data,
    loadStatusList: response[1].data,
    brokerList: response[2].data,
    driverList: response[3].data
  }
}
