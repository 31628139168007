import { Helmet } from "react-helmet"
import { Box, Button, Container, Grid, Typography } from "@material-ui/core"
import { useAuth } from "../../../hooks"
import GoogleIcon from "../../../icons/Google"
import PageLogo from "../../layout/PageLogo"

const LoginPage = () => {
  const { login, errorMessage } = useAuth()

  const handleLoginWithGoogle = async e => {
    e.preventDefault()
    try {
      await login()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Helmet>
        <title>Login FMS Portal</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center"
        }}
      >
        <Typography color="textSecondary" variant="body1">
          {errorMessage}
        </Typography>
        <Container maxWidth="sm">
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Typography color="textSecondary" textAlign="center" variant="h1">
              Fleet Managemet System
            </Typography>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <PageLogo />
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                startIcon={<GoogleIcon />}
                onClick={handleLoginWithGoogle}
                size="large"
                variant="contained"
              >
                Login with Google
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default LoginPage
