import { useQuery } from "react-query"
import { fetchWeeks, fetchCompanyDrivers } from "./sharedApi"

export const useWeeks = params => {
  return useQuery(["fetchWeeks", params], fetchWeeks)
}

export const useCompanyDrivers = () => {
  return useQuery("fetchCompanyDrivers", fetchCompanyDrivers)
}
