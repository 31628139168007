import React from "react"
import { Page, PageSectionAccordion } from "src/components"
import SuspenseWrapper from "src/components/layout/SuspenseWrapper"
import { Grid, Box } from "@material-ui/core"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import LocalGasStationTwoToneIcon from "@material-ui/icons/LocalGasStationTwoTone"
import AltRouteTwoToneIcon from "@material-ui/icons/AltRouteTwoTone"
import MoneyOffTwoToneIcon from "@material-ui/icons/MoneyOffTwoTone"
import MonetizationOnTwoToneIcon from "@material-ui/icons/MonetizationOnTwoTone"
import {
  indigo,
  red,
  green,
  lime,
  yellow,
  orange,
  blueGrey,
  amber
} from "@material-ui/core/colors"
import { useAuth, useScreenSize } from "src/hooks"
import LoadDataTableMobile from "./components/LoadDataTableMobile"
import SearchFilters from "./components/SearchFilters"
import { useWeeks, useCompanyDrivers, useDriversGross } from "./api/hooks"
import LoadDataTable from "./components/LoadDataTable"
import DriverTotals from "./components/DriverTotals"
import ExpenseDataTable from "./components/ExpenseDataTable"

const DriverDashboardPage = () => {
  const [isMobile] = useScreenSize()
  const auth = useAuth()
  const { currentUser } = auth
  const driverData = useCompanyDrivers()
  const [weekList, setWeekList] = React.useState([])
  const [driverList, setDriverList] = React.useState([])
  const [selectedYear, setSelectedYear] = React.useState(
    new Date().getFullYear()
  )
  const weeksData = useWeeks(selectedYear)
  const [driversGrossIncome, setDriversGrossIncome] = React.useState(null)
  const [searchParams, setSearchParams] = React.useState({
    year: 0,
    weekId: 0,
    driverId: 0,
    startDate: "",
    endDate: ""
  })
  const driversGrossIncomeData = useDriversGross(searchParams)

  React.useEffect(() => {
    if (weeksData.isFetched) {
      setWeekList(weeksData.data)
    }
    if (driverData.isFetched) {
      setDriverList(driverData.data)
    }
    if (driversGrossIncomeData.isFetched) {
      setDriversGrossIncome(driversGrossIncomeData.data)
    }
  }, [weeksData, driverData, driversGrossIncomeData])

  const onSearchClick = async value => {
    value.year = selectedYear || 0
    setSearchParams(value)
    // await driversGrossIncomeData.refetch()
  }

  const canSeeCompanyGross = () => {
    if (currentUser.roleId === 1 || currentUser.roleId === 2) return true
    return false
  }

  const colors = [yellow[900], orange[800], blueGrey[400]]

  return (
    <>
      <Page title="Driver Dashboard">
        <PageSectionAccordion name="Filters" defaultExpanded>
          <SuspenseWrapper>
            <SearchFilters
              weekList={weekList}
              driverList={driverList}
              setYear={e => setSelectedYear(e)}
              selectedYear={selectedYear}
              onSearch={onSearchClick}
            />
          </SuspenseWrapper>
        </PageSectionAccordion>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            {driversGrossIncome &&
              canSeeCompanyGross() &&
              driversGrossIncome.companyGrossList.map((item, index) => (
                <Grid item lg={3} sm={6} xl={3} xs={12} key={item.companyId}>
                  <DriverTotals
                    sx={{ height: "100%" }}
                    data={item.gross}
                    header={`${item.companyName} Gross`}
                    icon={<AttachMoneyIcon />}
                    iconColor={colors[index]}
                  />
                </Grid>
              ))}
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <DriverTotals
                sx={{ height: "100%" }}
                data={driversGrossIncome ? driversGrossIncome.totalGross : 0}
                header="Total Gross"
                icon={<AttachMoneyIcon />}
                iconColor={indigo[600]}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <DriverTotals
                sx={{ height: "100%" }}
                data={driversGrossIncome ? driversGrossIncome.totalExpense : 0}
                header="Total Expense"
                icon={<MoneyOffTwoToneIcon />}
                iconColor={red[500]}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <DriverTotals
                sx={{ height: "100%" }}
                data={driversGrossIncome ? driversGrossIncome.totalFuel : 0}
                header="Total Fuel"
                icon={<LocalGasStationTwoToneIcon />}
                iconColor={green[400]}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <DriverTotals
                sx={{ height: "100%" }}
                data={
                  driversGrossIncome ? driversGrossIncome.administrationFee : 0
                }
                header="Administration Fee"
                icon={<AltRouteTwoToneIcon />}
                iconColor={lime[500]}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <DriverTotals
                sx={{ height: "100%" }}
                data={driversGrossIncome ? driversGrossIncome.netIncome : 0}
                header="NET"
                icon={<MonetizationOnTwoToneIcon />}
                iconColor={amber[400]}
              />
            </Grid>
          </Grid>
        </Box>
        {driversGrossIncome && !isMobile && (
          <PageSectionAccordion name="Executed Loads">
            <SuspenseWrapper>
              <LoadDataTable data={driversGrossIncome.driverLoadList} />
            </SuspenseWrapper>
          </PageSectionAccordion>
        )}
        {driversGrossIncome && isMobile && (
          <Box sx={{ marginTop: 3 }}>
            <LoadDataTableMobile data={driversGrossIncome.driverLoadList} />
          </Box>
        )}
        <PageSectionAccordion name="Expenses">
          <SuspenseWrapper>
            {driversGrossIncome && (
              <ExpenseDataTable data={driversGrossIncome.driverExpenseList} />
            )}
          </SuspenseWrapper>
        </PageSectionAccordion>
      </Page>
    </>
  )
}

DriverDashboardPage.propTypes = {}

export default DriverDashboardPage
