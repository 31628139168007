import React from "react"
import { Navigate } from "react-router"
import PropTypes from "prop-types"
import { useAuth } from "../hooks"

// eslint-disable-next-line no-unused-vars
const RegisterRoute = ({ children, name, ...rest }) => {
  const auth = useAuth()
  const { currentUser } = auth
  if (currentUser && !currentUser.companyId && !currentUser.roleName) {
    return <>{children}</>
  }

  return <Navigate to="/" />
}

RegisterRoute.propTypes = {
  children: PropTypes.object,
  name: PropTypes.string
}

export default RegisterRoute
