import React, { useState, useEffect } from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Box, TableCell, TableRow } from "@material-ui/core"
import { EnhancedTable } from "src/components"
import PropTypes from "prop-types"
import moment from "moment"
import { ProtectedElement } from "src/components/auth"
import { makeStyles } from "@material-ui/styles"
import { useAuth } from "src/hooks"

const useStyles = makeStyles(() => ({
  cellColor: {
    "&:hover": {
      color: "blue",
      cursor: "pointer",
      textDecoration: "underline"
    }
  }
}))

const LoadDataTable = ({ data }) => {
  const auth = useAuth()
  const { currentUser } = auth
  const [sortedData, setSortedData] = useState(null)

  const classes = useStyles()

  const handleOnSort = inData => {
    setSortedData(inData)
  }
  useEffect(() => {
    setSortedData(data)
  }, [data])

  const onLoadNumberClick = loadNumber => {
    const path = `${window.location.origin}/app/loadboard/${loadNumber}`
    window.open(path, "_blank")
  }

  const headers = [
    {
      id: "loadNumber",
      numeric: false,
      disablePadding: false,
      label: "Load"
    },
    {
      id: "companyName",
      numeric: false,
      disablePadding: false,
      label: "Company"
    },
    {
      id: "startDate",
      numeric: false,
      disablePadding: false,
      label: "Start Date"
    },
    {
      id: "endDate",
      numeric: false,
      disablePadding: false,
      label: "End Date"
    },
    {
      id: "origin",
      numeric: false,
      disablePadding: false,
      label: "Origin"
    },
    {
      id: "destination",
      numeric: false,
      disablePadding: false,
      label: "Destination"
    },
    {
      id: "rate",
      numeric: false,
      disablePadding: false,
      label: "PPM"
    },
    { id: "payout", numeric: false, disablePadding: false, label: "Payout" },
    { id: "comments", numeric: false, disablePadding: false, label: "Comments" }
  ]

  return (
    <>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {sortedData && (
            <EnhancedTable
              data={sortedData}
              headers={headers}
              onSort={handleOnSort}
              dense
            >
              {sortedData.map(item => {
                return (
                  <TableRow hover key={item.loadId}>
                    {currentUser.roleId < 3 && (
                      <ProtectedElement
                        name="DriverDashboardPage"
                        accessLevel="DenyDelete"
                      >
                        <TableCell
                          onClick={() => onLoadNumberClick(item.loadNumber)}
                          className={classes.cellColor}
                        >
                          {item.loadNumber}
                        </TableCell>
                      </ProtectedElement>
                    )}
                    {currentUser.roleId > 2 && (
                      <TableCell>{item.loadNumber}</TableCell>
                    )}
                    <TableCell>{item.companyName}</TableCell>
                    <TableCell>
                      {moment(item.startDate).format("MM/DD/YYYY HH:mm")}
                    </TableCell>
                    <TableCell>
                      {moment(item.endDate).format("MM/DD/YYYY HH:mm")}
                    </TableCell>
                    <TableCell>{item.origin}</TableCell>
                    <TableCell>{item.destination}</TableCell>
                    <TableCell>${item.rate.toLocaleString()}</TableCell>
                    <TableCell>${item.payout.toLocaleString()}</TableCell>
                    <TableCell>{item.comments}</TableCell>
                  </TableRow>
                )
              })}
            </EnhancedTable>
          )}
        </Box>
      </PerfectScrollbar>
    </>
  )
}

LoadDataTable.propTypes = {
  data: PropTypes.array
}

export default LoadDataTable
