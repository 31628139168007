import React from "react"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Typography
} from "@material-ui/core"
import { Helmet } from "react-helmet"
import { useAuth } from "../../../hooks"

const InReviewPage = () => {
  const auth = useAuth()
  const { currentUser } = auth

  return (
    <>
      <Helmet>
        <title>Application in review</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center"
        }}
      >
        <Container maxWidth="sm">
          <Card>
            <CardHeader
              subheader="Thank you for your interest in FMS"
              title="FMS Registration"
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <Typography>
                    Your application is currently under review by{" "}
                    {currentUser.company} admin. Please allow 2 days for
                    application to be reviewed.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  )
}

export default InReviewPage
