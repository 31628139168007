import React, { useEffect, useState } from "react"
import { signInWithPopup, signOut, GoogleAuthProvider } from "firebase/auth"
import { useNavigate } from "react-router"
import { PropTypes } from "prop-types"
import { auth } from "../firebase/firebase-config"
import {
  fetchUserDetails,
  updateUserCompany,
  changeUsersCompany,
  signOutUser
} from "./api/api"
import { useLocalStorage } from "../../hooks"

const AuthContext = React.createContext()

const AuthProvider = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState(undefined)
  const navigate = useNavigate()

  const [localUser, setCurrentUserLocally] = useLocalStorage("FMS_CUD", null)
  const [currentUser, setCurrentUser] = useState(localUser)

  // clears local storage on browser close
  // window.onbeforeunload = () => {
  //   localStorage.clear()
  // }

  const refreshLocalSession = () => {
    fetchUserDetails({ email: localUser.email }).then(data => {
      setCurrentUser(data)
    })
  }

  const provider = new GoogleAuthProvider()
  const login = async () => {
    signInWithPopup(auth, provider)
      .then(result => {
        setErrorMessage(undefined)
        const { email, displayName, phoneNumber, uid } = result.user
        fetchUserDetails({ email, displayName, phoneNumber, uid }).then(
          data => {
            setCurrentUser(data)
            return navigate("/")
          }
        )
      })
      .catch(error => {
        const errmessage = error.message
        setErrorMessage(errmessage)
      })
  }

  const updateUsersCompany = async params => {
    try {
      const user = await updateUserCompany(params)
      setCurrentUser(user)
      navigate("/")
    } catch (e) {
      setErrorMessage(e)
    }
  }

  const switchUsersCompany = async params => {
    try {
      const user = await changeUsersCompany(params)
      setCurrentUser(user)
      navigate("/")
    } catch (e) {
      setErrorMessage(e)
    }
  }

  const logOut = () => {
    signOut(auth)
      .then(() => {
        signOutUser()
        setCurrentUser(undefined)
        window.localStorage.clear()
        navigate("/")
      })
      .catch(error => setErrorMessage(error))
  }

  useEffect(() => {
    if (currentUser) {
      setCurrentUserLocally(currentUser)
    }
    // const unsubscribe = auth.onAuthStateChanged(user => {
    //   setCurrentUser(user)
    // })
    // return unsubscribe
  }, [currentUser])

  const value = {
    currentUser,
    login,
    logOut,
    updateUsersCompany,
    refreshLocalSession,
    switchUsersCompany,
    errorMessage
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.element
}

export { AuthProvider, AuthContext }
