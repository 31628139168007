import darkTheme from "./darkTheme"
import lightTheme from "./lightTheme"

const Themes = themeName => {
  switch (themeName) {
    case "light":
      return lightTheme
    case "dark":
      return darkTheme
    default:
      return lightTheme
  }
}

export default Themes
