import React from "react"
import {
  TextField,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Field } from "formik"
import PropTypes from "prop-types"

const MultiSelect = props => {
  const { name, form, values, ...rest } = props
  // TODO: When page changed quickly from navbar from current to another and back,
  // lookup data takes some time to load and if add or edit load pressed quickly,
  // data comes undefined
  // eslint-disable-next-line react/destructuring-assignment
  const data = props.data || []

  const variant = rest.variant ? rest.variant : "outlined"

  const useStyles = makeStyles(theme => {
    return {
      root: {
        backgroundColor: theme.palette.background.default,
        color: "white"
      }
    }
  })
  const classes = useStyles()

  let selectedList = values ?? []
  const isAllSelected = data.length > 0 && selectedList.length === data.length

  const handleChange = event => {
    const { value } = event.target
    if (value[value.length - 1] === "all") {
      form.setFieldValue(
        name,
        selectedList.length === data.length ? [] : data.map(x => x.value)
      )
      selectedList =
        selectedList.length === data.length ? [] : data.map(x => x.value)
      return
    }
    selectedList = value
    form.setFieldValue(name, value)
  }

  return (
    <>
      <Field name={name} id={name} {...rest}>
        {fieldProps => {
          const { field, meta } = fieldProps
          return (
            <>
              <TextField
                error={Boolean(meta.touched && meta.error)}
                fullWidth
                helperText={meta.error}
                {...rest}
                margin="normal"
                variant={variant}
                {...field}
                select
                SelectProps={{
                  multiple: true,
                  value: selectedList,
                  onChange: handleChange,
                  renderValue: selected => {
                    let label = ""
                    label += selected.map(item => {
                      if (item !== "all") {
                        return ` [${
                          data && data.filter(x => x.value === item)[0].label
                        }] `
                      }
                      return ""
                    })
                    return label
                  }
                }}
              >
                <MenuItem value="all">
                  <>
                    <Checkbox
                      checked={isAllSelected}
                      indeterminate={
                        selectedList.length > 0 &&
                        selectedList.length < data.length
                      }
                    />
                    <ListItemText primary="Select All" />
                  </>
                </MenuItem>
                {data &&
                  data.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      <>
                        <Checkbox
                          checked={selectedList.indexOf(item.value) > -1}
                        />
                        <ListItemText primary={item.label} />
                      </>
                    </MenuItem>
                  ))}
              </TextField>
              <div>
                {data &&
                  data.map(item => {
                    return (
                      selectedList.constructor === Array &&
                      selectedList.indexOf(item.value) > -1 && (
                        <Chip
                          className={classes.root}
                          key={item.value}
                          label={item.label}
                        />
                      )
                    )
                  })}
              </div>
            </>
          )
        }}
      </Field>
    </>
  )
}

MultiSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.array,
  form: PropTypes.object,
  values: PropTypes.array
}

export default MultiSelect
