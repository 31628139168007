import { useQuery } from "react-query"
import { fetchWeeks, fetchFilteredLoads, fetchLookupData } from "./api"

export const useWeeks = params => {
  return useQuery(["fetchWeeks", params], fetchWeeks)
}

export const useFilteredLoads = params => {
  return useQuery(["fetchFilteredLoads", params], fetchFilteredLoads)
}

export const useLookupData = () => {
  return useQuery("fetchLookupData", fetchLookupData)
}
