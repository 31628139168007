import React from "react"
import { Tooltip, IconButton } from "@material-ui/core"
import PropTypes from "prop-types"

const TooltipButton = ({ title, ariaLabel, icon, onClick }) => {
  return (
    <Tooltip title={title}>
      <IconButton aria-label={ariaLabel} onClick={onClick}>
        {icon}
      </IconButton>
    </Tooltip>
  )
}

TooltipButton.propTypes = {
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node
}

export default TooltipButton
