import React from "react"
import { Paper, Tab, Tabs } from "@material-ui/core"
import { Page, PageSection } from "src/components"
import { makeStyles } from "@material-ui/styles"
import SuspenseWrapper from "src/components/layout/SuspenseWrapper"
import { TabPanel, TabContext } from "@material-ui/lab"
import WorkWeeksDataTable from "./components/WorkWeeksDataTable"
import ServiceConfigurationPage from "./components/ServiceConfigurationPage"

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
})

const SystemAdminPage = () => {
  const classes = useStyles()
  const [value, setValue] = React.useState("1")

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Page title="System Management">
        <PageSection>
          <TabContext value={value}>
            <Paper className={classes.root}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Work Weeks" value="1" />
                <Tab label="Service" value="2" />
                <Tab label="Item Three" value="3" />
              </Tabs>
            </Paper>
            <TabPanel value="1">
              <SuspenseWrapper>
                <WorkWeeksDataTable />
              </SuspenseWrapper>
            </TabPanel>
            <TabPanel value="2">
              <SuspenseWrapper>
                <ServiceConfigurationPage />
              </SuspenseWrapper>
            </TabPanel>
            <TabPanel value="3" />
          </TabContext>
        </PageSection>
      </Page>
    </>
  )
}

SystemAdminPage.propTypes = {}

export default SystemAdminPage
