import React from "react"
import PropTypes from "prop-types"
import { Formik, Form } from "formik"
import {
  Box,
  Button,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle
} from "@material-ui/core"
import { FormikControl } from "src/components"
import * as Yup from "yup"

const AddEditUser = ({ onSave, onClose, data, isDialogOpen, editItem }) => {
  const { roleList, companyList } = data

  const onSubmit = async values => {
    onSave(values)
  }

  const YesNo = [
    { value: "True", label: "Yes" },
    { value: "False", label: "No" }
  ]

  const initialValues = {
    userId: "",
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    userRoles: undefined,
    userCompanies: undefined,
    serviceFee: 0,
    isActive: "True"
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
    userRoles: Yup.array().required("At least one role is required"),
    userCompanies: Yup.array().required("At least one company is required")
  })

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {editItem ? "Update user" : "Add Line Item"}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={editItem || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
            enableReinitialize
          >
            {formik => {
              return (
                <Form>
                  <Grid container spacing={1}>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="input"
                        title="First Name"
                        type="text"
                        label="First Name"
                        name="firstName"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="input"
                        title="Last Name"
                        type="text"
                        label="Last Name"
                        name="lastName"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="input"
                        title="Email"
                        type="text"
                        label="Email"
                        name="email"
                        variant="standard"
                        disabled
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="input"
                        title="Phone Number"
                        type="text"
                        label="Phone Number"
                        name="phoneNumber"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="multiselect"
                        data={roleList}
                        label="Roles"
                        name="userRoles"
                        type="text"
                        form={formik}
                        values={formik.values.userRoles}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="multiselect"
                        data={companyList}
                        label="Companies"
                        name="userCompanies"
                        type="text"
                        form={formik}
                        values={formik.values.userCompanies}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="input"
                        title="Service Fee"
                        type="text"
                        label="Service Fee"
                        name="serviceFee"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="input"
                        title="Unit Number"
                        type="text"
                        label="Unit Number"
                        name="unitNumber"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="radio"
                        label="Active"
                        name="isActive"
                        options={YesNo}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button onClick={onClose} color="primary">
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          Save
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}

AddEditUser.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  data: PropTypes.object,
  isDialogOpen: PropTypes.bool,
  editItem: PropTypes.object
}

export default AddEditUser
