import { useQuery } from "react-query"
import {
  fetchSearchSuggestions,
  fetchActiveSearches,
  fetchOrderHistory,
  fetchCompanyDrivers
} from "./api"

export const useSearchSuggestions = params => {
  return useQuery(["fetchSearchSuggestions", params], fetchSearchSuggestions)
}

export const useActiveOrders = () => {
  return useQuery("fetchActiveSearches", fetchActiveSearches, {
    // Refetch the data every second
    refetchInterval: 1000
  })
}

export const useOrderHistory = () => {
  return useQuery("fetchOrderHistory", fetchOrderHistory, {
    // Refetch the data every second
    refetchInterval: 5000
  })
}

export const useCompanyDrivers = () => {
  return useQuery("fetchCompanyDrivers", fetchCompanyDrivers)
}
