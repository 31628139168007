import React from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Box, TableCell, TableRow } from "@material-ui/core"
import { EnhancedTable } from "src/components"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"

const LoadDataTable = ({ data }) => {
  const useStyles = makeStyles(() => ({
    cellColor: {
      "&:hover": {
        color: "blue",
        cursor: "pointer",
        textDecoration: "underline"
      }
    }
  }))
  const classes = useStyles()

  const headers = [
    {
      id: "loadNumber",
      numeric: false,
      disablePadding: false,
      label: "Load"
    },
    {
      id: "invoicePayout",
      numeric: false,
      disablePadding: false,
      label: "Invoice Payout"
    },
    {
      id: "boardPayout",
      numeric: false,
      disablePadding: false,
      label: "Board Payout"
    },
    {
      id: "difference",
      numeric: false,
      disablePadding: false,
      label: "Difference"
    }
  ]

  const onLoadNumberClick = loadNumber => {
    const path = `${window.location.origin}/app/loadboard/${loadNumber}`
    window.open(path, "_blank")
  }

  return (
    <>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {data && (
            <EnhancedTable data={data} headers={headers} dense>
              {data.map(item => {
                return (
                  <TableRow hover key={item.createdOn}>
                    <TableCell
                      onClick={() => onLoadNumberClick(item.loadNumber)}
                      className={classes.cellColor}
                    >
                      {item.loadNumber}
                    </TableCell>
                    <TableCell>${item.invoicePayout}</TableCell>
                    <TableCell>${item.boardPayout}</TableCell>
                    <TableCell>${item.difference}</TableCell>
                  </TableRow>
                )
              })}
            </EnhancedTable>
          )}
        </Box>
      </PerfectScrollbar>
    </>
  )
}

LoadDataTable.propTypes = {
  data: PropTypes.array
}

export default LoadDataTable
