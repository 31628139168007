/* eslint-disable no-unused-vars */
import React from "react"
import {
  Box,
  Button,
  Grid,
  MenuItem,
  SvgIcon,
  TextField
} from "@material-ui/core"
import { PageSection, FormikControl, Page } from "src/components"
import SuspenseWrapper from "src/components/layout/SuspenseWrapper"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import { useWeeks } from "src/modules/shared"
import { Search as SearchIcon } from "react-feather"
import { useEnhancedMutate } from "src/hooks"
import UploadDocument from "src/modules/shared/UploadDocument"
import { useQueryClient } from "react-query"
import { quarterList, yearList } from "src/helpers/GlobalConstants"
import { importIftaMiles } from "./api/api"
import { useFetchIftaMiles } from "./api/hooks"
import IftaMilesDataTable from "./components/IftaMilesDataTable"

const IftaMilesImportPage = () => {
  const queryClient = useQueryClient()

  const [weekList, setWeekList] = React.useState([])
  const [iftaMilesList, setIftaMilesList] = React.useState([])

  const [selectedQuarter, setSelectedQuarter] = React.useState(null)
  const [selectedYear, setSelectedYear] = React.useState(
    new Date().getFullYear()
  )

  const initialFilterValues = {
    weekId: "",
    quarter: 1,
    year: selectedYear
  }

  const [filterValues, setFilterValues] = React.useState(initialFilterValues)
  const iftaMileResult = useFetchIftaMiles(filterValues)
  const weeksDataResult = useWeeks(selectedYear)

  const onSubmit = async values => {
    setFilterValues(values)
  }

  const onRefresh = () => {
    queryClient.invalidateQueries(["fetchIftaMiles", filterValues])
  }

  React.useEffect(() => {
    if (weeksDataResult.isFetched) {
      setWeekList(weeksDataResult.data)
    }
    if (iftaMileResult.isFetched) {
      setIftaMilesList(iftaMileResult.data)
    }
  }, [weeksDataResult, iftaMileResult])

  const [uploadDocument] = useEnhancedMutate(importIftaMiles, {})

  const handleOnUpload = async formData => {
    formData.append("Year", selectedYear || 0)
    formData.append("Quarter", selectedQuarter || 0)
    await uploadDocument(formData)
  }

  const validationSchema = Yup.object().shape({
    // origin: Yup.string().required("Origin city is required")
  })

  return (
    <>
      <Page title="IFTA Miles Import Page">
        <PageSection>
          <Grid container spacing={3}>
            <Grid item xs={12} md={1}>
              <TextField
                id="outlined-select-currency"
                select
                fullWidth
                margin="normal"
                label="Work Year"
                onChange={e => setSelectedYear(e.target.value)}
                value={selectedYear}
                variant="standard"
              >
                {yearList.map(option => (
                  <MenuItem key={option} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                margin="normal"
                variant="standard"
                name="Quarter"
                label="Quarters"
                onChange={e => setSelectedQuarter(e.target.value)}
                select
                defaultValue=""
              >
                {quarterList.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <SuspenseWrapper>
                <UploadDocument onUpload={handleOnUpload} />
              </SuspenseWrapper>
            </Grid>
          </Grid>
        </PageSection>
        <PageSection>
          <Box sx={{ pt: 3 }}>
            <SuspenseWrapper>
              <Formik
                initialValues={initialFilterValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnMount
                enableReinitialize
              >
                {formik => {
                  return (
                    <Form>
                      <Grid container spacing={1}>
                        <Grid item md={1} xs={12}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            fullWidth
                            margin="normal"
                            label="Work Year"
                            onChange={e => setSelectedYear(e.target.value)}
                            value={selectedYear}
                            variant="standard"
                          >
                            {yearList.map(option => (
                              <MenuItem key={option} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <FormikControl
                            control="select"
                            data={weekList}
                            label="Work Weeks"
                            name="weekId"
                            type="text"
                            variant="standard"
                            values={formik.values.weekId}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <FormikControl
                            control="select"
                            data={quarterList}
                            label="Quarter"
                            name="quarter"
                            type="text"
                            variant="standard"
                            values={formik.values.quarter}
                          />
                        </Grid>
                        <Grid item md={1} xs={12}>
                          <Box pt={3} justifyContent="flex-end">
                            <Button
                              fullWidth
                              type="submit"
                              color="primary"
                              variant="outlined"
                              startIcon={
                                <SvgIcon fontSize="small" color="action">
                                  <SearchIcon />
                                </SvgIcon>
                              }
                              disabled={!formik.isValid || formik.isSubmitting}
                            >
                              Search
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item md={1} xs={12}>
                          <Box pt={3} justifyContent="flex-end">
                            <Button
                              fullWidth
                              type="button"
                              onClick={() => onRefresh()}
                              color="warning"
                              variant="outlined"
                            >
                              Refresh
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Form>
                  )
                }}
              </Formik>
            </SuspenseWrapper>
          </Box>
        </PageSection>
        {iftaMilesList && (
          <PageSection name="Fuel">
            <IftaMilesDataTable data={iftaMilesList} />
          </PageSection>
        )}
      </Page>
    </>
  )
}

IftaMilesImportPage.propTypes = {}

export default IftaMilesImportPage
