import axios from "axios"

export const fetchCompanies = async () => {
  const result = await axios.get("/api/Lookup/GetCompanyListUserAdmin")
  return result.data
}

export const fetchUserStatuses = async () => {
  const result = await axios.get("/api/Lookup/GetUserStatuses")
  return result.data
}

export const fetchRoles = async () => {
  const result = await axios.get("/api/Lookup/GetRoles")
  return result.data
}

export const fetchUsers = async () => {
  const result = await axios.get("/api/auth/GetUsers")
  return result.data
}

export const saveUser = async data => {
  const result = await axios.post("/api/auth/UpdateUserInformation", data)
  return result.data
}

export const fetchLookupData = async () => {
  const getCompanies = await axios.get("/api/Lookup/GetCompanyListUserAdmin")
  const getRoles = await axios.get("/api/Lookup/GetRoles")
  const getStatuses = await axios.get("/api/Lookup/GetUserStatuses")

  const response = await Promise.all([getCompanies, getRoles, getStatuses])

  return {
    companyList: response[0].data,
    roleList: response[1].data,
    statusList: response[2].data
  }
}
