import React from "react"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

const ToastContext = React.createContext()

const ToastBoundary = ({ children }) => {
  const onSuccess = message => {
    toast.success(message)
  }

  const onError = message => {
    toast.error(message)
  }

  const onInfo = message => {
    toast.info(message)
  }

  const context = {
    onSuccess,
    onError,
    onInfo
  }
  return (
    <ToastContext.Provider value={context}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      {children}
    </ToastContext.Provider>
  )
}

ToastBoundary.propTypes = {
  children: PropTypes.element
}

export { ToastBoundary, ToastContext }
