export const workType = [
  { label: "Any", value: "Any" },
  { label: "One Way", value: "ONE_WAY" },
  { label: "Round Trip", value: "ROUND_TRIP" }
]

export const radius = [
  { label: "10", value: 10 },
  { label: "15", value: 15 },
  { label: "20", value: 20 },
  { label: "25", value: 25 },
  { label: "30", value: 30 },
  { label: "50", value: 50 },
  { label: "75", value: 75 },
  { label: "100", value: 100 }
]

export const numberOfStops = [
  { label: "Any", value: 0 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 }
]

export const trailerStatus = [
  { label: "Any", value: "" },
  { label: "Provided", value: "PROVIDED" },
  { label: "Required", value: "REQUIRED" }
]

export const loadingType = [
  { label: "Any", value: "Any" },
  { label: "Drop and Hook", value: "DROP" },
  { label: "Live", value: "LIVE" }
]

export const orderType = [
  { label: "Alert", value: 1 },
  { label: "Book", value: 2 },
  { label: "Book & Assign", value: 3 }
]

export const timeZone = [
  { label: "EST", value: "EST" },
  { label: "CST", value: "CST" }
]

export const usStateList = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DC", label: "District of Columbia" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" }
]

export const citiesList = [
  {
    label: "Edison, NJ",
    value:
      "{label: 'Edison, NJ', country: 'US', name: 'Edison',nearestDomicileCode: 'DEN', stateCode: 'NJ'}"
  },
  {
    label: "Imperial, PA",
    value:
      "{label: 'Imperial, PA',country: 'US',name: 'Imperial',nearestDomicileCode: 'PIT',stateCode: 'PA'}"
  },
  {
    label: "Hebron, KY",
    value:
      "{label: 'Hebron, KY',country: 'US',name: 'Hebron',nearestDomicileCode: 'CVG',stateCode: 'KY'}"
  },
  {
    label: "Indianapolis, IN",
    value:
      "{label: 'Indianapolis, IN',country: 'US',name: 'Indianapolis',nearestDomicileCode: 'IND',stateCode: 'IN'}"
  },
  {
    label: "Chicago, IL",
    value:
      "{label: 'Chicago, IL',country: 'US',name: 'Chicago',nearestDomicileCode: 'MDW',stateCode: 'IL'}"
  }
]
