import React from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Box, TableCell, TableRow } from "@material-ui/core"
import { EnhancedTable } from "src/components"
import PropTypes from "prop-types"

const IftaReportTable = ({ data, summary }) => {
  const headers = [
    {
      id: "stateName",
      numeric: false,
      disablePadding: false,
      label: "State"
    },
    {
      id: "miles",
      numeric: false,
      disablePadding: false,
      label: "Miles"
    },
    {
      id: "gallons",
      numeric: false,
      disablePadding: false,
      label: "Gallons"
    }
  ]

  if (!summary) {
    headers.unshift({
      id: "unitNumber",
      numeric: false,
      disablePadding: false,
      label: "Unit"
    })
  }

  return (
    <>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {data && (
            <EnhancedTable data={data} headers={headers} dense>
              {data.map(item => {
                return (
                  <TableRow hover key={item.createdOn}>
                    {!summary && <TableCell>{item.unitNumber}</TableCell>}
                    <TableCell>{item.stateName}</TableCell>
                    <TableCell>{Math.round(item.miles)}</TableCell>
                    <TableCell>{Math.round(item.gallons)}</TableCell>
                  </TableRow>
                )
              })}
            </EnhancedTable>
          )}
        </Box>
      </PerfectScrollbar>
    </>
  )
}

IftaReportTable.propTypes = {
  data: PropTypes.array,
  summary: PropTypes.bool
}

export default IftaReportTable
