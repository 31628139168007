import React from "react"
import {
  TableCell,
  TableRow,
  Table,
  TableBody,
  Skeleton
} from "@material-ui/core"

import PropTypes from "prop-types"

const SkeletonDataTable = ({ cells }) => {
  // below functions added to avoid eslint key warning

  const getCells = () => {
    const cellsArray = []
    for (let i = 1; i <= cells; i++) {
      cellsArray.push(
        <TableCell key={i} padding="normal">
          <Skeleton variant="text" />
        </TableCell>
      )
    }
    return cellsArray
  }

  const rowsArray = []
  for (let i = 1; i <= 10; i++) {
    rowsArray.push(
      <TableRow hover key={i}>
        {getCells()}
      </TableRow>
    )
  }

  return (
    <Table>
      <TableBody>{rowsArray}</TableBody>
    </Table>
  )
}

SkeletonDataTable.propTypes = {
  cells: PropTypes.number
}

export default SkeletonDataTable
