import React from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Box, TableCell, TableRow } from "@material-ui/core"
import { EnhancedTable } from "src/components"
import PropTypes from "prop-types"

const FuelDataTable = ({ data }) => {
  const headers = [
    {
      id: "fuelDate",
      numeric: false,
      disablePadding: false,
      label: "Date"
    },
    {
      id: "unitNumber",
      numeric: false,
      disablePadding: false,
      label: "Unit Number"
    },
    {
      id: "quantity",
      numeric: false,
      disablePadding: false,
      label: "QTY"
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: false,
      label: "Amount"
    },
    {
      id: "fuelStateName",
      numeric: false,
      disablePadding: false,
      label: "State"
    }
  ]

  return (
    <>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {data && (
            <EnhancedTable
              data={data}
              headers={headers}
              sortBy="fuelDate"
              sortOrder="asc"
              dense
            >
              {data.map(item => {
                return (
                  <TableRow hover key={item.createdOn}>
                    <TableCell>{item.fuelDate}</TableCell>
                    <TableCell>{item.unitNumber}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>${item.amount}</TableCell>
                    <TableCell>{item.fuelStateName}</TableCell>
                  </TableRow>
                )
              })}
            </EnhancedTable>
          )}
        </Box>
      </PerfectScrollbar>
    </>
  )
}

FuelDataTable.propTypes = {
  data: PropTypes.array
}

export default FuelDataTable
