/* eslint-disable no-unused-vars */
import React from "react"
import {
  Box,
  Button,
  Grid,
  MenuItem,
  SvgIcon,
  TextField
} from "@material-ui/core"
import { PageSection, FormikControl, Page, Select } from "src/components"
import SuspenseWrapper from "src/components/layout/SuspenseWrapper"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import { useWeeks } from "src/modules/shared"
import { Search as SearchIcon } from "react-feather"
import { useQueryClient } from "react-query"
import { quarterList, yearList } from "src/helpers/GlobalConstants"
import { useIftaReport, useLookupData, useIftaReportSummary } from "./api/hooks"
import IftaReportTable from "./components/IftaReportTable"

const IftaReportPage = () => {
  const queryClient = useQueryClient()

  const [weekList, setWeekList] = React.useState([])
  const [iftaReport, setIftaReport] = React.useState([])
  const [iftaReportSummary, setIftaReportSummary] = React.useState([])
  const [iftaDrivers, setIftaDrivers] = React.useState([])
  const [stateList, setStateList] = React.useState([])

  const [selectedYear, setSelectedYear] = React.useState(
    new Date().getFullYear()
  )

  const initialFilterValues = {
    weekId: "",
    quarter: 1,
    stateId: "",
    year: selectedYear,
    driverIdList: []
  }

  const [filterValues, setFilterValues] = React.useState(initialFilterValues)
  const iftaReportResult = useIftaReport(filterValues)
  const iftaReportSummaryResult = useIftaReportSummary(filterValues)
  const weeksDataResult = useWeeks(selectedYear)
  const lookupResult = useLookupData()

  const onSubmit = async values => {
    setFilterValues(values)
  }

  const onRefresh = () => {
    queryClient.invalidateQueries(["fetchIftaReport", filterValues])
    queryClient.invalidateQueries(["fetchIftaReportSummary", filterValues])
  }

  React.useEffect(() => {
    if (weeksDataResult.isFetched) {
      setWeekList(weeksDataResult.data)
    }
    if (iftaReportResult.isFetched) {
      setIftaReport(iftaReportResult.data)
    }
    if (iftaReportSummaryResult.isFetched) {
      setIftaReportSummary(iftaReportSummaryResult.data.unitReports[0])
    }
  }, [weeksDataResult, iftaReport, iftaReportSummaryResult])

  React.useEffect(() => {
    if (lookupResult.isFetched) {
      setIftaDrivers(lookupResult.data.iftaDrivers)
      setStateList(lookupResult.data.stateList)
    }
  }, [lookupResult])

  const validationSchema = Yup.object().shape({
    // origin: Yup.string().required("Origin city is required")
  })

  return (
    <>
      <Page title="IFTA Miles Import Page">
        <PageSection>
          <Box sx={{ pt: 3 }}>
            <SuspenseWrapper>
              <Formik
                initialValues={initialFilterValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnMount
                enableReinitialize
              >
                {formik => {
                  return (
                    <Form>
                      <Grid container spacing={1}>
                        <Grid item md={1} xs={12}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            fullWidth
                            margin="normal"
                            label="Work Year"
                            onChange={e => setSelectedYear(e.target.value)}
                            value={selectedYear}
                            variant="standard"
                          >
                            {yearList.map(option => (
                              <MenuItem key={option} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <FormikControl
                            control="select"
                            data={quarterList}
                            label="Quarter"
                            name="quarter"
                            type="text"
                            variant="standard"
                            values={formik.values.quarter}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <FormikControl
                            control="multiselect"
                            data={iftaDrivers}
                            label="Drivers"
                            name="driverIdList"
                            type="text"
                            variant="standard"
                            form={formik}
                            values={formik.values.driverIdList}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <FormikControl
                            control="select"
                            data={weekList}
                            label="Work Weeks"
                            name="weekId"
                            type="text"
                            variant="standard"
                            values={formik.values.weekId}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <FormikControl
                            control="select"
                            data={stateList}
                            label="State"
                            name="stateId"
                            type="text"
                            variant="standard"
                            value={formik.values.stateId}
                          />
                        </Grid>
                        <Grid item md={1} xs={12}>
                          <Box pt={3} justifyContent="flex-end">
                            <Button
                              fullWidth
                              type="submit"
                              color="primary"
                              variant="outlined"
                              startIcon={
                                <SvgIcon fontSize="small" color="action">
                                  <SearchIcon />
                                </SvgIcon>
                              }
                              disabled={!formik.isValid || formik.isSubmitting}
                            >
                              Search
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item md={1} xs={12}>
                          <Box pt={3} justifyContent="flex-end">
                            <Button
                              fullWidth
                              type="button"
                              onClick={() => onRefresh()}
                              color="warning"
                              variant="outlined"
                            >
                              Refresh
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Form>
                  )
                }}
              </Formik>
            </SuspenseWrapper>
          </Box>
        </PageSection>
        <PageSection name="Totals">
          <Box justifyContent="space-between" display="flex">
            <Box>Total Gallons: {Math.round(iftaReport.totalGallons)}</Box>
            <Box>Total Miles: {Math.round(iftaReport.totalMiles)}</Box>
            <Box>
              MPG:{" "}
              {Math.round((iftaReport.averageMPG + Number.EPSILON) * 100) / 100}
            </Box>
          </Box>
        </PageSection>
        <PageSection name="Summary Report">
          {iftaReportSummary && (
            <IftaReportTable data={iftaReportSummary} summary />
          )}
        </PageSection>
        {iftaReport.unitReports &&
          iftaReport.unitReports.length > 0 &&
          iftaReport.unitReports.map(item => {
            return (
              <PageSection name={`Unit ${item[0].unitNumber}`}>
                <IftaReportTable data={item} />
              </PageSection>
            )
          })}
      </Page>
    </>
  )
}

IftaReportPage.propTypes = {}

export default IftaReportPage
