/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/styles"
import ListSubheader from "@material-ui/core/ListSubheader"
import List from "@material-ui/core/List"
import {
  ConfirmDialog,
  IconButton,
  PageSection,
  PageSectionAccordion
} from "src/components"
import { PropTypes } from "prop-types"
import { CircularProgress, ListItemSecondaryAction } from "@material-ui/core"
import { useEnhancedMutate } from "src/hooks"
import {
  useActiveOrders,
  useOrderHistory,
  useCompanyDrivers
} from "../api/hooks"
import { postLoadOrder, cancelLoadOrder, placeOrder } from "../api/api"
import AddEditItem from "./AddEditItem"
import OrderDetails from "./OrderDetails"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
    // maxWidth: 360,
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}))

const OrderListPage = ({ addNewItem, onAddItemClose }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const dataQuery = useOrderHistory()
  const driverData = useCompanyDrivers()
  const [driverList, setDriverList] = React.useState([])
  const [activeOrders, setActiveOrders] = useState()
  const [editItem, setEditItem] = useState(null)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState(null)
  const handleClick = () => {
    setOpen(!open)
  }

  const handleAddItem = () => {
    setEditItem(null)
    setIsEditDialogOpen(true)
  }
  const handleOnCloseDialog = () => {
    setEditItem(null)
    setIsEditDialogOpen(false)
    onAddItemClose()
    setConfirmDialog({ isDialogOpen: false })
  }

  const [postOrder] = useEnhancedMutate(placeOrder, {
    invalidateQueries: [
      {
        queryKey: ["fetchOrderHistory"]
      }
    ],
    onSuccess: () => {
      handleOnCloseDialog()
    }
  })

  const handleOnSave = item => {
    setIsEditDialogOpen(false)
    const dialog = {
      text: `Are you sure you want to submit your order for ${item.driver1}`,
      onConfirm: () => postOrder(item),
      onCancel: () => handleOnCloseDialog(),
      isDialogOpen: true
    }
    setConfirmDialog(dialog)
  }

  const editOrder = item => {
    item.refreshRate = 0.5
    setEditItem(item)
    setIsEditDialogOpen(true)
  }

  useEffect(() => {
    if (addNewItem) {
      handleAddItem()
    }
  }, [addNewItem])

  useEffect(() => {
    if (dataQuery.isFetched) {
      setActiveOrders(dataQuery.data)
    }
  }, [dataQuery])

  useEffect(() => {
    if (driverData.isFetched) {
      setDriverList(driverData.data)
    }
  }, [driverData])

  return (
    <>
      <List
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Active Orders
          </ListSubheader>
        }
        className={classes.root}
      >
        {activeOrders &&
          activeOrders
            .filter(x => x.loadOrderStatusId === 1)
            .map(item => {
              return (
                <OrderDetails
                  item={item}
                  key={item.loadOrderId}
                  editOrder={editOrder}
                />
              )
            })}
      </List>
      <PageSectionAccordion name="Order History">
        <List aria-labelledby="nested-list-subheader" className={classes.root}>
          {activeOrders &&
            activeOrders
              .filter(x => x.loadOrderStatusId === 0)
              .map(item => {
                return (
                  <OrderDetails
                    item={item}
                    key={item.loadOrderId}
                    editOrder={editOrder}
                  />
                )
              })}
        </List>
      </PageSectionAccordion>
      <AddEditItem
        onSave={handleOnSave}
        onClose={handleOnCloseDialog}
        editItem={editItem}
        isDialogOpen={isEditDialogOpen}
        driverList={driverList}
      />
      <ConfirmDialog props={confirmDialog} />
    </>
  )
}

OrderListPage.propTypes = {
  addNewItem: PropTypes.bool,
  onAddItemClose: PropTypes.func
}

export default OrderListPage
