/* eslint-disable */
import React from "react"
import {
  TableCell,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableContainer
} from "@material-ui/core"
import { useLocation } from "react-router"
import { Page, PageSection } from "src/components"

const WeeklyLoadsPrintView = () => {
  const { state } = useLocation()
  const { data } = state

  const emptyCell = (
    <TableCell size="small" width={30} align="center"></TableCell>
  )
  const uniqueUnits = [...new Set(data.map(x => x.loadUnitNumber[0]))].sort()

  return (
    <>
      <Page>
        <PageSection>
          <TableContainer sx={{ maxWidth: 1100 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell size="small" width={30} align="center">
                    Load Number
                  </TableCell>
                  {uniqueUnits &&
                    uniqueUnits.map(x => {
                      if (x !== undefined) {
                        return (
                          <TableCell size="small" width={30} align="center">
                            {x}
                          </TableCell>
                        )
                      }
                    })}
                  <TableCell size="small" width={30} align="center">
                    Other
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map(item => {
                    return (
                      <TableRow key={item.loadNumber + item.payout}>
                        <TableCell size="small" width={30} align="center">
                          {item.loadNumber}
                        </TableCell>
                        {uniqueUnits &&
                          !uniqueUnits.includes(item.loadUnitNumber[0]) &&
                          uniqueUnits.map(x => {
                            return emptyCell
                          })(
                            <TableCell size="small" width={30} align="center">
                              {item.payout}
                            </TableCell>
                          )}
                        {uniqueUnits &&
                          uniqueUnits.includes(item.loadUnitNumber[0]) &&
                          uniqueUnits.map(x => {
                            if (item.loadUnitNumber[0] === x) {
                              return (
                                <TableCell
                                  size="small"
                                  width={30}
                                  align="center"
                                >
                                  {item.payout}
                                </TableCell>
                              )
                            } else {
                              return emptyCell
                            }
                          })}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </PageSection>
      </Page>
    </>
  )
}

export default WeeklyLoadsPrintView
