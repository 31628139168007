import React from "react"
import PropTypes from "prop-types"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import { Button } from "@material-ui/core"

const AddButton = ({ children, ...rest }) => {
  return (
    <Button {...rest} startIcon={<AddCircleOutlineIcon />}>
      {children}
    </Button>
  )
}

AddButton.propTypes = {
  children: PropTypes.string
}

export default AddButton
