import React from "react"
import PropTypes from "prop-types"
import { Formik, Form } from "formik"
import {
  Box,
  Button,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  Divider
} from "@material-ui/core"
import { FormikControl } from "src/components"
import * as Yup from "yup"
import { ProtectedElement } from "src/components/auth"

const AddEditItem = ({ onSave, onClose, data, isDialogOpen, editItem }) => {
  const { states: stateList } = data

  const onSubmit = async values => {
    onSave(values)
  }

  const YesNo = [
    { value: "True", label: "Yes" },
    { value: "False", label: "No" }
  ]

  const initialValues = {
    brokerId: "",
    brokerName: "",
    brokerAddress: "",
    brokerCity: "",
    brokerStateId: 5,
    brokerZipCode: "",
    phoneNumber: "",
    email: "",
    mcNumber: "",
    dotNumber: "",
    comments: "",
    isActive: "True"
  }

  const validationSchema = Yup.object().shape({
    brokerName: Yup.string().required("Brokers name is required"),
    brokerAddress: Yup.string().required("Address is required"),
    brokerCity: Yup.string().required("City is required"),
    brokerStateId: Yup.number().required("State is required"),
    brokerZipCode: Yup.string().required("ZipCode is required"),
    phoneNumber: Yup.string().required("Required"),
    email: Yup.string().required("Required")
  })

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose()
          }
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {editItem ? "Update broker" : "Add broker"}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={editItem || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
            enableReinitialize
          >
            {formik => {
              return (
                <Form>
                  <Grid container spacing={1}>
                    <Grid item md={4} xs={12}>
                      <FormikControl
                        control="input"
                        title="Name"
                        type="text"
                        label="Name"
                        name="brokerName"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormikControl
                        control="input"
                        title="MC Number"
                        type="text"
                        label="MC Number"
                        name="mcNumber"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormikControl
                        control="input"
                        title="DOT Number"
                        type="text"
                        label="DOT Number"
                        name="dotNumber"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="input"
                        title="Address"
                        type="text"
                        label="Address"
                        name="brokerAddress"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="input"
                        title="City"
                        type="text"
                        label="City"
                        name="brokerCity"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="select"
                        data={stateList}
                        label="State"
                        name="brokerStateId"
                        type="text"
                        variant="standard"
                        value={formik.values.userStateId}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="input"
                        title="ZipCode"
                        type="text"
                        label="ZipCode"
                        name="brokerZipCode"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="input"
                        title="Email"
                        type="text"
                        label="Email"
                        name="email"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="input"
                        title="Phone Number"
                        type="text"
                        label="Phone Number"
                        name="phoneNumber"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="input"
                        title="Comments"
                        type="text"
                        label="Comments"
                        name="comments"
                        variant="standard"
                      />
                    </Grid>
                    <ProtectedElement name="BrokerAdmin" accessLevel="Write">
                      <Grid item md={12} xs={12}>
                        <FormikControl
                          control="radio"
                          label="Active"
                          name="isActive"
                          options={YesNo}
                        />
                      </Grid>
                    </ProtectedElement>
                    <Grid item md={12} xs={12}>
                      <Divider />
                      <Box display="flex" justifyContent="flex-end">
                        <Button onClick={onClose} color="primary">
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          Save
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}

AddEditItem.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  data: PropTypes.object,
  isDialogOpen: PropTypes.bool,
  editItem: PropTypes.object
}

export default AddEditItem
