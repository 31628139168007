import React from "react"
import { Navigate } from "react-router"
import PropTypes from "prop-types"
import { useAuth } from "../hooks"

// eslint-disable-next-line no-unused-vars
const ProtectedRoute = ({ children, name, ...rest }) => {
  const auth = useAuth()
  const { currentUser } = auth

  if (!currentUser) {
    return <Navigate to="/login" />
  }

  if (currentUser && !currentUser.companyId && !currentUser.roleName) {
    return <Navigate to="/register" />
  }

  if (currentUser && currentUser.companyId && !currentUser.roleName) {
    return <Navigate to="/application-in-review" />
  }

  return <>{children}</>
}

ProtectedRoute.propTypes = {
  children: PropTypes.object,
  name: PropTypes.string
}

export default ProtectedRoute
