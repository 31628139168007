import React from "react"
import PropTypes from "prop-types"
import { Formik, Form } from "formik"
import {
  Box,
  Button,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  Divider
} from "@material-ui/core"
import { FormikControl } from "src/components"
import * as Yup from "yup"

const AddEditItem = ({ onSave, onClose, data, isDialogOpen, editItem }) => {
  const { expenseTypeList, frequencyTypeList, driverList } = data

  const onSubmit = async values => {
    onSave(values)
  }

  const initialValues = {
    companyExpenseId: "",
    expenseTypeId: "",
    expenseName: "",
    amount: 0,
    frequencyTypeId: "",
    occurences: 0,
    expenseDate: "",
    expenseStartDate: "",
    expenseEndDate: "",
    expenseUserId: "",
    comments: ""
  }

  const validationSchema = Yup.object().shape({
    expenseTypeId: Yup.number().required("Field is required"),
    expenseName: Yup.string().required("Field is required"),
    amount: Yup.number().required("Field is required"),
    frequencyTypeId: Yup.number().required("Field is required"),
    expenseDate: Yup.date(),
    expenseStartDate: Yup.date(),
    expenseEndDate: Yup.date(),
    expenseUserId: Yup.number(),
    comments: Yup.string()
  })

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {editItem ? "Update load" : "Add load"}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={editItem || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
            enableReinitialize
          >
            {formik => {
              return (
                <Form>
                  <Grid container spacing={1}>
                    <Grid item md={4} xs={12}>
                      <FormikControl
                        control="select"
                        data={expenseTypeList}
                        label="Type"
                        name="expenseTypeId"
                        type="text"
                        variant="standard"
                        value={formik.values.expenseTypeId}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormikControl
                        control="select"
                        data={frequencyTypeList}
                        label="Frequency"
                        name="frequencyTypeId"
                        type="text"
                        variant="standard"
                        value={formik.values.frequencyTypeId}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormikControl
                        control="input"
                        label="Occurences"
                        name="occurences"
                        type="text"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="input"
                        title="Description"
                        type="text"
                        label="Description"
                        name="expenseName"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="input"
                        title="Amount"
                        type="number"
                        label="Amount"
                        name="amount"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="datepicker"
                        title="Start Date"
                        label="Start Date"
                        type="date"
                        name="expenseStartDate"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="datepicker"
                        title="End Date"
                        type="date"
                        label="End Date"
                        name="expenseEndDate"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="datepicker"
                        title="Expense Date"
                        type="datetime-local"
                        label="Expense Date"
                        name="expenseDate"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        control="select"
                        data={driverList}
                        label="Driver"
                        name="expenseUserId"
                        type="text"
                        variant="standard"
                        values={formik.values.loadDrivers}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FormikControl
                        control="input"
                        title="Comments"
                        type="text"
                        label="Comments"
                        name="comments"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Divider />
                      <Box display="flex" justifyContent="flex-end">
                        <Button onClick={onClose} color="primary">
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          Save
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}

AddEditItem.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  data: PropTypes.object,
  isDialogOpen: PropTypes.bool,
  editItem: PropTypes.object
}

export default AddEditItem
