/* eslint-disable */
import React, { forwardRef } from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { Card, CardHeader, Container, LinearProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  card: {
    marginBottom: 5
  },
  cardHeader: {
    backgroundColor: theme.palette.background.paper
  }
}))

const Page = forwardRef(({ children, title = "", isLoading, ...rest }, ref) => {
  const classes = useStyles()
  return (
    <>
      <div ref={ref} {...rest} className={classes.root}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Container maxWidth={false}>
          {isLoading && <LinearProgress color="primary" />}
          <Card className={classes.card}>
            <CardHeader className={classes.cardHeader} title={title} mb={2} />
          </Card>
          {children}
        </Container>
      </div>
    </>
  )
})

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
}

export default Page
