import React, { useState, useEffect } from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Box, TableCell, TableRow } from "@material-ui/core"
import { EnhancedTable } from "src/components"
import PropTypes from "prop-types"

import moment from "moment"

const ExpenseDataTable = ({ data }) => {
  const [sortedData, setSortedData] = useState(null)

  const handleOnSort = inData => {
    setSortedData(inData)
  }
  useEffect(() => {
    setSortedData(data)
  }, [data])

  const headers = [
    {
      id: "expenseName",
      numeric: false,
      disablePadding: false,
      label: "Expense Description"
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: false,
      label: "Amount"
    },
    {
      id: "expenseDate",
      numeric: false,
      disablePadding: false,
      label: "Date"
    }
  ]

  return (
    <>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {sortedData && (
            <EnhancedTable
              data={sortedData}
              headers={headers}
              onSort={handleOnSort}
              dense
            >
              {sortedData.map(item => {
                return (
                  <TableRow hover key={item.expenseId}>
                    <TableCell>{item.expenseName}</TableCell>
                    <TableCell>{item.amount}</TableCell>
                    <TableCell>
                      {moment(item.expenseDate).format("DD/MM/YYYY HH:mm")}
                    </TableCell>
                  </TableRow>
                )
              })}
            </EnhancedTable>
          )}
        </Box>
      </PerfectScrollbar>
    </>
  )
}

ExpenseDataTable.propTypes = {
  data: PropTypes.array
}

export default ExpenseDataTable
