import { useQuery } from "react-query"
import { fetchIftaReport, fetchLookupData, fetchIftaReportSummary } from "./api"

export const useIftaReport = params => {
  return useQuery(["fetchIftaReport", params], fetchIftaReport)
}

export const useIftaReportSummary = params => {
  return useQuery(["fetchIftaReportSummary", params], fetchIftaReportSummary)
}

export const useLookupData = params => {
  return useQuery(["fetchLookupData", params], fetchLookupData)
}
