import React from "react"
import { useRoutes } from "react-router-dom"
import { StyledEngineProvider } from "@material-ui/core"
import { QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { AuthProvider } from "./components/auth/AuthBoundary"
import GlobalStyles from "./components/GlobalStyles"
import routes from "./routes/routes"
import { AppThemeProvider } from "./theme/AppThemeProvider"
import ReactQueryConfig from "./config/ReactQueryConfig"

const App = () => {
  const content = useRoutes(routes)

  return (
    <QueryClientProvider client={ReactQueryConfig}>
      <StyledEngineProvider injectFirst>
        <AuthProvider>
          <AppThemeProvider>
            <GlobalStyles />
            {content}
          </AppThemeProvider>
        </AuthProvider>
      </StyledEngineProvider>
      <ReactQueryDevtools initialOpen={false} />
    </QueryClientProvider>
  )
}

export default App
