import axios from "axios"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export const fetchSearchSuggestions = async ({ queryKey }) => {
  toast.configure()

  // eslint-disable-next-line no-unused-vars
  const [_, param] = queryKey
  // eslint-disable-next-line no-unused-vars
  const result = await axios.get(
    `https://completion.amazon.com/api/2017/suggestions?session-id=147-9627297-7920701&customer-id=A2P8D2161H6T04&request-id=E0W5SZK4JE3FZ8G8B655&page-type=Gateway&lop=en_US&site-variant=desktop&client-info=amazon-search-ui&mid=ATVPDKIKX0DER&alias=aps&ks=84&prefix=${param}&event=onKeyPress&limit=11&b2b=0&fresh=0&fb=1&suggestion-type=KEYWORD&suggestion-type=WIDGET&_=1639782663200`
  )
  return result.data
}

export const fetchActiveSearches = async () => {
  const result = await axios.get(`https://localhost:5001/api/Test`)
  return result.data
}

export const fetchOrderHistory = async () => {
  const result = await axios.get(`/api/LoadOrder`)
  if (typeof result.data === "string") {
    toast.error(result.data)
    return null
  }
  return result.data
}

export const postLoadOrder = async data => {
  const result = await axios.post("https://localhost:5001/api/Test", data)
  return result.data
}

export const placeOrder = async data => {
  const result = await axios.post(`/api/LoadOrder/PlaceOrder`, data)
  return result.data
}

export const fetchCities = async data => {
  const result = await axios.get(`/api/LoadOrder/GetCities?value=${data}`)
  if (typeof result.data === "string" && result.data.substr(0, 5) === "ERROR") {
    toast.error(result.data)
    return ""
  }
  return result.data
}

export const cancelLoadOrder = async data => {
  const result = await axios.delete(`/api/LoadOrder?id=${data}`)
  return result.data
}

export const fetchCompanyDrivers = async () => {
  const result = await axios.get("/api/LoadOrder/GetCompanyDrivers")
  return result.data
}
