import { Avatar, Card, CardContent, Grid, Typography } from "@material-ui/core"
import PropTypes from "prop-types"

const DriverTotals = ({ data, header, icon, iconColor, ...rest }) => {
  return (
    <Card {...rest}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {header}
            </Typography>
            <Typography color="textPrimary" variant="h3">
              $ {data.toLocaleString()}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: iconColor,
                height: 56,
                width: 56
              }}
            >
              {icon}
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

DriverTotals.propTypes = {
  data: PropTypes.number,
  header: PropTypes.string,
  icon: PropTypes.element,
  iconColor: PropTypes.string
}

export default DriverTotals
