import React from "react"
import { Box } from "@material-ui/core"
import { Page, SearchBar } from "src/components"
import SuspenseWrapper from "src/components/layout/SuspenseWrapper"
import OrderList from "./components/OrderListPage"

const SearchDashboard = () => {
  const [addNewItem, setAddNewItem] = React.useState(false)

  return (
    <>
      <Page title="Load Order Dashboard">
        <SearchBar name="Order" addNewItem={() => setAddNewItem(true)} mb={3} />
        <Box sx={{ pt: 3 }}>
          <SuspenseWrapper>
            <OrderList
              addNewItem={addNewItem}
              onAddItemClose={() => setAddNewItem(false)}
            />
          </SuspenseWrapper>
        </Box>
      </Page>
    </>
  )
}

SearchDashboard.propTypes = {}

export default SearchDashboard
