import React from "react"
import PropTypes from "prop-types"
import { Field } from "formik"
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormControl
} from "@material-ui/core"

const RadioButton = props => {
  const { label, name, options, ...rest } = props
  return (
    <>
      <Field name={name} id={name} {...rest}>
        {fieldProps => {
          const { field } = fieldProps
          // eslint-disable-next-line
          // console.log("field", field)
          return (
            <FormControl
              component="fieldset"
              variant="outlined"
              margin="normal"
            >
              <FormLabel component="label">{label}</FormLabel>
              <RadioGroup
                aria-label={label}
                {...rest}
                onChange={field.onChange}
                value={field.value}
                name={name}
                row
              >
                {options.map(item => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio />}
                    label={item.label}
                    {...rest}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )
        }}
      </Field>
    </>
  )
}

RadioButton.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  props: PropTypes.object
}

export default RadioButton
