import React, { useState, useEffect } from "react"
import moment from "moment"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@material-ui/core"
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone"
import { useEnhancedMutate } from "src/hooks"
import { IconButton } from "src/components"
import AddEditUser from "./AddEditUser"
import { useLookupData, useUsers } from "../api/hooks"
import { saveUser } from "../api/api"

const UsersDataTable = ({ ...rest }) => {
  const [selectedUserIds, setSelectedUserIds] = useState([])
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(0)

  const [data, setData] = useState([])
  const [lookupData, setLookupData] = useState(null)
  const [editItem, setEditItem] = useState(null)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const dataQuery = useUsers()
  const lookupQuery = useLookupData()

  const handleOnCloseDialog = () => {
    setEditItem(null)
    setIsEditDialogOpen(false)
  }

  const [saveUserInfo] = useEnhancedMutate(saveUser, {
    invalidateQueries: [
      {
        queryKey: ["fetchUsers"]
      }
    ],
    customSuccessMsg: "User updated successfully",
    onSuccess: () => {
      handleOnCloseDialog()
    }
  })

  useEffect(() => {
    if (dataQuery.isFetched) {
      setData(dataQuery.data)
    }
    if (lookupQuery.isFetched) {
      setLookupData(lookupQuery.data)
    }
  }, [dataQuery, lookupQuery])

  const handleSelectAll = event => {
    let newSelectedUserIds

    if (event.target.checked) {
      newSelectedUserIds = data.map(user => user.userId)
    } else {
      newSelectedUserIds = []
    }

    setSelectedUserIds(newSelectedUserIds)
  }

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUserIds.indexOf(id)
    let newSelectedUserIds = []

    if (selectedIndex === -1) {
      newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds, id)
    } else if (selectedIndex === 0) {
      newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds.slice(1))
    } else if (selectedIndex === selectedUserIds.length - 1) {
      newSelectedUserIds = newSelectedUserIds.concat(
        selectedUserIds.slice(0, -1)
      )
    } else if (selectedIndex > 0) {
      newSelectedUserIds = newSelectedUserIds.concat(
        selectedUserIds.slice(0, selectedIndex),
        selectedUserIds.slice(selectedIndex + 1)
      )
    }

    setSelectedUserIds(newSelectedUserIds)
  }

  const handleLimitChange = event => {
    setLimit(event.target.value)
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handleEditUser = item => {
    setEditItem(item)
    setIsEditDialogOpen(true)
  }
  const handleOnSave = item => {
    saveUserInfo(item)
    setIsEditDialogOpen(false)
  }

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedUserIds.length === data.length}
                      color="primary"
                      indeterminate={
                        selectedUserIds.length > 0 &&
                        selectedUserIds.length < data.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Unit Number</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Last Login</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Fee</TableCell>
                  <TableCell>Companies</TableCell>
                  <TableCell>Roles</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.slice(0, limit).map(user => (
                  <TableRow
                    hover
                    key={user.userId}
                    selected={selectedUserIds.indexOf(user.userId) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUserIds.indexOf(user.userId) !== -1}
                        onChange={event => handleSelectOne(event, user.userId)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex"
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {user.fullName}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {user.unitNumber > 0 ? user.unitNumber : ""}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {moment(user.lastLoginDate).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>{user.phoneNumber}</TableCell>
                    <TableCell>{user.serviceFee}</TableCell>
                    <TableCell>
                      {user.userCompanyNames.map(comp => (
                        <Typography key={comp}>{comp}</Typography>
                      ))}
                    </TableCell>
                    <TableCell>
                      {user.userRoleNames.map(role => (
                        <Typography key={role}>{role}</Typography>
                      ))}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="Edit"
                        onClick={() => handleEditUser(user)}
                      >
                        <EditTwoToneIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={data.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
        />
      </Card>
      {lookupData && (
        <AddEditUser
          data={lookupData}
          onSave={handleOnSave}
          onClose={handleOnCloseDialog}
          editItem={editItem}
          isDialogOpen={isEditDialogOpen}
        />
      )}
    </>
  )
}

UsersDataTable.propTypes = {}

export default UsersDataTable
