import React from "react"
import { PropTypes } from "prop-types"
import { useLocalStorage } from "../hooks"

const AppThemeContext = React.createContext()

const AppThemeProvider = ({ children }) => {
  const [currentMode, setCurrentMode] = useLocalStorage("FMS_THEME", null)
  const [mode, setMode] = React.useState(currentMode)

  const setThemeMode = () => {
    setMode(mode === "light" ? "dark" : "light")
  }

  React.useEffect(() => {
    if (mode) {
      setCurrentMode(mode)
    }
  }, [mode])

  const value = {
    mode,
    setThemeMode
  }
  return (
    <AppThemeContext.Provider value={value}>
      {children}
    </AppThemeContext.Provider>
  )
}

AppThemeProvider.propTypes = {
  // children: React.Node
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
}

export { AppThemeProvider, AppThemeContext }
