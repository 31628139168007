import React from "react"
import PropTypes from "prop-types"
import { ErrorDialog } from ".."

const isEmpty = text => text == null || text.match(/^\s*$/) !== null

/** Render error boundary with fallback component
 *
 * Ref: https://codesandbox.io/s/adoring-goodall-8wbn7
 */
class ErrorBoundary extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidUpdate() {
    const { error } = this.state
    const { resetError } = this.props
    if (error && resetError) {
      this.resetError()
    }
  }

  componentDidCatch(error) {
    // eslint-disable-next-line no-console
    console.error(error)
    this.setState({ error })
  }

  resetError() {
    this.setState({ error: null })
  }

  render() {
    const { error } = this.state
    const { fallback, showErrStackMsg, children } = this.props
    if (error) {
      return (
        fallback || (
          <ErrorDialog
            messageDetails={
              showErrStackMsg && !isEmpty(error.message)
                ? error.message
                : undefined
            }
          />
        )
      )
    }
    return children
  }
}

ErrorBoundary.propTypes = {
  fallback: PropTypes.element,
  resetError: PropTypes.bool,
  showErrStackMsg: PropTypes.bool,
  children: PropTypes.element
}

export default ErrorBoundary
