/* eslint-disable no-unused-vars */
import React from "react"
import { Box, Button, Grid, MenuItem, TextField } from "@material-ui/core"
import { Page, PageSection, SearchBar, FormikControl } from "src/components"
import SuspenseWrapper from "src/components/layout/SuspenseWrapper"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import PropTypes from "prop-types"
import { useWeeks } from "src/modules/shared"
import { useSearchSuggestions, useLoadHistory } from "./api/hooks"
import { fetchSearch, fetchCities, fetchLoadHistory } from "./api/api"
import {
  workType,
  radius,
  numberOfStops,
  trailerStatus,
  loadingType,
  orderType,
  timeZone
} from "./api/options"

const LoadSearchPage = ({ onSave }) => {
  const [selectedWeek, setSelectedWeek] = React.useState(0)
  const onSubmit = async values => {
    setSelectedWeek(values.weekId)
    // await fetchLoadHistory(values.weekId)
  }

  const [weekList, setWeekList] = React.useState([])
  const [selectedYear, setSelectedYear] = React.useState(
    new Date().getFullYear()
  )
  const weeksDataResult = useWeeks(selectedYear)

  const loadHistoryResult = useLoadHistory(selectedWeek)

  const [suggestionList, setSuggestionList] = React.useState([])
  const [citiesList, setCitiesList] = React.useState([])
  const [searchValue, setSearchValue] = React.useState("")
  const suggestionQuery = useSearchSuggestions(searchValue)

  // React.useEffect(() => {
  //   if (suggestionQuery.isFetched) {
  //     setSuggestionList(suggestionQuery.data.suggestions)
  //   }
  // }, [suggestionQuery])

  React.useEffect(() => {
    if (weeksDataResult.isFetched) {
      setWeekList(weeksDataResult.data)
    }
  }, [weeksDataResult])

  const initialValues = {
    weekId: ""
  }

  // eslint-disable-next-line no-unused-vars
  const getSuggestions = async (e, formik) => {
    const data = await fetchSearch(e)
    setSuggestionList(data.suggestions)
  }

  const getCities = async e => {
    const data = await fetchCities(e)
    setCitiesList(data)
  }

  const validationSchema = Yup.object().shape({
    // origin: Yup.string().required("Origin city is required")
  })

  return (
    <Page title="Load Search Page">
      <SearchBar name="Load" mb={3} />
      <PageSection>
        <Box sx={{ pt: 3 }}>
          <SuspenseWrapper>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              validateOnMount
              enableReinitialize
            >
              {formik => {
                return (
                  <Form>
                    <Grid container spacing={1}>
                      <Grid item md={1} xs={12}>
                        <TextField
                          id="outlined-select-currency"
                          select
                          fullWidth
                          margin="normal"
                          label="Work Year"
                          onChange={e => setSelectedYear(e.target.value)}
                          value={selectedYear}
                          variant="standard"
                        >
                          {[2021, 2022, 2023, 2024, 2025].map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {!formik.values.startDate && !formik.values.endDate && (
                        <Grid item md={2} xs={12}>
                          <FormikControl
                            control="select"
                            data={weekList}
                            label="Work Weeks"
                            name="weekId"
                            type="text"
                            variant="standard"
                            values={formik.values.weekId}
                          />
                        </Grid>
                      )}

                      <Grid item md={3} xs={12}>
                        <FormikControl
                          control="autocomplete"
                          data={citiesList}
                          onInputChange={getCities}
                          label="Origin"
                          name="origin"
                          variant="standard"
                          pb={2}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                          <Button
                            type="submit"
                            color="primary"
                            disabled={!formik.isValid || formik.isSubmitting}
                          >
                            Save
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                )
              }}
            </Formik>
          </SuspenseWrapper>
        </Box>
      </PageSection>
    </Page>
  )
}

LoadSearchPage.propTypes = {
  onSave: PropTypes.func
}

export default LoadSearchPage
