import React from "react"
import PropTypes from "prop-types"
import { IconButton as CustomButton } from "@material-ui/core"

const IconButton = ({ children, ...rest }) => {
  return <CustomButton {...rest}>{children}</CustomButton>
}

IconButton.propTypes = {
  children: PropTypes.element
}

export default IconButton
