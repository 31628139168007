import React from "react"
import { TextField } from "@material-ui/core"
import { Field } from "formik"
import PropTypes from "prop-types"

const PhoneInput = props => {
  const { name, ...rest } = props
  // eslint-disable-next-line
  // console.log("maxLength", maxLength)
  return (
    <>
      <Field name={name} id={name} {...rest}>
        {fieldProps => {
          const { field, meta } = fieldProps
          return (
            <TextField
              error={Boolean(meta.touched && meta.error)}
              fullWidth
              helperText={meta.error}
              {...rest}
              inputProps={{ maxLength: 10 }}
              margin="normal"
              variant="outlined"
              {...field} // replaces name, value, onChange and onBlur
            />
          )
        }}
      </Field>
    </>
  )
}

PhoneInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  props: PropTypes.object
}
export default PhoneInput
