import React from "react"
import { Skeleton, Container } from "@material-ui/core"
import styled from "styled-components"

const StyledHeader = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`

const StyledHeaderContent = styled.div`
  width: 100%;
  padding-left: 5px;
`

const SkeletonPage = () => {
  return (
    <Container>
      <StyledHeader>
        <Skeleton animation="wave" variant="circle" width={40} height={40} />
        <StyledHeaderContent>
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: "5px" }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="90%"
            style={{ marginBottom: "5px" }}
          />
        </StyledHeaderContent>
      </StyledHeader>
      <Skeleton
        animation="wave"
        variant="rect"
        width="100%"
        height={100}
        style={{ marginBottom: "10px" }}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        width="100%"
        height={125}
        style={{ marginBottom: "10px" }}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        width="100%"
        height={135}
        style={{ marginBottom: "10px" }}
      />
    </Container>
  )
}

export default SkeletonPage
