import React from "react"
import { useAuth } from "src/hooks"
import { TextField, MenuItem, Typography } from "@material-ui/core"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

const RoleSelector = () => {
  const { currentUser, switchUsersCompany } = useAuth()
  const variant = "standard"

  const handleChange = (event, value) => {
    const selectedCompany = {
      label: value.props.children,
      value: event.target.value
    }
    switchUsersCompany(selectedCompany)
  }

  return (
    <PerfectScrollbar>
      {currentUser.userCompanies.length === 1 && (
        <Typography align="center" fontWeight={600} color="red">
          {currentUser.userCompanies[0].label}
        </Typography>
      )}
      {currentUser.userCompanies.length > 1 && (
        <TextField
          fullWidth
          margin="normal"
          variant={variant}
          value={currentUser.companyId}
          onChange={handleChange} // replaces name, value, onChange and onBlur
          select
        >
          {currentUser.userCompanies.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    </PerfectScrollbar>
  )
}

export default RoleSelector
