import { useQuery } from "react-query"
import { fetchExpenses, fetchLookupData } from "./api"

export const useExpenses = () => {
  return useQuery("fetchExpenses", fetchExpenses)
}

export const useLookupData = () => {
  return useQuery("fetchLookupData", fetchLookupData)
}
