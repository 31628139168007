import React, { useState, useEffect } from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Box, TableCell, TableRow } from "@material-ui/core"
import { EnhancedTable } from "src/components"
import moment from "moment"
import PropTypes from "prop-types"
import { useFilteredLoads } from "../api/hooks"

const LoadDataTable = ({ searchFilters }) => {
  const dataQuery = useFilteredLoads(searchFilters)

  // Used to check if useeffect trigerred by data update
  const [dataUpdatedOn, setDataUpdatedOn] = useState("")
  const [sortedData, setSortedData] = useState(null)

  const handleOnSort = inData => {
    setSortedData(inData)
  }
  useEffect(() => {
    if (dataQuery.isFetched && dataUpdatedOn < dataQuery.dataUpdatedAt) {
      setDataUpdatedOn(dataQuery.dataUpdatedAt)
      setSortedData(dataQuery.data)
    }
  }, [dataQuery, searchFilters])

  const headers = [
    {
      id: "loadNumber",
      numeric: false,
      disablePadding: false,
      label: "Load"
    },
    {
      id: "loadStartDate",
      numeric: false,
      disablePadding: false,
      label: "Start Date"
    },
    {
      id: "originCity",
      numeric: false,
      disablePadding: false,
      label: "Origin"
    },
    {
      id: "destinationCity",
      numeric: false,
      disablePadding: false,
      label: "Destination"
    },
    {
      id: "tripLength",
      numeric: false,
      disablePadding: false,
      label: "Miles"
    },
    {
      id: "numberOfStops",
      numeric: false,
      disablePadding: false,
      label: "Stops"
    },
    { id: "payout", numeric: false, disablePadding: false, label: "Payout" }
  ]

  return (
    <>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {sortedData && (
            <EnhancedTable
              data={sortedData}
              headers={headers}
              onSort={handleOnSort}
              dense
            >
              {sortedData.map(item => {
                return (
                  <TableRow hover key={item.loadId}>
                    <TableCell>{item.loadNumber}</TableCell>
                    <TableCell>
                      {moment(item.loadStartDate).format("MM/DD/YYYY HH:mm")}
                    </TableCell>
                    <TableCell>
                      {item.originCity} {item.originStateName}
                    </TableCell>
                    <TableCell>
                      {item.destinationCity} {item.destinationStateName}
                    </TableCell>
                    <TableCell>{item.tripLength}</TableCell>
                    <TableCell>{item.numberOfStops}</TableCell>
                    <TableCell>
                      ${item.pricePerMile}/${item.payout.toLocaleString()}
                    </TableCell>
                  </TableRow>
                )
              })}
            </EnhancedTable>
          )}
        </Box>
      </PerfectScrollbar>
    </>
  )
}

LoadDataTable.propTypes = {
  searchFilters: PropTypes.object
}

export default LoadDataTable
