import React from "react"
import { ConfirmDialog, FormikControl } from "src/components"
import { Box, Button, Grid } from "@material-ui/core"
import { useEnhancedMutate } from "src/hooks"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { setServiceUrl, refreshCookies } from "../api/api"
import { useServiceUrl } from "../api/hooks"

const ServiceConfigurationPage = () => {
  const dataQuery = useServiceUrl()
  // eslint-disable-next-line no-unused-vars
  const [editItem, setEditItem] = React.useState({ apiServiceUrl: "" })
  const [dataUpdatedOn, setDataUpdatedOn] = React.useState("")
  const [confirmDialog, setConfirmDialog] = React.useState(null)
  const initialValues = {
    apiServiceUrl: ""
  }

  React.useEffect(() => {
    if (dataQuery.isFetched && dataUpdatedOn < dataQuery.dataUpdatedAt) {
      setDataUpdatedOn(dataQuery.dataUpdatedAt)
      initialValues.apiServiceUrl = dataQuery.data
      setEditItem(initialValues)
    }
  }, [dataQuery])

  const [handleOnSave] = useEnhancedMutate(setServiceUrl, {
    invalidateQueries: [
      {
        queryKey: ["fetchServiceUrl"]
      }
    ],
    customSuccessMsg: "Service URL saved successfully"
  })

  const onSubmit = async value => {
    await handleOnSave(value.apiServiceUrl)
  }

  const validationSchema = Yup.object().shape({
    apiServiceUrl: Yup.string().required("Field is required")
  })

  const onClose = () => {
    const dialog = {
      isDialogOpen: false
    }
    setConfirmDialog(dialog)
  }

  const handleRefreshCookies = async () => {
    await refreshCookies()
    onClose()
  }

  const onCookieRefreshClick = async () => {
    const dialog = {
      text: `Are you sure you want to refresh Cookies?`,
      onConfirm: () => handleRefreshCookies(),
      onCancel: () => onClose(),
      isDialogOpen: true
    }
    setConfirmDialog(dialog)
  }

  return (
    <>
      <Formik
        initialValues={editItem || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount
        enableReinitialize
      >
        {formik => {
          return (
            <Form>
              <Grid container spacing={1} alignItems="center">
                <Grid item md={10} xs={12}>
                  <FormikControl
                    control="input"
                    title="Service Url"
                    type="text"
                    label="Service Url"
                    name="apiServiceUrl"
                    variant="filled"
                    fullWidth
                  />
                </Grid>
                <Grid item md={1} xs={12}>
                  <Box
                    m={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      disabled={!formik.isValid || formik.isSubmitting}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
      <Button
        fullWidth
        variant="contained"
        onClick={onCookieRefreshClick}
        color="warning"
      >
        Refresh Cookies
      </Button>
      <ConfirmDialog props={confirmDialog} />
    </>
  )
}

ServiceConfigurationPage.propTypes = {}

export default ServiceConfigurationPage
