import { Link as RouterLink } from "react-router-dom"
import { AppBar, IconButton, Toolbar, Box } from "@material-ui/core"
import InputIcon from "@material-ui/icons/Input"
import { useAuth } from "../../hooks"
import Logo from "./Logo"
import ToggleTheme from "../../theme/ToggleTheme"

const MainNavbar = props => {
  const auth = useAuth()
  const { currentUser, logOut } = auth

  const handleLogOut = () => logOut()

  return (
    <AppBar elevation={0} {...props}>
      <Toolbar sx={{ height: 64 }}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <ToggleTheme />
        {currentUser && (
          <IconButton color="inherit" onClick={handleLogOut} size="large">
            <InputIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default MainNavbar
