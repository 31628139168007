import { Outlet } from "react-router-dom"
import { styled } from "@material-ui/styles"
import { ThemeProvider } from "@material-ui/core"
import MainNavbar from "./MainNavbar"
import { Themes } from "../../theme"
import { useAppTheme } from "../../hooks"

const MainLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%"
}))

const MainLayoutWrapper = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64
})

const MainLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden"
})

const MainLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto"
})

const MainLayout = () => {
  const { mode } = useAppTheme()
  return (
    <ThemeProvider theme={Themes(mode)}>
      <MainLayoutRoot>
        <MainNavbar />
        <MainLayoutWrapper>
          <MainLayoutContainer>
            <MainLayoutContent>
              <Outlet />
            </MainLayoutContent>
          </MainLayoutContainer>
        </MainLayoutWrapper>
      </MainLayoutRoot>
    </ThemeProvider>
  )
}

export default MainLayout
