/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React from "react"
import {
  Avatar,
  Box,
  Chip,
  Collapse,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography
} from "@material-ui/core"
import { IconButton, PageSection, ProgressButton } from "src/components"
import { makeStyles } from "@material-ui/styles"
import ArrowRightAltOutlinedIcon from "@material-ui/icons/ArrowRightAltOutlined"
import DangerousTwoToneIcon from "@material-ui/icons/DangerousTwoTone"
import PropTypes from "prop-types"
import { StarBorder } from "@material-ui/icons"
import moment from "moment"
import orange from "@material-ui/core/colors/orange"
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone"
import { useEnhancedMutate } from "src/hooks"
import { cancelLoadOrder } from "../api/api"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
    // maxWidth: 360,
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  chipLarge: {
    height: 45
  }
}))

const OrderDetails = ({ item, editOrder }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  const {
    workType,
    origin,
    originRadius,
    destination,
    destinationRadius,
    excludedDestinations,
    numberOfStops,
    minPickupTime,
    pickupTimeWithin,
    timeZone,
    loadStartDate,
    loadEndDate,
    ppm,
    payout,
    driver1,
    driver2,
    orderActionType,
    maxDistance,
    excludedStates,
    loadOrderStatusId
  } = item

  const getOrigin = () => <>{origin.label}</>
  const getDestination = () => (
    <>
      {destination.map(loc => {
        return <Chip key={loc.latitude} label={loc.label} />
      })}
    </>
  )
  const getExcludedCities = () => (
    <>
      {excludedDestinations.map(loc => {
        return <Chip key={loc.latitude} label={loc.label} />
      })}
    </>
  )
  // const getExcludedCities = () => (
  //   <>
  //     {excludedDestinations.map(loc => {
  //       return <Chip key={loc} label={loc.split("'", 2)[1]} />
  //     })}
  //   </>
  // )
  const getExcludedStates = () => (
    <>
      {excludedStates.map(loc => {
        return <Chip key={loc} label={loc} />
      })}
    </>
  )

  const getDateTime = (label, date) => (
    <>
      {label}: {date ? moment(date).format("MM/DD/YYYY HH:mm") : "Any"}
    </>
  )
  const getCenteredText = data => (
    <>
      <Box>{data}</Box>
    </>
  )

  const getLabels = () => (
    <>
      <Box sx={{ textAlign: "center", pt: 0, mt: 0, mr: 2 }}>
        <Chip
          label={`Stops ${numberOfStops}`}
          size="medium"
          variant="outlined"
          color="primary"
          className={classes.chipLarge}
          avatar={
            <Avatar>
              <DangerousTwoToneIcon />
            </Avatar>
          }
        />
      </Box>
    </>
  )

  const [cancelOrder] = useEnhancedMutate(cancelLoadOrder, {
    invalidateQueries: [
      {
        queryKey: ["fetchOrderHistory"]
      }
    ],
    customSuccessMsg: "Order cancelled successfully"
  })

  return (
    <>
      <PageSection>
        <Grid container direction="row" alignItems="center">
          <Grid item width="5%">
            {loadOrderStatusId === 1 && (
              <ProgressButton onClick={() => cancelOrder(item.loadOrderId)} />
            )}
          </Grid>
          <Grid item width="95%">
            <ListItem button onClick={handleClick}>
              <Fab size="medium" sx={{ mr: 2 }}>
                {workType === "Any" ? "OW/RT" : workType}
              </Fab>
              <Fab size="medium" color="secondary" sx={{ mr: 2 }}>
                {originRadius} mi
              </Fab>
              <ListItemText
                sx={{ maxWidth: 250 }}
                primary={getOrigin(item)}
                secondary={getDateTime("Start time", loadStartDate)}
              />
              <ArrowRightAltOutlinedIcon />
              <Fab size="medium" color="secondary" sx={{ mx: 2 }}>
                {destinationRadius} mi
              </Fab>
              <ListItemText
                primary={getDestination(item)}
                secondary={getDateTime("End time", loadEndDate)}
              />
              <ListItemText mt={0} pt={0}>
                <Typography
                  component="span"
                  variant="body1"
                  color="textSecondary"
                  align="center"
                  mt={0}
                  pt={0}
                >
                  Excluded States:
                </Typography>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  align="center"
                  component="span"
                >
                  {getExcludedStates(item)}
                </Typography>
              </ListItemText>
              {getLabels()}
              <Fab size="medium" sx={{ mr: 2, backgroundColor: orange[500] }}>
                {ppm > 0 ? `$${ppm}/mi` : "Any"}
              </Fab>
              <Fab size="medium" sx={{ mr: 2, backgroundColor: orange[500] }}>
                {payout > 0 ? `$${payout}` : "Any"}
              </Fab>
              <Fab size="medium" sx={{ mr: 2, backgroundColor: "lightblue" }}>
                {maxDistance > 0 ? `${maxDistance}mi` : "Any"}
              </Fab>
              <IconButton aria-label="Edit" onClick={() => editOrder(item)}>
                <EditTwoToneIcon />
              </IconButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>

                  {excludedDestinations.length > 0 &&
                    excludedDestinations[0] !== "" && (
                      <ListItemText>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          align="center"
                        >
                          Excluded
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          align="center"
                          component="span"
                        >
                          {getExcludedCities()}
                        </Typography>
                      </ListItemText>
                    )}

                  <ListItemText>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      align="center"
                    >
                      Stops
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      align="center"
                    >
                      {numberOfStops}
                    </Typography>
                  </ListItemText>

                  <ListItemText>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      align="center"
                    >
                      PPM
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      align="center"
                    >
                      {ppm}
                    </Typography>
                  </ListItemText>

                  <ListItemText>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      align="center"
                    >
                      Payout
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      align="center"
                    >
                      {payout}
                    </Typography>
                  </ListItemText>

                  <ListItemText>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      align="center"
                    >
                      Max Distance
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      align="center"
                    >
                      {maxDistance}
                    </Typography>
                  </ListItemText>

                  <ListItemText>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      align="center"
                    >
                      Min Pickup Time
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      align="center"
                    >
                      {minPickupTime}
                    </Typography>
                  </ListItemText>

                  <ListItemText>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      align="center"
                    >
                      PU Time Within
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      align="center"
                    >
                      {pickupTimeWithin}
                    </Typography>
                  </ListItemText>

                  <ListItemText>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      align="center"
                    >
                      Driver 1
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      align="center"
                    >
                      {driver1}
                    </Typography>
                  </ListItemText>

                  <ListItemText>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      align="center"
                    >
                      Driver 2
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      align="center"
                    >
                      {driver2}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Collapse>
          </Grid>
        </Grid>
      </PageSection>
    </>
  )
}

OrderDetails.propTypes = {
  item: PropTypes.object,
  editOrder: PropTypes.func
}

export default OrderDetails
