import React from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardHeader
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import PropTypes from "prop-types"

const PageSectionAccordion = ({
  name,
  children,
  hidden,
  actionElement,
  ...rest
}) => {
  return (
    <>
      {!hidden && (
        <Box sx={{ mt: 3 }}>
          <Accordion {...rest}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              sx={{ py: 0, my: 0 }}
            >
              {name && (
                <CardHeader
                  sx={{ py: 0, my: 0 }}
                  title={name}
                  action={actionElement}
                />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Card>
                <CardContent>{children}</CardContent>
              </Card>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </>
  )
}

PageSectionAccordion.propTypes = {
  name: PropTypes.string,
  // eslint-disable-next-line max-len
  children: PropTypes.node, // Takes care of single element and array of elements passed as a child
  hidden: PropTypes.bool,
  actionElement: PropTypes.element
}

export default PageSectionAccordion
