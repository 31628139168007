import React, { useState } from "react"
import clsx from "clsx"
import { useTheme } from "@material-ui/core/styles"
import { makeStyles } from "@material-ui/styles"
import { Link as RouterLink } from "react-router-dom"
import Drawer from "@material-ui/core/Drawer"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined"
import InputIcon from "@material-ui/icons/Input"
import { Box, Avatar, Hidden, Badge } from "@material-ui/core"
import { useAuth } from "../../hooks"
import Logo from "./Logo"
import ToggleTheme from "../../theme/ToggleTheme"
import AppLayoutNavBar from "./AppLayoutNavBar"
import { RoleSelector } from "../auth"

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    // this spacing is for small icons when drawer is closed
    width: theme.spacing(7),
    // removes drawer on mobile view
    [theme.breakpoints.only("xs")]: {
      width: theme.spacing(0)
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  listItemText: {
    marginTop: 0,
    marginBottom: 0
  },
  routerLink: {
    margin: 0,
    padding: 0
  }
}))

// const user = {
//   avatar: "/static/images/no_avatar.png",
//   jobTitle: "Senior Developer",
//   name: "Katarina Smith"
// }

const AppLayoutAppBar = () => {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const auth = useAuth()
  const { currentUser, logOut } = auth

  const handleLogOut = () => logOut()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const [notifications] = useState([])

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon />
          </IconButton>
          <RouterLink to="/" className={classes.routerLink}>
            <Logo />
          </RouterLink>
          <Typography variant="h6" noWrap>
            {currentUser && currentUser.company}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <ToggleTheme />
          <IconButton color="inherit" onClick={handleLogOut} size="large">
            <InputIcon />
          </IconButton>
          <Hidden xlDown>
            <IconButton color="inherit" size="large">
              <Badge
                badgeContent={notifications.length}
                color="primary"
                variant="dot"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            p: 2
          }}
        >
          {!open && (
            <Avatar>
              <>
                <Typography color="textPrimary" variant="h5">
                  {currentUser.firstName.substring(0, 1)}{" "}
                  {currentUser.lastName.substring(0, 1)}
                </Typography>
              </>
            </Avatar>
          )}
          {open && (
            <>
              <Typography color="textPrimary" variant="h5">
                {currentUser.fullName}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {currentUser.roleName}
              </Typography>
              <RoleSelector />
            </>
          )}
        </Box>
        <Divider />
        <Box sx={{ p: 1 }}>
          <AppLayoutNavBar isOpen={open} />
        </Box>
      </Drawer>
    </>
  )
}

export default AppLayoutAppBar
