/* eslint-disable no-unused-vars */
import React from "react"
import {
  Box,
  Button,
  Grid,
  MenuItem,
  SvgIcon,
  TextField
} from "@material-ui/core"
import { PageSection, FormikControl, Page } from "src/components"
import SuspenseWrapper from "src/components/layout/SuspenseWrapper"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import { useWeeks } from "src/modules/shared"
import { Search as SearchIcon } from "react-feather"
import { useEnhancedMutate } from "src/hooks"
import { useLoadHistory } from "./api/hooks"
import { saveImportedLoads } from "./api/api"
import LoadDataTable from "./components/LoadDataTable"
import { useLookupData } from "../../loadboard/api/hooks"

const LoadImportPage = () => {
  const [selectedWeek, setSelectedWeek] = React.useState(0)
  const [lookupData, setLookupData] = React.useState(null)
  const lookupQuery = useLookupData()
  const onSubmit = async values => {
    setSelectedWeek(values.weekId)
  }
  const [newLoads, setNewLoads] = React.useState()
  const [existingLoads, setExistingLoads] = React.useState()

  // const onSaveImportedLoads = async () => {
  //   await saveImportedLoads(newLoads)
  // }

  const [onSaveImportedLoads] = useEnhancedMutate(saveImportedLoads, {
    invalidateQueries: [
      {
        queryKey: ["fetchLoadHistory", selectedWeek]
      }
    ]
  })

  const [weekList, setWeekList] = React.useState([])
  const [selectedYear, setSelectedYear] = React.useState(
    new Date().getFullYear()
  )
  const weeksDataResult = useWeeks(selectedYear)

  const loadHistoryResult = useLoadHistory(selectedWeek)

  // eslint-disable-next-line no-unused-vars

  React.useEffect(() => {
    if (weeksDataResult.isFetched) {
      setWeekList(weeksDataResult.data)
    }
    if (loadHistoryResult.isFetched) {
      if (!newLoads) {
        setNewLoads(loadHistoryResult.data.newLoads)
      }
      setExistingLoads(loadHistoryResult.data.existingLoads)
    }
  }, [weeksDataResult, loadHistoryResult])

  React.useEffect(() => {
    if (lookupQuery.isFetched) {
      setLookupData(lookupQuery.data)
    }
  }, [lookupQuery])

  const deleteNewLoad = item => {
    const filteredLoads = newLoads.filter(x => x.loadNumber !== item.loadNumber)
    setNewLoads(filteredLoads)
  }

  const editNewLoad = item => {
    const index = newLoads.findIndex(x => x.loadNumber === item.loadNumber)
    newLoads[index].loadDrivers = item.loadDrivers ?? []
    // eslint-disable-next-line prefer-destructuring
    newLoads[index].loadDriverNames = item.loadDrivers
    setNewLoads(newLoads)
  }

  const initialValues = {
    weekId: ""
  }

  const downloadTxt = (fileName, data) => {
    const element = document.createElement("a")
    element.setAttribute(
      "href",
      `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`
    )
    element.setAttribute("download", fileName)

    element.style.display = "none"
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }

  const onDownloadBtnClick = () => {
    const data = existingLoads.reverse()
    let output = "Load|Date|Driver|101|102|103|104|105|106|Other \n"
    for (let i = 0; i < data.length; i++) {
      output += `\n${data[i].loadNumber}|${data[i].loadStartDate}|${data[
        i
      ].loadDriverNames.join()}|`
      if (data[i].loadDriverNames.join().includes("Mukhammadjon Sadikov")) {
        output += data[i].payout
      } else if (
        data[i].loadDriverNames.join().includes("Sardorbek Shakirov")
      ) {
        output += `|${data[i].payout}`
      } else if (data[i].loadDriverNames.join().includes("Odiljon Israilov")) {
        output += `||${data[i].payout}`
      } else if (
        data[i].loadDriverNames.join().includes("Yuldoshali Nurmatov")
      ) {
        output += `|||${data[i].payout}`
      } else if (
        data[i].loadDriverNames.join().includes("Mukhammadsodik Ergashev")
      ) {
        output += `||||${data[i].payout}`
      } else if (data[i].loadDriverNames.join().includes("A. Rahmanov")) {
        output += `|||||${data[i].payout}`
      } else output += `||||||${data[i].payout}`
      output += `|||||||${data[i].comments ?? ""}`
    }

    const fileName = `${data[0].companyName}_${
      weekList.filter(x => x.value === selectedWeek)[0].label
    }`
    downloadTxt(fileName, output)
  }

  const validationSchema = Yup.object().shape({
    // origin: Yup.string().required("Origin city is required")
  })

  return (
    <>
      <Page title="Load Import Page">
        <PageSection>
          <Box sx={{ pt: 3 }}>
            <SuspenseWrapper>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnMount
                enableReinitialize
              >
                {formik => {
                  return (
                    <Form>
                      <Grid container spacing={1}>
                        <Grid item md={1} xs={12}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            fullWidth
                            margin="normal"
                            label="Work Year"
                            onChange={e => setSelectedYear(e.target.value)}
                            value={selectedYear}
                            variant="standard"
                          >
                            {[2021, 2022, 2023, 2024, 2025].map(option => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        {!formik.values.startDate && !formik.values.endDate && (
                          <Grid item md={2} xs={12}>
                            <FormikControl
                              control="select"
                              data={weekList}
                              label="Work Weeks"
                              name="weekId"
                              type="text"
                              variant="standard"
                              values={formik.values.weekId}
                            />
                          </Grid>
                        )}
                        <Grid item md={1} xs={12}>
                          <Box pt={3} justifyContent="flex-end">
                            <Button
                              fullWidth
                              type="submit"
                              color="primary"
                              variant="outlined"
                              startIcon={
                                <SvgIcon fontSize="small" color="action">
                                  <SearchIcon />
                                </SvgIcon>
                              }
                              disabled={!formik.isValid || formik.isSubmitting}
                            >
                              Search
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Form>
                  )
                }}
              </Formik>
            </SuspenseWrapper>
          </Box>
        </PageSection>
        {lookupData && newLoads && (
          <PageSection name="New Loads">
            <LoadDataTable
              lookupData={lookupData}
              data={newLoads}
              weekList={weekList}
              deleteNewLoad={e => deleteNewLoad(e)}
              editNewLoad={e => editNewLoad(e)}
            />
            <Box justifyContent="space-between">
              <Box justifyContent="flex-start">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => onSaveImportedLoads(newLoads)}
                >
                  Import Loads
                </Button>
              </Box>
              <Box justifyContent="flex-end">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => onDownloadBtnClick()}
                >
                  Export Loads
                </Button>
              </Box>
            </Box>
          </PageSection>
        )}
        {lookupData && existingLoads && (
          <PageSection name="Existing Loads">
            <LoadDataTable
              lookupData={lookupData}
              data={existingLoads}
              weekList={weekList}
              deleteNewLoad={e => deleteNewLoad(e)}
              editNewLoad={e => editNewLoad(e)}
            />
          </PageSection>
        )}
      </Page>
    </>
  )
}

LoadImportPage.propTypes = {}

export default LoadImportPage
