import { QueryClient } from "react-query"

const ReactQueryConfig = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      refetchOnWindowFocus: false
    }
  }
})

export default ReactQueryConfig
