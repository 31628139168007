/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  TableCell,
  TableRow,
  TextField
} from "@material-ui/core"
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone"
import { useEnhancedMutate } from "src/hooks"
import {
  EnhancedTable,
  ConfirmDialog,
  IconButton,
  PageSection
} from "src/components"
import PropTypes from "prop-types"
import { DeleteForeverTwoTone } from "@material-ui/icons"
import { ProtectedElement } from "src/components/auth"
import moment from "moment"
import { useWeeks } from "../api/hooks"
import { generateWorkWeeks, deleteWorkWeeks } from "../api/api"

const WorkWeeksDataTable = ({ ...rest }) => {
  const [confirmDialog, setConfirmDialog] = useState(null)
  const [sortedData, setSortedData] = useState(null)
  const [weekYear, setWeekYear] = useState(2022)
  const [workYearStartDate, setWorkYearStartDate] = useState("")

  const handleOnSort = inData => {
    setSortedData(inData)
  }

  const dataQuery = useWeeks(weekYear)

  const headers = [
    {
      id: "weekId",
      numeric: false,
      disablePadding: false,
      label: "ID"
    },
    {
      id: "year",
      numeric: false,
      disablePadding: false,
      label: "Year"
    },
    {
      id: "number",
      numeric: false,
      disablePadding: false,
      label: "Week number"
    },
    {
      id: "startDate",
      numeric: false,
      disablePadding: false,
      label: "Week Start Date"
    },
    {
      id: "endDate",
      numeric: false,
      disablePadding: false,
      label: "Week End Date"
    },
    {
      id: "action",
      disablePadding: false,
      label: "Action"
    }
  ]

  const [generateWeeks] = useEnhancedMutate(generateWorkWeeks, {
    invalidateQueries: [
      {
        queryKey: ["fetchWeeks", 2022]
      }
    ],
    customSuccessMsg: "Weeks generated successfully"
  })

  const [deleteWeeks] = useEnhancedMutate(deleteWorkWeeks, {
    invalidateQueries: [
      {
        queryKey: ["fetchWeeks", 2022]
      }
    ],
    customSuccessMsg: "Weeks deleted successfully"
  })

  // Used to check if useeffect trigerred by data update
  const [dataUpdatedOn, setDataUpdatedOn] = useState("")

  useEffect(() => {
    if (dataQuery.isFetched && dataUpdatedOn < dataQuery.dataUpdatedAt) {
      setDataUpdatedOn(dataQuery.dataUpdatedAt)
      setSortedData(dataQuery.data)
    }
  }, [dataQuery])

  const handleOnGenerateWeeks = () => {
    generateWeeks(workYearStartDate)
  }

  const handleOnDeleteWeeks = () => {
    deleteWeeks(weekYear)
  }

  return (
    <>
      <PageSection>
        <Card mb={2}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                onInput={e => setWorkYearStartDate(e.target.value)}
                type="date"
              />
              <Button
                onClick={() => handleOnGenerateWeeks()}
                variant="outlined"
              >
                Generate
              </Button>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-select-currency"
                select
                label="Select"
                onChange={e => setWeekYear(e.target.value)}
                helperText="Please select year"
                variant="outlined"
                value={weekYear}
              >
                {[2021, 2022, 2023, 2024, 2025].map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <Button onClick={() => handleOnDeleteWeeks()} variant="outlined">
                Delete
              </Button>
            </Grid>
          </Grid>
        </Card>
      </PageSection>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            {sortedData && (
              <EnhancedTable
                data={sortedData}
                headers={headers}
                onSort={handleOnSort}
              >
                {sortedData.map(item => {
                  return (
                    <TableRow hover key={item.weekId}>
                      <TableCell>{item.weekId}</TableCell>
                      <TableCell>{item.year}</TableCell>
                      <TableCell align="left">{item.number}</TableCell>
                      <TableCell align="left">
                        {moment(item.startDate).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell align="left">
                        {moment(item.endDate).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell align="left">
                        <ProtectedElement
                          name="BrokerAdminPage"
                          accessLevel="DenyDelete"
                        >
                          <IconButton aria-label="Edit">
                            <EditTwoToneIcon />
                          </IconButton>
                        </ProtectedElement>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </EnhancedTable>
            )}
          </Box>
        </PerfectScrollbar>
      </Card>
      <ConfirmDialog props={confirmDialog} />
    </>
  )
}

WorkWeeksDataTable.propTypes = {}

export default WorkWeeksDataTable
