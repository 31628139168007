import React from "react"
import { InputAdornment, TextField } from "@material-ui/core"
import { Field } from "formik"
import PropTypes from "prop-types"

const TextBox = props => {
  const { name, inputAdornment, adornmentPosition, ...rest } = props
  const variant = rest.variant ? rest.variant : "outlined"
  return (
    <>
      <Field name={name} id={name} {...rest}>
        {fieldProps => {
          const { field, meta } = fieldProps
          return (
            <TextField
              error={Boolean(meta.touched && meta.error)}
              fullWidth
              helperText={meta.error}
              {...rest}
              title={name}
              margin="normal"
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value || ""}
              variant={variant}
              InputProps={
                inputAdornment && {
                  startAdornment: (
                    <InputAdornment position={adornmentPosition}>
                      {inputAdornment}
                    </InputAdornment>
                  )
                }
              }
              // {...field} // replaces name, value, onChange and onBlur
            />
          )
        }}
      </Field>
    </>
  )
}

TextBox.propTypes = {
  name: PropTypes.string,
  props: PropTypes.object,
  inputAdornment: PropTypes.string,
  adornmentPosition: PropTypes.string
}

export default TextBox
