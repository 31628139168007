import React from "react"
import { TextField } from "@material-ui/core"
import { Field } from "formik"
import PropTypes from "prop-types"

const TextArea = props => {
  const { name, ...rest } = props
  const variant = rest.variant ? rest.variant : "outlined"
  return (
    <>
      <Field name={name} id={name} {...rest}>
        {fieldProps => {
          const { field, meta } = fieldProps
          return (
            <TextField
              fullWidth
              label={name}
              multiline
              error={Boolean(meta.touched && meta.error)}
              helperText={meta.error}
              title={name}
              variant={variant}
              rows={6}
              {...rest}
              {...field}
            />
          )
        }}
      </Field>
    </>
  )
}

TextArea.propTypes = {
  name: PropTypes.string,
  props: PropTypes.object
}

export default TextArea
