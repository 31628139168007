import React, { useEffect, useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core"
import PropTypes from "prop-types"

const ConfirmDialog = props => {
  const defaultDialog = {
    text: "",
    onCancel: () => {},
    onConfirm: () => {},
    isOpen: false
  }
  const [dialog, setDialog] = useState(defaultDialog)

  const handleConfirm = () => {
    dialog.onConfirm()
  }

  useEffect(() => {
    if (props.props) {
      const d = {}
      d.text = props.props.text
      d.onConfirm = props.props.onConfirm
      d.isOpen = props.props.isDialogOpen
      d.onCancel = props.props.onCancel
      setDialog(d)
    } else {
      setDialog(defaultDialog)
    }
  }, [props])

  return (
    <div>
      <Dialog
        open={dialog.isOpen}
        onClose={() => dialog.onCancel()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Please confirm your selection
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialog.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dialog.onCancel()} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

ConfirmDialog.propTypes = {
  props: PropTypes.object,
  text: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isDialogOpen: PropTypes.bool
}

export default ConfirmDialog
