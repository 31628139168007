import React from "react"
import PropTypes from "prop-types"
import { useAuth } from "src/hooks"

// eslint-disable-next-line no-unused-vars
const ProtectedNav = ({ children, name, permissionLevel, ...rest }) => {
  const auth = useAuth()
  const { currentUser } = auth

  if (
    currentUser &&
    currentUser.userPermissibleObjects.some(
      x => x.objectName === name && x.isMenuVisible
    )
  ) {
    return <>{children}</>
  }

  return <></>
}

ProtectedNav.propTypes = {
  children: PropTypes.object,
  name: PropTypes.string,
  permissionLevel: PropTypes.string
}

export default ProtectedNav
