/* eslint-disable no-unused-vars */
import React from "react"
import { Box } from "@material-ui/core"
import { Page, PageSection, SearchBar } from "src/components"
import CustomerListResults from "src/components/customer/CustomerListResults"
import SuspenseWrapper from "src/components/layout/SuspenseWrapper"
import { useUsers } from "./api/hooks"
import { fetchUsers } from "./api/api"
import UsersDataTable from "./components/UsersDataTable"

const UserAdminPage = () => {
  return (
    <>
      <Page title="User Management">
        <SearchBar name="User" disabled mb={3} />
        <PageSection>
          <Box sx={{ pt: 3 }}>
            <SuspenseWrapper>
              <UsersDataTable />
            </SuspenseWrapper>
          </Box>
        </PageSection>
      </Page>
    </>
  )
}

UserAdminPage.propTypes = {}

export default UserAdminPage
