// import firebase from "firebase/compat/app"
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBHpKZc5sQ1A0wqhLUxpWkfh9YGUBdD2AY",
  authDomain: "erpdb-7eeee.firebaseapp.com",
  databaseURL: "https://erpdb-7eeee-default-rtdb.firebaseio.com",
  projectId: "erpdb-7eeee",
  storageBucket: "erpdb-7eeee.appspot.com",
  messagingSenderId: "1021219594602",
  appId: "1:1021219594602:web:7cc4e704017a0947ebc513",
  measurementId: "G-QVJ2THY3ZY"
}

// Initialize Firebase
// < v9
// const app = firebase.initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig)

const auth = getAuth(app)

export { auth, app as firebase }
