/* eslint-disable no-unused-vars */
import axios from "axios"

export const fetchWeeks = async ({ queryKey }) => {
  const [_, param] = queryKey
  const result = await axios.get(`/api/General/GetWorkWeeks?year=${param}`)
  return result.data
}

export const fetchServiceUrl = async () => {
  const result = await axios.get(`/api/General/GetServiceUrl`)
  return result.data
}

export const refreshCookies = async () => {
  const result = await axios.get(`/api/CookieAdmin/RefreshCookies`)
  return result.data
}

export const setServiceUrl = async data => {
  const result = await axios.post(`/api/General/setServiceUrl?url=${data}`)
  return result.data
}

export const generateWorkWeeks = async data => {
  const result = await axios.post(`/api/General/GenerateWorkWeeks?date=${data}`)
  return result.data
}

export const deleteWorkWeeks = async data => {
  const result = await axios.delete(`/api/General/DeleteWorkWeeks?year=${data}`)
  return result.data
}
